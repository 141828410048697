import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  // return "http://192.168.1.8:3203/admin/order"
  return APIHost() + "/admin/order";
};

export const baseURLTrip = () => {
  return APIHost() + "/admin/trip";
};

export const baseURLTrans = () => {
  return APIHost() + "/admin/transaction";
};

export const baseURLReport = () => {
  // return "http://192.168.1.8:3203/admin/report"
  return APIHost() + "/admin/report";
};

export const baseURLExpress = () => {
  return APIHost() + "/admin/express";
};

export const baseURLGrading = () => {
  // return "http://192.168.100.62:3203/admin/grading"
  return APIHost() + "/admin/grading";
};

export const loadOrderList = data => {
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};
export const loadOrderDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};

export const loadOrderDetailTripGroup = (id, data) => {
  const url = `${baseURL()}/tripGroup/${id}`;
  return axios.post(url, data);
};

export const loadOrderDetailTripList = (id, data) => {
  const url = `${baseURL()}/tripList/${id}`;
  return axios.post(url, data);
};



export const orderCount = (data) => {
  const url = `${baseURL()}/count`;
  return axios.post(url, data);
};

export const cancelOrder = (id, data) => {
  const url = `${baseURL()}/cancel/${id}`;
  return axios.post(url, data);
};

export const getOrderCancellationDetail = (id, data) => {
  const url = `${baseURL()}/cancellation/${id}`;
  return axios.post(url, data);
};

export const orderAssignDriver = (id, data) => {
  const url = `${baseURL()}/assignDriver/${id}`;
  return axios.post(url, data);
};

export const createOrder = data => {
  const url = `${baseURL()}/create`;
  return axios.post(url, data);
};


export const loadOrderTripList = data => {
  const url = `${baseURL()}/trip/load`;
  return axios.post(url, data);
};

export const orderTripCount = data => {
  const url = `${baseURL()}/trip/count`;
  return axios.post(url, data);
};

export const loadDriverTripList = (id, data) => {
  const url = `${baseURL()}/trip/driver/${id}`;
  return axios.post(url, data);
};

export const trackDriver = (id, data) => {
  const url = `${baseURL()}/trackDriver/${id}`;
  return axios.post(url, data);
};

export const tripSetArrived = (id, data) => {
  const url = `${baseURLTrip()}/setTripArrived/${id}`;
  return axios.post(url, data);
};

export const tripUpdateGoingNow = (id,data) => {
  const url = `${baseURLTrip()}/goingNow/${id}`;
  return axios.post(url, data);
};

export const tripAllowGoingNowAdvance = (id, data) => {
  const url = `${baseURLTrip()}/goingNowAdvance/${id}`;
  return axios.post(url, data);
};

export const tripRollbackGoingNow = (id, data) => {
  const url = `${baseURLTrip()}/goingNowRollback/${id}`;
  return axios.post(url, data);
};

export const tripSetStarted = (orderID,tripID, data) => {
  const url = `${baseURLTrip()}/startTrip/${orderID}/${tripID}`;
  return axios.post(url, data);
};

export const editTripWeighingFigure = (id, data) => {
  const url = `${baseURLTrip()}/weighingFigure/edit/${id}`;
  return axios.post(url, data);
};

export const resetCollectionTrip = (id, data) => {
  const url = `${baseURLTrip()}/resetCollection/${id}`;
  return axios.post(url, data);
};

export const updateTripPlacement = (id, data) => {
  const url = `${baseURLTrip()}/placement/${id}`;
  return axios.post(url, data);
};

export const updateTripFilling = (id, data) => {
  const url = `${baseURLTrip()}/filling/${id}`;
  return axios.post(url, data);
};

export const rollbackTripFilling = (id, data) => {
  const url = `${baseURLTrip()}/filling/rollback/${id}`;
  return axios.post(url, data);
};

export const updateTripCollection = (id, data) => {
  const url = `${baseURLTrip()}/collection/${id}`;
  return axios.post(url, data);
};

export const updateTripDisposal = (id, data) => {
  const url = `${baseURLTrip()}/disposal/${id}`;
  return axios.post(url, data);
};

export const reuploadDisposalSlip = (id, data) => {
  const url = `${baseURLTrip()}/disposalSlip/${id}`;
  return axios.post(url, data);
};

export const reuploadTripProofImg = (id, data) => {
  const url = `${baseURLTrip()}/tripProofImage/${id}`;
  return axios.post(url, data);
};

export const reuploadTripWeighingSlip = (id, data) => {
  const url = `${baseURLTrip()}/weighingSlip/${id}`;
  return axios.post(url, data);
};


export const updateTripLoadingCollection = (id, data) => {
  const url = `${baseURLTrip()}/loadingCollection/${id}`;
  return axios.post(url, data);
};



/* ===== ORDER INVOICE  ===== */
export const billingList = (data) => {
  const url = `${baseURLReport()}/billing/load`;
  return axios.post(url, data);
};

export const orderInvoice = (id, data) => {
  const url = `${baseURLReport()}/billing/invoice/${id}`;
  return axios.post(url, data);
};

export const orderInvoiceStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/invoice`;
  return axios.post(url, data);
};

export const agreementDebitCreditNoteLoad = (id, data) => {
  const url = `${baseURLReport()}/billing/note/${id}`;
  return axios.post(url, data);
};

export const agreementDebitCreditNoteStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/note`;
  return axios.post(url, data);
};
/* ===== END ORDER INVOICE ===== */



/* ===== SPECIAL INVOICE  ===== */
export const specialInvoiceList = (data) => {
  const url = `${baseURLReport()}/specialInvoice/load`;
  return axios.post(url, data);
};

export const getSpecialInvoice = (id, data) => {
  const url = `${baseURLReport()}/specialInvoice/${id}`;
  return axios.post(url, data);
};

export const getSpecialInvoiceStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/specialInvoice`;
  return axios.post(url, data);
};
/* ===== SPECIAL INVOICE  ===== */



/* ===== PURCHASE BILL  ===== */
export const purchaseBillList = (data) => {
  const url = `${baseURLReport()}/purchaseBill/load`;
  return axios.post(url, data);
};

export const purchaseBillDumpExpress = (id, data) => {
  const url = `${baseURLReport()}/purchaseBill/DumpExpress/${id}`;
  return axios.post(url, data);
};

export const purchaseBillDumpExpressStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/purchaseBill/DumpExpress`;
  return axios.post(url, data);
};

export const purchaseBillDumpRecycling = (id, data) => {
  const url = `${baseURLReport()}/purchaseBill/DumpRecycling/${id}`;
  return axios.post(url, data);
};

export const purchaseBillDumpRecyclingStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/purchaseBill/DumpRecycling`;
  return axios.post(url, data);
};

export const purchaseBillStatement = (data) => {
  const url = `${baseURLReport()}/purchaseBill/statement`;
  return axios.post(url, data);
};

/* ===== END PURCHASE BILL ===== */



/* ===== PURCHASE DEBIT CREDIT NOTE  ===== */
export const purchaseDebitCreditNoteList = (data) => {
  const url = `${baseURLReport()}/purchaseDebitCreditNote/load`;
  return axios.post(url, data);
};

export const purchaseDebitCreditNote = (id, data) => {
  const url = `${baseURLReport()}/purchaseNote/${id}`;
  return axios.post(url, data);
};

export const purchaseDebitCreditNoteStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/purchaseNote`;
  return axios.post(url, data);
};

/* ===== END PURCHASE DEBIT CREDIT NOTE ===== */


export const storeReceiptList = (data) => {
  const url = `${baseURLReport()}/storeReceipt/load`;
  return axios.post(url, data);
};

export const getStoreReceiptDumpExpressStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/storeReceipt/DumpExpress`;
  return axios.post(url, data);
};

export const getStoreReceiptDumpRecyclingStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/storeReceipt/DumpRecycling`;
  return axios.post(url, data);
};

export const wasteReceiptList = (data) => {
  const url = `${baseURLReport()}/wasteReceipt/load`;
  return axios.post(url, data);
};

export const getWasteReceiptStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/wasteReceipt`;
  return axios.post(url, data);
};

/* ===== RECYCLABLE SALES INVOICE  ===== */
export const recyclableSalesInvoiceList = (data) => {
  const url = `${baseURLReport()}/recyclableSalesInvoice/load`;
  return axios.post(url, data);
};

export const recyclableSalesInvoice = (id, data) => {
  const url = `${baseURLReport()}/recyclableSalesInvoice/load/${id}`;
  return axios.post(url, data);
};

export const recyclableSalesInvoiceStack = (data) => {
  const url = `${baseURLReport()}/billing/stack/recyclableSalesInvoice`;
  return axios.post(url, data);
};
/* ===== END RECYCLABLE SALES INVOICE ===== */

/* ===== BOOKING FEE  ===== */
export const bookingFeeList = (data) => {
  const url = `${baseURLReport()}/bookingFee/load`;
  return axios.post(url, data);
}
/* ===== END BOOKING FEE ===== */

/* ===== PROCESS FEE  ===== */
export const processFeeList = (data) => {
  const url = `${baseURLReport()}/processFee/load`;
  return axios.post(url, data);
}
/* ===== END PROCESS FEE ===== */


/* ===== LANDFILL FEE  ===== */
export const landfillFeeList = (data) => {
  const url = `${baseURLReport()}/landfillfee/load`;
  return axios.post(url, data);
}
/* ===== END LANDFILL FEE ===== */

/* ===== USER LOADING FEE LIST  ===== */
export const userLoadingFeeList = (data) => {
  const url = `${baseURLReport()}/userLoading/load`;
  return axios.post(url, data);
}
/* ===== END USER LOADING FEE LIST ===== */

/* ===== DRIVER LOADING PAYMENT LIST  ===== */
export const driverLoadingPaymentList = (data) => {
  const url = `${baseURLReport()}/driverLoading/load`;
  return axios.post(url, data);
}
/* ===== END DRIVER LOADING PAYMENT LIST ===== */

/* ===== SORTING FEE  ===== */
export const sortingFeeList = (data) => {
  const url = `${baseURLReport()}/sortingfee/load`;
  return axios.post(url, data);
}
/* ===== END SORTING FEE ===== */

/* ===== OVERSIZE FEE LIST  ===== */
export const oversizeFeeList = (data) => {
  const url = `${baseURLReport()}/oversizeFee/load`;
  return axios.post(url, data);
}
/* ===== END OVERSIZE FEE LIST ===== */


export const recyclablePurchaseList = (data) => {
  const url = `${baseURLReport()}/recyclablePurchase/load`;
  return axios.post(url, data);
}


export const orderTrackReport = (id, data) => {
  const url = `${baseURL()}/trackReport/${id}`;
  // const url = `http://192.168.100.13:3203/admin/order/trackReport/${id}`;
  return axios.post(url, data);
};

export const orderTripStoreReceipt = (id, data) => {
  const url = `${baseURL()}/storeReceipt/${id}`;
  return axios.post(url, data);
};

export const orderTripWasteReceipt = (id, data) => {
  const url = `${baseURL()}/wasteReceipt/${id}`;
  return axios.post(url, data);
};

export const orderWasteSummary = (id, data) => {
  // const url = `http://192.168.100.13:3203/admin/order/wasteSummary/${id}`;
  const url = `${baseURL()}/wasteSummary/${id}`;
  return axios.post(url, data);
};

export const loadTransactionList = data => {
  const url = `${baseURLTrans()}/load`;
  return axios.post(url,data);
};

export const loadDuitNowTransactionList = data => {
  const url = `${baseURLTrans()}/duitnow/load`;
  return axios.post(url,data);
};


/* ===== ORDER SUMMARY ===== */
export const loadOrderSummary = data => {
  const url = `${baseURL()}/summary`;
  return axios.post(url,data);
};

export const loadOrderSummaryChart = data => {
  const url = `${baseURL()}/summary/chart`;
  return axios.post(url,data);
};

export const loadOrderSaleSummary = data => {
  const url = `${baseURL()}/sales/summary`;
  return axios.post(url,data);
};
/* ===== END ORDER SUMMARY ===== */

export const loadDriverIncentive = (id, data) => {
  let url = `${baseURL()}/incentive`;

  if(id)
    url += `/${id}`
  return axios.post(url, data);
};

export const loadUserOrderHistory = (id, data) => {
  let url = `${baseURL()}/history`;

  if(id)
    url += `/${id}`

  return axios.post(url, data);
};

/* =====REPORT ===== */
export const monthlyWasteSummary = (user, branch, data) => {
  let url = `${baseURLReport()}/wasteSummary/report`;
  if(user) url += `/${user}`
  if(branch) url += `/${branch}`
  
  return axios.post(url, data);
};

export const monthlyWasteSummaryLoad = (user, branch, data) => {
  let url = `${baseURLReport()}/wasteSummary/load`;
  if(user) url += `/${user}`
  if(branch) url += `/${branch}`
  
  return axios.post(url, data);
};

export const monthlyLandfillSummary = (id, data) => {
  let url = `${baseURLReport()}/LandfillSummary/report`;
  if(id) url += `/${id}`
  
  return axios.post(url, data);
};

export const monthlyLandfillSummaryLoad = (id, data) => {
  let url = `${baseURLReport()}/LandfillSummary/load`;
  if(id) url += `/${id}`
  
  return axios.post(url, data);
};


export const monthlyStockSummary = (data) => {
  const url = `${baseURLReport()}/stockSummary/report`;  
  return axios.post(url, data);
};

export const monthlyStockSummaryLoad = (data) => {
  const url = `${baseURLReport()}/stockSummary/load`; 
  return axios.post(url, data);
};


export const monthlyWasteStockSummary = (data) => {
  const url = `${baseURLReport()}/wasteStockSummary/report`;  
  return axios.post(url, data);
};

export const monthlyWasteStockSummaryLoad = (data) => {
  const url = `${baseURLReport()}/wasteStockSummary/load`; 
  return axios.post(url, data);
};


export const monthlyDriverTripSummary = (data) => {
  const url = `${baseURLReport()}/monthlyTripSummary`;  
  return axios.post(url, data);
};

export const orderDistanceSummary = (data) => {
  const url = `${baseURLReport()}/orderDistanceSummary`;  
  return axios.post(url, data);
};

export const referralCommissionSummary = (data) => {
  const url = `${baseURLReport()}/referralCommissionSummary`;  
  return axios.post(url, data);
};
/* ===== END REPORT ===== */

/* ===== ADJUSTMENT AGREEMENT  ===== */

export const loadAdjAgreementList = (data) => {
  const url = `${baseURL()}/agreement/load`;
  return axios.post(url, data);
};

export const loadAdjAgreementDetail = (id, data) => {
  const url = `${baseURL()}/agreement/load/${id}`;
  return axios.post(url, data);
};

export const agreementProceeRefund = (data) => {
  const url = `${baseURL()}/agreement/refund/proceed`;
  return axios.post(url, data);
};

export const loadAdjAffectedOrderList = (id,data) => {
  const url = `${baseURL()}/adjustment/${id}`;
  return axios.post(url, data);
};

/* ===== END ADJUSTMENT AGREEMENT ===== */


/* ===== CANCEL ORDER REFUND ===== */
export const cancelOrderRefundList = (data) => {
  const url = `${baseURL()}/cancelRefund/load`;
  return axios.post(url, data);
};

export const cancelOrderRefundDetail = (id, data) => {
  const url = `${baseURL()}/cancelRefund/load/${id}`;
  return axios.post(url, data);
};

export const cancelOrderProcessRefund = (id, data) => {
  const url = `${baseURL()}/cancelRefund/refund/${id}`;
  return axios.post(url, data);
};

/* ===== END CANCEL ORDER REFUND ===== */


/* ===== REASSIGN TRIP ===== */

export const reassignTrip = (id, data) => {
  const url = `${baseURL()}/reassignTrip/${id}`;
  return axios.post(url, data);
};

/* ===== END REASSIGN TRIP ===== */

/* ===== MAKE IT TODAY TRIP ===== */

export const makeItTodayTrip = (id, data) => {
  const url = `${baseURL()}/trip/madeItToday/${id}`;
  return axios.post(url, data);
};
/* ===== END MAKE IT TODAY TRIP ===== */


export const skipSignature = (id, data) => {
  const url = `${baseURL()}/trip/skipSignature/${id}`;
  return axios.post(url, data);
};

export const updateDumpsterEmpty = (id, data) => {
  const url = `${baseURL()}/trip/dumpsterEmpty/${id}`;
  return axios.post(url, data);
};


/* ===== COLLECTION REQUEST ===== */

export const collectionRequestCount = (data) => {
  const url = `${baseURL()}/collectionRequest/count`;
  return axios.post(url, data);
};

export const collectionRequestLoad = (data) => {
  const url = `${baseURL()}/collectionRequest/load`;
  return axios.post(url, data);
};

export const collectionRequestApprove = (id, data) => {
  const url = `${baseURL()}/collectionRequest/approve/${id}`;
  return axios.post(url, data);
};

export const collectionRequestReject = (id, data) => {
  const url = `${baseURL()}/collectionRequest/reject/${id}`;
  return axios.post(url, data);
};

/* ===== END COLLECTION REQUEST ===== */


/* ===== FILLING_EXTENSION REQUEST ===== */

export const fillingExtensionRequestCount = (data) => {
  const url = `${baseURL()}/fillingExtension/count`;
  return axios.post(url, data);
};

export const fillingExtensionRequestLoad = (data) => {
  const url = `${baseURL()}/fillingExtension/load`;
  return axios.post(url, data);
};

export const fillingExtensionRequestDetail = (id, data) => {
  const url = `${baseURL()}/fillingExtension/load/${id}`;
  return axios.post(url, data);
};

export const fillingExtensionRequestApprove = (id, data) => {
  const url = `${baseURL()}/fillingExtension/accept/${id}`;
  return axios.post(url, data);
};

export const fillingExtensionRequestReject = (id, data) => {
  const url = `${baseURL()}/fillingExtension/reject/${id}`;
  return axios.post(url, data);
};

/* ===== END FILLING_EXTENSION REQUEST ===== */


/* ===== MADE_IT_TODAY REQUEST ===== */

export const madeItTodayRequestCount = (data) => {
  const url = `${baseURL()}/madeItToday/count`;
  return axios.post(url, data);
};

export const madeItTodayRequestLoad = (data) => {
  const url = `${baseURL()}/madeItToday/load`;
  return axios.post(url, data);
};

export const madeItTodayRequestDetail = (id, data) => {
  const url = `${baseURL()}/madeItToday/load/${id}`;
  return axios.post(url, data);
};

export const madeItTodayRequestApprove = (id, data) => {
  const url = `${baseURL()}/madeItToday/accept/${id}`;
  return axios.post(url, data);
};

export const madeItTodayRequestReject = (id, data) => {
  const url = `${baseURL()}/madeItToday/reject/${id}`;
  return axios.post(url, data);
};

/* ===== END MADE_IT_TODAY REQUEST ===== */


/* ===== ADD_ON_TRIP REQUEST ===== */

export const addonTripRequestCount = (data) => {
  const url = `${baseURL()}/addonTrip/count`;
  return axios.post(url, data);
};

export const addonTripRequestLoad = (data) => {
  const url = `${baseURL()}/addonTrip/load`;
  return axios.post(url, data);
};

export const addonTripRequestDetail = (id, data) => {
  const url = `${baseURL()}/addonTrip/load/${id}`;
  return axios.post(url, data);
};

export const addonTripRequestApprove = (id, data) => {
  const url = `${baseURL()}/addonTrip/accept/${id}`;
  return axios.post(url, data);
};

export const addonTripRequestReject = (id, data) => {
  const url = `${baseURL()}/addonTrip/reject/${id}`;
  return axios.post(url, data);
};

/* ===== END ADD_ON_TRIP REQUEST ===== */


export const findDriverSimulate = (id, data) => {
  const url = `${baseURL()}/findDriverSimulate/${id}`;
  // const url = `http://192.168.100.61:3203/admin/order/findDriverSimulate/${id}`;
  return axios.post(url, data);
};

/* ===== GRADING  ===== */
export const gradingListLoad = (data) => {
  const url = `${baseURLGrading()}/list`;
  return axios.post(url, data);
};

export const gradingCount = (data) => {
  const url = `${baseURLGrading()}/count`;
  return axios.post(url, data);
};


export const pendingGradingListLoad = (data) => {
  const url = `${baseURLGrading()}/pending/list`;
  return axios.post(url, data);
};

export const pendingGradingCount = (data) => {
  const url = `${baseURLGrading()}/pending/count`;
  return axios.post(url, data);
};


export const pendingWasteGradingListLoad = (data) => {
  const url = `${baseURLGrading()}/waste/pending/list`;
  return axios.post(url, data);
};

export const pendingWasteGradingCount = (data) => {
  const url = `${baseURLGrading()}/waste/pending/count`;
  return axios.post(url, data);
};
/* ===== END GRADING  ===== */



/* ===== MPRM Process  ===== */
export const MPRMProcessList = (data) => {
  const url = `${baseURL()}/MPRMProcess/load`;
  return axios.post(url, data);
};


export const MPRMProcessDetail = (id, data) => {
  const url = `${baseURL()}/MPRMProcess/detail/${id}`;
  return axios.post(url, data);
};


export const MPRMProcessRetry = (id, data) => {
  const url = `${baseURL()}/MPRMProcess/retry/${id}`;
  return axios.post(url, data);
};


export const MPRMProcessReset = (id, data) => {
  const url = `${baseURL()}/MPRMProcess/reset/${id}`;
  return axios.post(url, data);
};

/* ===== END MPRM Process  ===== */



/* ===== EXPRESS ===== */
export const expressOrderCount = (data) => {
  const url = `${baseURLExpress()}/count`;
  return axios.post(url, data);
};

export const loadExpressOrderList = data => {
  const url = `${baseURLExpress()}/load`;
  return axios.post(url, data);
};

export const loadExpressOrderDetail = (id, data) => {
  const url = `${baseURLExpress()}/detail/${id}`;
  return axios.post(url, data);
};

export const cancelExpressOrder = (id, data) => {
  const url = `${baseURLExpress()}/cancel/${id}`;
  return axios.post(url, data);
};

export const expressStoreReceipt = (id, data) => {
  const url = `${baseURLExpress()}/storeReceipt/${id}`;
  return axios.post(url, data);
};

export const loadExpressOrderSummary = data => {
  const url = `${baseURLExpress()}/summary`;
  return axios.post(url,data);
};

export const loadExpressOrderSummaryChart = data => {
  const url = `${baseURLExpress()}/summary/chart`;
  return axios.post(url,data);
};

export const loadExpressOrderSaleSummary = data => {
  const url = `${baseURLExpress()}/sales/summary`;
  return axios.post(url,data);
};

export const graderDailySettlement = data => {
  const url = `${baseURLExpress()}/dailySettlement`;
  return axios.post(url,data);
};
/* ===== END EXPRESS ===== */