import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  userList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadUser: false,
  userFilterList:{
    data: [],
    total: 0,
    offset: 0,
    limit: 10,
    isLoading: false,
    hasMore: true
  },
  isProcessLoadUserFilter: false,
  isProcessLoadUserMore: false,
  userDetail:{},
  isUpdateUserStatusSuccess: false,
  isUpdateUserLoginShowVeriCodeStatusSuccess: false,
  isUpdateUserVerifyStatusSuccess: false,
  isUpdateUserDetailSuccess: false,
  isSwitchUserTypeSuccess: false,
  isRecoveryUserSuccess: false,
  userSummary:{},
  isSetBillingAddressSuccess: false,
  userCount: {},
  userCreditTermRequestList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isCreditTermApproveSuccess: false,
  isCreditTermApproveFail: false,
  isCreditTermRejectSuccess: false,
  isCreditTermCancelSuccess: false,
  isSetUserNegoPriceSuccess: false,
};

export default function userReducer(state = initState, action) {

  switch (action.type) {
    case actionTypes.LOAD_USER_LIST: {
      let userList = cloneDeep(state.userList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        userList.offset = action.data.offset;
        userList.limit = action.data.limit;
      }

      return {
        ...state,
        userList,        
        isProcessLoadUser: true,
      };
    }

    case actionTypes.LOAD_USER_LIST_SUCCESS: {
      let userList = cloneDeep(state.userList);
      userList.data = action.data.data;
      userList.meta = {
        page: 1 + state.userList.offset / state.userList.limit,
        pageSize: state.userList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.userList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        userList,        
        isProcessLoadUser: false,
      };
    }

    case actionTypes.LOAD_USER_LIST_FAIL: {
      return {
        ...state,        
        isProcessLoadUser: false,
      };
    }

    
    case actionTypes.LOAD_USER_FILTER: {
      
      let userFilterList = cloneDeep(state.userFilterList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        userFilterList.offset = action.data.offset;
        userFilterList.limit = action.data.limit;
      }

      userFilterList.isLoading = true;
      
      if (action.data.offset === 0) 
        userFilterList.data = [];

      return {
        ...state,
        userFilterList,
        isProcessLoadUserFilter: (action.data.offset === 0) ? true : false,
        isProcessLoadUserMore: (action.data.offset === 0) ? false : true,
      };
    }

    case actionTypes.LOAD_USER_FILTER_SUCCESS: {
      
      let userFilterList = cloneDeep(state.userFilterList);
      
      if(action.data.data.length > 0)
      {
        userFilterList.data = [...userFilterList.data, ...action.data.data];        
        userFilterList.hasMore = (action.data.data.length < state.userFilterList.limit) ? false : true;
      }else
      userFilterList.hasMore = false;

      userFilterList.isLoading = false;

      return {
        ...state,
        userFilterList,
        isProcessLoadUserFilter: false,
        isProcessLoadUserMore: false,
      };
    }

    case actionTypes.LOAD_USER_FILTER_FAIL: {
      
      let userFilterList = cloneDeep(state.userFilterList);
      userFilterList.isLoading = false;
      userFilterList.hasMore = false;

      return {
        ...state,
        userFilterList,
        isProcessLoadUserFilter: false,
        isProcessLoadUserMore: false,
      };
    }

    
    case actionTypes.USER_COUNT: {
      
      return {
        ...state,
        userCount: {}
      };
    }

    case actionTypes.USER_COUNT_SUCCESS: {
    
      return {
        ...state,
        userCount:action.data
      };
    }

    case actionTypes.USER_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_USER_DETAIL: {
      let userDetail = {};
      
      return {
        ...state,
        userDetail
      };
    }

    case actionTypes.LOAD_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        userDetail: action.data
      };
    }

    case actionTypes.LOAD_USER_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.EDIT_USER_STATUS: {
      return {
        ...state,
        isUpdateUserStatusSuccess: false
      };
    }

    case actionTypes.EDIT_USER_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateUserStatusSuccess: true
      };
    }

    case actionTypes.EDIT_USER_STATUS_FAIL: {
      return {
        ...state,
        isUpdateUserStatusSuccess: false
      };
    }

    case actionTypes.EDIT_USER_SHOW_VERI_CODE_STATUS: {
      return {
        ...state,
        isUpdateUserLoginShowVeriCodeStatusSuccess: false
      };
    }

    case actionTypes.EDIT_USER_SHOW_VERI_CODE_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateUserLoginShowVeriCodeStatusSuccess: true
      };
    }

    case actionTypes.EDIT_USER_SHOW_VERI_CODE_STATUS_FAIL: {
      return {
        ...state,
        isUpdateUserLoginShowVeriCodeStatusSuccess: false
      };
    }

    case actionTypes.EDIT_USER_VERIFY_STATUS: {
      return {
        ...state,
        isUpdateUserVerifyStatusSuccess: false
      };
    }

    case actionTypes.EDIT_USER_VERIFY_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateUserVerifyStatusSuccess: true
      };
    }

    case actionTypes.EDIT_USER_VERIFY_STATUS_FAIL: {
      return {
        ...state,
        isUpdateUserVerifyStatusSuccess: false
      };
    }


    case actionTypes.SWITCH_USER_TO_ORGANIZATION_TYPE: {
      return {
        ...state,
        isSwitchUserTypeSuccess: false
      };
    }

    case actionTypes.SWITCH_USER_TO_ORGANIZATION_TYPE_SUCCESS: {
      return {
        ...state,
        isSwitchUserTypeSuccess: true
      };
    }

    case actionTypes.SWITCH_USER_TO_ORGANIZATION_TYPE_FAIL: {
      return {
        ...state,
        isSwitchUserTypeSuccess: false
      };
    }

    

    case actionTypes.RECOVERY_USER: {
      return {
        ...state,
        isRecoveryUserSuccess: false
      };
    }

    case actionTypes.RECOVERY_USER_SUCCESS: {
      return {
        ...state,
        isRecoveryUserSuccess: true
      };
    }

    case actionTypes.RECOVERY_USER_FAIL: {
      return {
        ...state,
        isRecoveryUserSuccess: false
      };
    }

    case actionTypes.EDIT_USER_DETAIL: {
      return {
        ...state,
        isUpdateUserDetailSuccess: false
      };
    }

    case actionTypes.EDIT_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        isUpdateUserDetailSuccess: true
      };
    }

    case actionTypes.EDIT_USER_DETAIL_FAIL: {
      return {
        ...state,
        isUpdateUserDetailSuccess: false
      };
    }
    
    
    case actionTypes.LOAD_USER_SUMMARY: {
      let userSummary = {};
      
      return {
        ...state,
        userSummary
      };
    }

    case actionTypes.LOAD_USER_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        userSummary: action.data
      };
    }

    case actionTypes.LOAD_USER_SUMMARY_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.SET_USER_BILLING_ADDRESS: {
      return {
        ...state,
        isSetBillingAddressSuccess: false
      };
    }

    case actionTypes.SET_USER_BILLING_ADDRESS_SUCCESS: {
      return {
        ...state,
        isSetBillingAddressSuccess: true
      };
    }

    case actionTypes.SET_USER_BILLING_ADDRESS_FAIL: {
      return {
        ...state,
        isSetBillingAddressSuccess: false
      };
    }
    
    case actionTypes.LOAD_USER_CREDIT_TERM_REQEUST_LIST: {
      let userCreditTermRequestList = cloneDeep(state.userCreditTermRequestList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        userCreditTermRequestList.offset = action.data.offset;
        userCreditTermRequestList.limit = action.data.limit;
      }

      return {
        ...state,
        userCreditTermRequestList
      };
    }

    case actionTypes.LOAD_USER_CREDIT_TERM_REQEUST_LIST_SUCCESS: {
      let userCreditTermRequestList = cloneDeep(state.userCreditTermRequestList);
      userCreditTermRequestList.data = action.data.data;
      userCreditTermRequestList.meta = {
        page: 1 + state.userCreditTermRequestList.offset / state.userCreditTermRequestList.limit,
        pageSize: state.userCreditTermRequestList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.userCreditTermRequestList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        userCreditTermRequestList
      };
    }

    case actionTypes.LOAD_USER_CREDIT_TERM_REQEUST_LIST_FAIL: {
      return {
        ...state
      };
    }

    

    case actionTypes.APPROVE_USER_CREDIT_TERM_REQEUST: {
      return {
        ...state,
        isCreditTermApproveSuccess: false,
        isCreditTermApproveFail: false,
      };
    }

    case actionTypes.APPROVE_USER_CREDIT_TERM_REQEUST_SUCCESS: {
      return {
        ...state,
        isCreditTermApproveSuccess: true
      };
    }

    case actionTypes.APPROVE_USER_CREDIT_TERM_REQEUST_FAIL: {
      return {
        ...state,
        isCreditTermApproveFail: true
      };
    }

    

    case actionTypes.REJECT_USER_CREDIT_TERM_REQEUST: {
      return {
        ...state,
        isCreditTermRejectSuccess: false
      };
    }

    case actionTypes.REJECT_USER_CREDIT_TERM_REQEUST_SUCCESS: {
      return {
        ...state,
        isCreditTermRejectSuccess: true
      };
    }

    case actionTypes.REJECT_USER_CREDIT_TERM_REQEUST_FAIL: {
      return {
        ...state,
        isCreditTermRejectSuccess: false
      };
    }

    

    case actionTypes.CANCEL_USER_CREDIT_TERM_REQEUST: {
      return {
        ...state,
        isCreditTermCancelSuccess: false
      };
    }

    case actionTypes.CANCEL_USER_CREDIT_TERM_REQEUST_SUCCESS: {
      return {
        ...state,
        isCreditTermCancelSuccess: true
      };
    }

    case actionTypes.CANCEL_USER_CREDIT_TERM_REQEUST_FAIL: {
      return {
        ...state,
        isCreditTermCancelSuccess: false
      };
    }
        


    case actionTypes.SET_USER_NEGO_PRICE: {
      return {
        ...state,
        isSetUserNegoPriceSuccess: false
      };
    }

    case actionTypes.SET_USER_NEGO_PRICE_SUCCESS: {
      return {
        ...state,
        isSetUserNegoPriceSuccess: true
      };
    }

    case actionTypes.SET_USER_NEGO_PRICE_FAIL: {
      return {
        ...state,
        isSetUserNegoPriceSuccess: false
      };
    }

    default:
      return state;
  }
}
