import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";


export function* loadUserOrganizationList() {
  
  yield takeEvery(actionTypes.LOAD_USER_ORGANIZATION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`USERORGLIST${status}${search}`);
    
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadUserOrganizationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadUserOrganizationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadUserOrganizationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadUserOrganizationListFail());
    }
  });
}


export function* resetUserOrganizationList() {
  
  yield takeEvery(actionTypes.RESET_USER_ORGANIZATION_LIST, function*(action) {
    
    yield put(actions.resetUserOrganizationListSuccess());
  });

}


export function* userOrganizationCount() {
  
  yield takeEvery(actionTypes.USER_ORGANIZATION_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("USEROGRCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.userOrganizationCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.userOrganizationCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.userOrganizationCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.userOrganizationCountFail());
    }
  });
}

export function* userOrganizationApprove() {
  
  yield takeEvery(actionTypes.APPROVE_USER_ORGANIZATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRUSERORG");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.userOrganizationApprove(action.id, action.data);
      if (response.data.success) {
        yield put(actions.userOrganizationApproveSuccess());
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.userOrganizationApproveFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.userOrganizationApproveFail());
    }
  });
}

export function* userOrganizationReject() {
  
  yield takeEvery(actionTypes.REJECT_USER_ORGANIZATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTUSERORG");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.userOrganizationReject(action.id, action.data);
      if (response.data.success) {
        yield put(actions.userOrganizationRejectSuccess());
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.userOrganizationRejectFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.userOrganizationRejectFail());
    }
  });
}

export function* addUserOrganization() {
  yield takeEvery(actionTypes.ADD_USER_ORGANIZATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDUSERORG");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addUserOrganization(action.id, action.data);
      if (response.data.success) {
        yield put(actions.addUserOrganizationSuccess());
        yield put(actions.toastSuccess(response.data.message));
        yield put(push(`/organization/${response.data.data}/edit`));
      } else {
        alert(response.data.message);
        yield put(actions.addUserOrganizationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addUserOrganizationFail());
    }
  });
}

export function* editUserOrganization() {
  yield takeEvery(actionTypes.EDIT_USER_ORGANIZATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITUSERORG");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editUserOrganization(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editUserOrganizationSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.editUserOrganizationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editUserOrganizationFail());
    }
  });
}


export function* deleteUserOrganization() {
  yield takeEvery(actionTypes.DELETE_USER_ORGANIZATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELUSERORG");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteUserOrganization(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteUserOrganizationSuccess());
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteUserOrganizationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteUserOrganizationFail());
    }
  });
}

export function* setUserOrganizationNegoPrice() {
  yield takeEvery(actionTypes.SET_USER_ORGANIZATION_NEGO_PRICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETORGNEGOPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setUserOrganizationNegoPrice(action.id, action.data);
      if (response.data.success) {
        yield put(actions.setUserOrganizationNegoPriceSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.setUserOrganizationNegoPriceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setUserOrganizationNegoPriceFail());
    }
  });
}

export function* setUserOrganizationMonthlyPriceReducing() {
  yield takeEvery(actionTypes.SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETORGMPRM");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setUserOrganizationMonthlyPriceReducing(action.id, action.data);
      if (response.data.success) {
        yield put(actions.setUserOrganizationMonthlyPriceReducingSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.setUserOrganizationMonthlyPriceReducingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setUserOrganizationMonthlyPriceReducingFail());
    }
  });
}


export function* loadUserOrganizationDetail() {
  
  yield takeEvery(actionTypes.LOAD_USER_ORGANIZATION_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("USERORGDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadUserOrganizationDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadUserOrganizationDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadUserOrganizationDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadUserOrganizationDetailFail());
    }
  });
}



export function* getUserOrganizationLandfillDistance() {
  
  yield takeEvery(actionTypes.USER_ORGANIZATION_LANDFILL_DISTANCE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("USERORGLANDFILLDISTANCE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getUserOrganizationLandfillDistance(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.getUserOrganizationLandfillDistanceSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getUserOrganizationLandfillDistanceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getUserOrganizationLandfillDistanceFail());
    }
  });
}


export function* loadNegoPriceEffectiveDateList() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGOPRICEEFFECTIVELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadNegoPriceEffectiveDateList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadNegoPriceEffectiveDateListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoPriceEffectiveDateListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoPriceEffectiveDateListFail());
    }
  });
}

export function* loadNegoDumpWastePriceList() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPWASTEPRICELSIT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpWastePriceList(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpWastePriceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpWastePriceListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpWastePriceListFail());
    }
  });
}

export function* loadNegoDumpWastePriceAdj() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPWASTEPRICEADJ");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpWastePriceList(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpWastePriceAdjSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpWastePriceAdjFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpWastePriceAdjFail());
    }
  });
}


export function* loadNegoDumpWastePricePDF() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPWASTEPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpWastePricePDF(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpWastePricePDFSuccess(response.data.data));
        yield put(actions.countNewQuotation());
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpWastePricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpWastePricePDFFail());
    }
  });
}

export function* loadNegoDumpRecyclingPriceList() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPRECYCLINGPRICELIST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpRecyclingPriceList(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpRecyclingPriceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpRecyclingPriceListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpRecyclingPriceListFail());
    }
  });
}


export function* loadNegoDumpRecyclingPriceAdj() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPRECYCLINGPRICEADJ");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpRecyclingPriceList(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpRecyclingPriceAdjSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpRecyclingPriceAdjFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpRecyclingPriceAdjFail());
    }
  });
}

export function* loadNegoDumpRecyclingPricePDF() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPRECYCLINGPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpRecyclingPricePDF(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpRecyclingPricePDFSuccess(response.data.data));
        yield put(actions.countNewQuotation());
        
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpRecyclingPricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpRecyclingPricePDFFail());
    }
  });
}


export function* loadNegoRecyclingPriceList() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_RECYCLING_PRICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGORECYCLINGPRICELIST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoRecyclingPriceList(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoRecyclingPriceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoRecyclingPriceListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoRecyclingPriceListFail());
    }
  });
}


export function* loadNegoRecyclingPricePDF() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_RECYCLING_PRICE_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGORECYCLINGPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoRecyclingPricePDF(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoRecyclingPricePDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoRecyclingPricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoRecyclingPricePDFFail());
    }
  });
}




export function* loadNegoDumpExpressPriceList() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPEXPRESSPRICELIST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpExpressPriceList(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpExpressPriceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpExpressPriceListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpExpressPriceListFail());
    }
  });
}


export function* loadNegoDumpExpressPriceAdj() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPEXPRESSPRICEADJ");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpExpressPriceList(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpExpressPriceAdjSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpExpressPriceAdjFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpExpressPriceAdjFail());
    }
  });
}

export function* loadNegoDumpExpressPricePDF() {
  
  yield takeEvery(actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEGODUMPEXPRESSPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNegoDumpExpressPricePDF(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNegoDumpExpressPricePDFSuccess(response.data.data));
        yield put(actions.countNewQuotation());
        
      } else {
        alert(response.data.message);
        yield put(actions.loadNegoDumpExpressPricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNegoDumpExpressPricePDFFail());
    }
  });
}

export function* resetNegoDumpWastePriceList() {
  
  yield takeEvery(actionTypes.RESET_NEGO_DUMP_WASTE_PRICE_LIST, function*(action) {
    
    yield put(actions.loadNegoDumpWastePriceListSuccess([]));
  });
}

export function* resetNegoDumpRecyclingPriceList() {
  
  yield takeEvery(actionTypes.RESET_NEGO_DUMP_RECYCLING_PRICE_LIST, function*(action) {
    
    yield put(actions.loadNegoDumpRecyclingPriceListSuccess([]));
  });
}

export function* resetNegoRecyclingPriceList() {
  
  yield takeEvery(actionTypes.RESET_NEGO_RECYCLING_PRICE_LIST, function*(action) {
    
    yield put(actions.loadNegoDumpWastePriceListSuccess([]));
  });
}



export function* countNewQuotation() {
  
  yield takeEvery(actionTypes.NEW_QUOTATION_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NEWQUOTATIONCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.countNewQuotation(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.countNewQuotationSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.countNewQuotationFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.countNewQuotationFail());
    }
  });
}



export function* loadDumpWasteQuotationList() {
  
  yield takeEvery(actionTypes.DUMPWASTE_QUOTATION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""

    let track_no = Helper.generateTrackNo(`DUMPWASTEQUOTATIONLIST${status}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpWasteQuotationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpWasteQuotationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpWasteQuotationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpWasteQuotationListFail());
    }
  });
}


export function* loadDumpRecyclingQuotationList() {
  
  yield takeEvery(actionTypes.DUMPRECYCLING_QUOTATION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""

    let track_no = Helper.generateTrackNo(`DUMPRECYCLINGQUOTATIONLIST${status}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpRecyclingQuotationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpRecyclingQuotationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpRecyclingQuotationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpRecyclingQuotationListFail());
    }
  });
}


export function* loadDumpExpressQuotationList() {
  
  yield takeEvery(actionTypes.DUMPEXPRESS_QUOTATION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""

    let track_no = Helper.generateTrackNo(`DUMPEXPRESSQUOTATIONLIST${status}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpExpressQuotationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpExpressQuotationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpExpressQuotationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpExpressQuotationListFail());
    }
  });
}

export function* terminateQuotation() {
  
  yield takeEvery(actionTypes.TERMINATE_QUOTATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("TERMINATEQUOTATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.terminateQuotation(action.id, action.data);
      if (response.data.success) {
        yield put(actions.terminateQuotationSuccess());
      } else {
        alert(response.data.message);
        yield put(actions.terminateQuotationFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.terminateQuotationFail());
    }
  });
}



export function* loadEInvoiceList() {
  
  yield takeEvery(actionTypes.LOAD_EINVOICE_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let branch = (action.data.branch) ? action.data.branch : ""

    let track_no = Helper.generateTrackNo(`EINVOICELIST${branch}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadEInvoiceList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadEInvoiceListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadEInvoiceListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadEInvoiceListFail());
    }
  });
}

export function* updateEInvoice() {
  
  yield takeEvery(actionTypes.UPDATE_EINVOICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDEINVOICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateEInvoice(action.id, action.branch, action.data);
      if (response.data.success) {
        yield put(actions.updateEInvoiceSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.updateEInvoiceFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateEInvoiceFail());
    }
  });
}

export default function* userOrganizationSaga() {
  
  yield all([
    fork(loadUserOrganizationList),
    fork(resetUserOrganizationList),
    fork(userOrganizationCount),
    fork(userOrganizationApprove),
    fork(userOrganizationReject),
    fork(addUserOrganization),
    fork(editUserOrganization),
    fork(deleteUserOrganization),
    fork(loadUserOrganizationDetail),
    fork(setUserOrganizationNegoPrice),
    fork(setUserOrganizationMonthlyPriceReducing),
    fork(getUserOrganizationLandfillDistance),
    
    fork(loadNegoPriceEffectiveDateList),
    fork(loadNegoDumpWastePriceList),
    fork(loadNegoDumpWastePriceAdj),
    fork(loadNegoDumpWastePricePDF),
    fork(loadNegoDumpRecyclingPriceList),
    fork(loadNegoDumpRecyclingPriceAdj),
    fork(loadNegoDumpRecyclingPricePDF),
    fork(loadNegoRecyclingPriceList),
    fork(loadNegoRecyclingPricePDF),
    fork(loadNegoDumpExpressPriceList),
    fork(loadNegoDumpExpressPriceAdj),
    fork(loadNegoDumpExpressPricePDF),
    fork(resetNegoDumpWastePriceList),
    fork(resetNegoDumpRecyclingPriceList),
    fork(resetNegoRecyclingPriceList),
    fork(countNewQuotation),
    fork(loadDumpWasteQuotationList),
    fork(loadDumpRecyclingQuotationList),
    fork(loadDumpExpressQuotationList),
    fork(terminateQuotation),
    
    fork(loadEInvoiceList),
    fork(updateEInvoice),
  ]);
  
}
