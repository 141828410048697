import * as actionTypes from "./actionTypes";


export const loadUserOrganizationList = data => {
  return {
    type: actionTypes.LOAD_USER_ORGANIZATION_LIST,
    data
  };
};

export const loadUserOrganizationListSuccess = data => {
  return {
    type: actionTypes.LOAD_USER_ORGANIZATION_LIST_SUCCESS,
    data
  };
};

export const loadUserOrganizationListFail = () => {
  return {
    type: actionTypes.LOAD_USER_ORGANIZATION_LIST_FAIL
  };
};



export const resetUserOrganizationList = () => {
  return {
    type: actionTypes.RESET_USER_ORGANIZATION_LIST,
  };
};

export const resetUserOrganizationListSuccess = () => {
  return {
    type: actionTypes.RESET_USER_ORGANIZATION_LIST_SUCCESS,
  };
};


export const userOrganizationCount = data => {
  return {
    type: actionTypes.USER_ORGANIZATION_COUNT,
    data
  };
};

export const userOrganizationCountSuccess = data => {
  return {
    type: actionTypes.USER_ORGANIZATION_COUNT_SUCCESS,
    data
  };
};

export const userOrganizationCountFail = () => {
  return {
    type: actionTypes.USER_ORGANIZATION_COUNT_FAIL
  };
};

export const userOrganizationApprove = (id, data) => {
  return {
    type: actionTypes.APPROVE_USER_ORGANIZATION,
    id,
    data
  };
};

export const userOrganizationApproveSuccess = () => {
  return {
    type: actionTypes.APPROVE_USER_ORGANIZATION_SUCCESS
  };
};

export const userOrganizationApproveFail = () => {
  return {
    type: actionTypes.APPROVE_USER_ORGANIZATION_FAIL
  };
};


export const userOrganizationReject = (id, data) => {
  return {
    type: actionTypes.REJECT_USER_ORGANIZATION,
    id,
    data
  };
};

export const userOrganizationRejectSuccess = () => {
  return {
    type: actionTypes.REJECT_USER_ORGANIZATION_SUCCESS
  };
};

export const userOrganizationRejectFail = () => {
  return {
    type: actionTypes.REJECT_USER_ORGANIZATION_FAIL
  };
};


export const addUserOrganization = (id,data) => {
  return {
    type: actionTypes.ADD_USER_ORGANIZATION,
    id,
    data
  };
};

export const addUserOrganizationSuccess = () => {
  return {
    type: actionTypes.ADD_USER_ORGANIZATION_SUCCESS
  };
};

export const addUserOrganizationFail = () => {
  return {
    type: actionTypes.ADD_USER_ORGANIZATION_FAIL
  };
};

export const editUserOrganization = (id,data) => {
  return {
    type: actionTypes.EDIT_USER_ORGANIZATION,
    id,
    data
  };
};

export const editUserOrganizationSuccess = () => {
  return {
    type: actionTypes.EDIT_USER_ORGANIZATION_SUCCESS
  };
};

export const editUserOrganizationFail = () => {
  return {
    type: actionTypes.EDIT_USER_ORGANIZATION_FAIL
  };
};

export const deleteUserOrganization = (id, data) => {
  return {
    type: actionTypes.DELETE_USER_ORGANIZATION,
    id,
    data
  };
};

export const deleteUserOrganizationSuccess = () => {
  return {
    type: actionTypes.DELETE_USER_ORGANIZATION_SUCCESS
  };
};

export const deleteUserOrganizationFail = () => {
  return {
    type: actionTypes.DELETE_USER_ORGANIZATION_FAIL
  };
};


export const getUserOrganizationLandfillDistance = (id,data) => {
  return {
    type: actionTypes.USER_ORGANIZATION_LANDFILL_DISTANCE,
    id,
    data
  };
};

export const getUserOrganizationLandfillDistanceSuccess = (data) => {
  return {
    type: actionTypes.USER_ORGANIZATION_LANDFILL_DISTANCE_SUCCESS,
    data
  };
};

export const getUserOrganizationLandfillDistanceFail = () => {
  return {
    type: actionTypes.USER_ORGANIZATION_LANDFILL_DISTANCE_FAIL
  };
};



export const setUserOrganizationNegoPrice = (id,data) => {
  return {
    type: actionTypes.SET_USER_ORGANIZATION_NEGO_PRICE,
    id,
    data
  };
};

export const setUserOrganizationNegoPriceSuccess = () => {
  return {
    type: actionTypes.SET_USER_ORGANIZATION_NEGO_PRICE_SUCCESS
  };
};

export const setUserOrganizationNegoPriceFail = () => {
  return {
    type: actionTypes.SET_USER_ORGANIZATION_NEGO_PRICE_FAIL
  };
};

export const setUserOrganizationMonthlyPriceReducing = (id,data) => {
  return {
    type: actionTypes.SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING,
    id,
    data
  };
};

export const setUserOrganizationMonthlyPriceReducingSuccess = () => {
  return {
    type: actionTypes.SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING_SUCCESS
  };
};

export const setUserOrganizationMonthlyPriceReducingFail = () => {
  return {
    type: actionTypes.SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING_FAIL
  };
};



export const loadUserOrganizationDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_USER_ORGANIZATION_DETAIL,
    id,
    data
  };
};

export const loadUserOrganizationDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_USER_ORGANIZATION_DETAIL_SUCCESS,
    data
  };
};

export const loadUserOrganizationDetailFail = () => {
  return {
    type: actionTypes.LOAD_USER_ORGANIZATION_DETAIL_FAIL
  };
};



export const loadNegoPriceEffectiveDateList = (data) => {
  return {
    type: actionTypes.LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST,
    data
  };
};

export const loadNegoPriceEffectiveDateListSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST_SUCCESS,
    data
  };
};

export const loadNegoPriceEffectiveDateListFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST_FAIL
  };
};


export const loadNegoDumpWastePriceList = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_LIST,
    id,
    data
  };
};

export const loadNegoDumpWastePriceListSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_LIST_SUCCESS,
    data
  };
};

export const loadNegoDumpWastePriceListFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_LIST_FAIL
  };
};

export const loadNegoDumpWastePriceAdj = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT,
    id,
    data
  };
};

export const loadNegoDumpWastePriceAdjSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT_SUCCESS,
    data
  };
};

export const loadNegoDumpWastePriceAdjFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT_FAIL
  };
};


export const loadNegoDumpWastePricePDF = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_PDF,
    id,
    data
  };
};

export const loadNegoDumpWastePricePDFSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_PDF_SUCCESS,
    data
  };
};

export const loadNegoDumpWastePricePDFFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_PDF_FAIL
  };
};


export const loadNegoDumpRecyclingPriceList = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST,
    id,
    data
  };
};

export const loadNegoDumpRecyclingPriceListSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST_SUCCESS,
    data
  };
};

export const loadNegoDumpRecyclingPriceListFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST_FAIL
  };
};



export const loadNegoDumpRecyclingPriceAdj = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT,
    id,
    data
  };
};

export const loadNegoDumpRecyclingPriceAdjSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT_SUCCESS,
    data
  };
};

export const loadNegoDumpRecyclingPriceAdjFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT_FAIL
  };
};

export const loadNegoDumpRecyclingPricePDF = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF,
    id,
    data
  };
};

export const loadNegoDumpRecyclingPricePDFSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF_SUCCESS,
    data
  };
};

export const loadNegoDumpRecyclingPricePDFFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF_FAIL
  };
};


export const loadNegoRecyclingPriceList = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_RECYCLING_PRICE_LIST,
    id,
    data
  };
};

export const loadNegoRecyclingPriceListSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_RECYCLING_PRICE_LIST_SUCCESS,
    data
  };
};

export const loadNegoRecyclingPriceListFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_RECYCLING_PRICE_LIST_FAIL
  };
};

export const loadNegoRecyclingPricePDF = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_RECYCLING_PRICE_PDF,
    id,
    data
  };
};

export const loadNegoRecyclingPricePDFSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_RECYCLING_PRICE_PDF_SUCCESS,
    data
  };
};

export const loadNegoRecyclingPricePDFFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_RECYCLING_PRICE_PDF_FAIL
  };
};


export const loadNegoDumpExpressPriceList = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST,
    id,
    data
  };
};

export const loadNegoDumpExpressPriceListSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST_SUCCESS,
    data
  };
};

export const loadNegoDumpExpressPriceListFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST_FAIL
  };
};


export const loadNegoDumpExpressPriceAdj = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT,
    id,
    data
  };
};

export const loadNegoDumpExpressPriceAdjSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT_SUCCESS,
    data
  };
};

export const loadNegoDumpExpressPriceAdjFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT_FAIL
  };
};

export const loadNegoDumpExpressPricePDF = (id,data) => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF,
    id,
    data
  };
};

export const loadNegoDumpExpressPricePDFSuccess = data => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF_SUCCESS,
    data
  };
};

export const loadNegoDumpExpressPricePDFFail = () => {
  return {
    type: actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF_FAIL
  };
};



export const resetNegoDumpWastePriceList = (data) => {
  return {
    type: actionTypes.RESET_NEGO_DUMP_WASTE_PRICE_LIST,
    data
  };
};


export const resetNegoDumpRecyclingPriceList = () => {
  return {
    type: actionTypes.RESET_NEGO_DUMP_RECYCLING_PRICE_LIST
  };
};


export const resetNegoRecyclingPriceList = () => {
  return {
    type: actionTypes.RESET_NEGO_RECYCLING_PRICE_LIST
  };
};




export const countNewQuotation = (data) => {
  return {
    type: actionTypes.NEW_QUOTATION_COUNT,
    data
  };
};

export const countNewQuotationSuccess = data => {
  return {
    type: actionTypes.NEW_QUOTATION_COUNT_SUCCESS,
    data
  };
};

export const countNewQuotationFail = () => {
  return {
    type: actionTypes.NEW_QUOTATION_COUNT_FAIL
  };
};


export const loadDumpWasteQuotationList = (data) => {
  return {
    type: actionTypes.DUMPWASTE_QUOTATION_LIST,
    data
  };
};

export const loadDumpWasteQuotationListSuccess = data => {
  return {
    type: actionTypes.DUMPWASTE_QUOTATION_LIST_SUCCESS,
    data
  };
};

export const loadDumpWasteQuotationListFail = () => {
  return {
    type: actionTypes.DUMPWASTE_QUOTATION_LIST_FAIL
  };
};


export const loadDumpRecyclingQuotationList = (data) => {
  return {
    type: actionTypes.DUMPRECYCLING_QUOTATION_LIST,
    data
  };
};

export const loadDumpRecyclingQuotationListSuccess = data => {
  return {
    type: actionTypes.DUMPRECYCLING_QUOTATION_LIST_SUCCESS,
    data
  };
};

export const loadDumpRecyclingQuotationListFail = () => {
  return {
    type: actionTypes.DUMPRECYCLING_QUOTATION_LIST_FAIL
  };
};


export const loadDumpExpressQuotationList = (data) => {
  return {
    type: actionTypes.DUMPEXPRESS_QUOTATION_LIST,
    data
  };
};

export const loadDumpExpressQuotationListSuccess = data => {
  return {
    type: actionTypes.DUMPEXPRESS_QUOTATION_LIST_SUCCESS,
    data
  };
};

export const loadDumpExpressQuotationListFail = () => {
  return {
    type: actionTypes.DUMPEXPRESS_QUOTATION_LIST_FAIL
  };
};


export const terminateQuotation = (id, data) => {
  return {
    type: actionTypes.TERMINATE_QUOTATION,
    id,
    data
  };
};

export const terminateQuotationSuccess = () => {
  return {
    type: actionTypes.TERMINATE_QUOTATION_SUCCESS
  };
};

export const terminateQuotationFail = () => {
  return {
    type: actionTypes.TERMINATE_QUOTATION_FAIL
  };
};





export const loadEInvoiceList = (data) => {
  return {
    type: actionTypes.LOAD_EINVOICE_LIST,
    data
  };
};

export const loadEInvoiceListSuccess = (data) => {
  return {
    type: actionTypes.LOAD_EINVOICE_LIST_SUCCESS,
    data
  };
};

export const loadEInvoiceListFail = () => {
  return {
    type: actionTypes.LOAD_EINVOICE_LIST_FAIL
  };
};



export const updateEInvoice = (id, branch, data) => {
  return {
    type: actionTypes.UPDATE_EINVOICE,
    id,
    branch,
    data
  };
};

export const updateEInvoiceSuccess = () => {
  return {
    type: actionTypes.UPDATE_EINVOICE_SUCCESS
  };
};

export const updateEInvoiceFail = () => {
  return {
    type: actionTypes.UPDATE_EINVOICE_FAIL
  };
};