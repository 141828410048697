import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/admin/branch";
};


export const loadUserOrganizationList = data => {
  const url = `${baseURL()}/list`;
  return axios.post(url, data);
};

export const userOrganizationCount = data => {
  const url = `${baseURL()}/count`;
  return axios.post(url, data);
};

export const userOrganizationApprove = (id, data) => {
  const url = `${baseURL()}/approve/${id}`;
  return axios.post(url, data);
};

export const userOrganizationReject = (id, data) => {
  const url = `${baseURL()}/reject/${id}`;
  return axios.post(url, data);
};

export const addUserOrganization = (id, data) => {
  const url = `${baseURL()}/create/${id}`;
  return axios.post(url, data);
};

export const editUserOrganization = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const loadUserOrganizationDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};

export const deleteUserOrganization = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};

export const getUserOrganizationLandfillDistance = (id, data) => {
  const url = `${baseURL()}/landfill/distance/${id}`;
  return axios.post(url, data);
};

export const setUserOrganizationNegoPrice = (id, data) => {
  const url = `${baseURL()}/negoPrice/set/${id}`;
  // const url = `http://192.168.100.61:3202/admin/branch/negoPrice/set/${id}`;
  return axios.post(url, data);
};

export const setUserOrganizationMonthlyPriceReducing = (id, data) => {
  const url = `${baseURL()}/negoPrice/monthlyPriceReducing/${id}`;
  return axios.post(url, data);
};


export const loadNegoPriceEffectiveDateList = (data) => {
  const url = `${baseURL()}/negoPrice/effectiveDate`;
  return axios.post(url, data);
};

export const loadNegoDumpWastePriceList = (id, data) => {
  const url = `${baseURL()}/negoPrice/dumpWaste/load/${id}`;
  return axios.post(url, data);
};

export const loadNegoDumpWastePricePDF = (id, data) => {
  const url = `${baseURL()}/negoPrice/dumpWaste/pdf/${id}`;
  return axios.post(url, data);
};


export const loadNegoDumpRecyclingPriceList = (id, data) => {
  const url = `${baseURL()}/negoPrice/dumpRecycling/load/${id}`;
  return axios.post(url, data);
};


export const loadNegoDumpRecyclingPricePDF = (id, data) => {
  const url = `${baseURL()}/negoPrice/dumpRecycling/pdf/${id}`;
  return axios.post(url, data);
};


export const loadNegoRecyclingPriceList = (id, data) => {
  const url = `${baseURL()}/negoPrice/recycling/load/${id}`;
  return axios.post(url, data);
};


export const loadNegoDumpExpressPriceList = (id, data) => {
  const url = `${baseURL()}/negoPrice/dumpExpress/load/${id}`;
  return axios.post(url, data);
};


export const loadNegoDumpExpressPricePDF = (id, data) => {
  const url = `${baseURL()}/negoPrice/dumpExpress/pdf/${id}`;
  return axios.post(url, data);
};


export const loadNegoRecyclingPricePDF = (id, data) => {
  const url = `${baseURL()}/negoPrice/dumpRecycling/pdf/${id}`;
  return axios.post(url, data);
};


export const countNewQuotation = (data) => {
  const url = `${baseURL()}/quotation/count`;
  return axios.post(url, data);
};

export const loadDumpWasteQuotationList = (data) => {
  const url = `${baseURL()}/quotation/list`;
  return axios.post(url, data);
};

export const loadDumpRecyclingQuotationList = (data) => {
  const url = `${baseURL()}/quotation/list`;
  return axios.post(url, data);
};

export const loadDumpExpressQuotationList = (data) => {
  const url = `${baseURL()}/quotation/list`;
  return axios.post(url, data);
};

export const terminateQuotation = (id, data) => {
  const url = `${baseURL()}/quotation/terminate/${id}`;
  return axios.post(url, data);
};




export const loadEInvoiceList = (data) => {
  const url = `${baseURL()}/einvoice/list`;
  return axios.post(url, data);
};

export const updateEInvoice = (id, branch, data) => {
  let url = `${baseURL()}/einvoice/update/${branch}`;

  if(id) url += `/${id}`;
  
  return axios.post(url, data);
};