import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  driverList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    hasMore: true
  },
  isProcessLoadDriver:false,
  driverFilterList:{
    data: [],
    total: 0,
    offset: 0,
    limit: 10,
    isLoading: false,
    hasMore: true
  },
  isProcessLoadDriverFilter: false,
  isProcessLoadDriverMore: false,
  driverDetail:{},
  isProcessLoadDriverDetail:false,
  removeDriverConfirm: null,
  isUpdateDriverStatusSuccess: false,
  isUpdateDriverLoginShowVeriCodeStatusSuccess: false,
  isUpdateDriverSubscriptionStatusSuccess: false,
  isRecoveryDriverSuccess: false,
  isUploadDriverDocSuccess: false,
  driverDocumentationCount: {},
  driverDocumentList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadDriverDocument:false,
  isApproveDriverDocumentSuccess:false,
  isRejectDriverDocumentSuccess: false,
  isApplyScheduledWasteServiceSuccess: false,
  isUpdateDriverVerifyStatusSuccess: false,
  isRejectDriverVerifyStatusSuccess: false,
  isUpdateDriverDetailSuccess: false,
  driverTrackList:{
    data: [],
    total: 0,
    offset: 0,
    limit: 10,
    isLoading: false,
    hasMore: true
  },
  driverTrack:{},
  driverSummary:{},
  driverCount:{},
  driverLorryDumpster:{},
  driverTransportLicenseList:{
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  driverTransportLicenseCount:{},
  driverTransportLicenseVerifySuccess: false,
  driverTransportLicenseRejectSuccess: false,
  driverScheduledWasteSetRecovererSuccess: false,
};
   
export default function driverReducer(state = initState, action) {

  switch (action.type) {
    case actionTypes.LOAD_DRIVER_LIST: {
      let driverList = cloneDeep(state.driverList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverList.offset = action.data.offset;
        driverList.limit = action.data.limit;
      }

      if(Object.keys(action.data).includes("assignOrder"))
      {
        driverList.data = [];
      }

      return {
        ...state,
        driverList,
        isProcessLoadDriver: true
      };
    }

    case actionTypes.LOAD_DRIVER_LIST_SUCCESS: {
      let driverList = cloneDeep(state.driverList);
      driverList.data = action.data.data;
      driverList.meta = {
        page: 1 + state.driverList.offset / state.driverList.limit,
        pageSize: state.driverList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.driverList.limit
        ),
        total: action.data.recordsTotal
      };
      
      return {
        ...state,
        driverList,
        isProcessLoadDriver:false,
      };
    }

    case actionTypes.LOAD_DRIVER_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadDriver:false
      };
    }

    
    case actionTypes.LOAD_DRIVER_FILTER: {
      
      let driverFilterList = cloneDeep(state.driverFilterList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverFilterList.offset = action.data.offset;
        driverFilterList.limit = action.data.limit;
      }

      driverFilterList.isLoading = true;
      
      if (action.data.offset === 0) 
        driverFilterList.data = [];

      return {
        ...state,
        driverFilterList,
        isProcessLoadDriverFilter: (action.data.offset === 0) ? true : false,
        isProcessLoadDriverMore: (action.data.offset === 0) ? false : true,
      };
    }

    case actionTypes.LOAD_DRIVER_FILTER_SUCCESS: {
      
      let driverFilterList = cloneDeep(state.driverFilterList);
      
      if(action.data.data.length > 0)
      {
        driverFilterList.data = [...driverFilterList.data, ...action.data.data];        
        driverFilterList.hasMore = (action.data.data.length < state.driverFilterList.limit) ? false : true;
      }else
        driverFilterList.hasMore = false;

      driverFilterList.isLoading = false;

      return {
        ...state,
        driverFilterList,
        isProcessLoadDriverFilter: false,
        isProcessLoadDriverMore: false,
      };
    }

    case actionTypes.LOAD_DRIVER_FILTER_FAIL: {
      
      let driverFilterList = cloneDeep(state.driverFilterList);
      driverFilterList.isLoading = false;
      driverFilterList.hasMore = false;

      return {
        ...state,
        driverFilterList,
        isProcessLoadDriverFilter: false,
        isProcessLoadDriverMore: false,
      };
    }

    
    case actionTypes.DRIVER_COUNT: {
      
      return {
        ...state,
        driverCount: {}
      };
    }

    case actionTypes.DRIVER_COUNT_SUCCESS: {
    
      return {
        ...state,
        driverCount:action.data
      };
    }

    case actionTypes.DRIVER_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_DRIVER_DETAIL: {
      
      return {
        ...state,
        driverDetail: {},
        isProcessLoadDriverDetail: true,
      };
    }

    case actionTypes.LOAD_DRIVER_DETAIL_SUCCESS: {
      return {
        ...state,
        driverDetail: action.data,
        isProcessLoadDriverDetail: false,
      };
    }

    case actionTypes.LOAD_DRIVER_DETAIL_FAIL: {
      return {
        ...state,
        isProcessLoadDriverDetail: false,
      };
    }

    
    
    case actionTypes.REMOVE_DRIVER: {
      return {
        ...state,
        removeDriverConfirm: null
      };
    }

    case actionTypes.REMOVE_DRIVER_SUCCESS: {
      return {
        ...state,
        removeDriverConfirm: (action.data) ? action.data : null
      };
    }
    

    case actionTypes.RECOVERY_DRIVER: {
      return {
        ...state,
        isRecoveryDriverSuccess: false
      };
    }

    case actionTypes.RECOVERY_DRIVER_SUCCESS: {
      return {
        ...state,
        isRecoveryDriverSuccess: true
      };
    }

    case actionTypes.RECOVERY_DRIVER_FAIL: {
      return {
        ...state,
        isRecoveryDriverSuccess: false
      };
    }

    case actionTypes.UPLOAD_DRIVER_DOC: {
      return {
        ...state,
        isUploadDriverDocSuccess: false
      };
    }

    case actionTypes.UPLOAD_DRIVER_DOC_SUCCESS: {
      return {
        ...state,
        isUploadDriverDocSuccess: true
      };
    }

    case actionTypes.UPLOAD_DRIVER_DOC_FAIL: {
      return {
        ...state,
        isUploadDriverDocSuccess: false
      };
    }
    

    
  
    case actionTypes.DRIVER_DOCUMENTATION_COUNT: {
      
      return {
        ...state,
        driverDocumentationCount: {}
      };
    }

    case actionTypes.DRIVER_DOCUMENTATION_COUNT_SUCCESS: {
    
      return {
        ...state,
        driverDocumentationCount:action.data
      };
    }

    case actionTypes.DRIVER_DOCUMENTATION_COUNT_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.DRIVER_DOCUMENTATION_LIST: {
      let driverDocumentList = cloneDeep(state.driverDocumentList);

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverDocumentList.offset = action.data.offset;
        driverDocumentList.limit = action.data.limit;
      }

      if (action.data.offset === 0) 
        driverDocumentList.data = [];

      return {
        ...state,
        driverDocumentList,
        isProcessLoadDriverDocument: true
      };
    }

    case actionTypes.DRIVER_DOCUMENTATION_LIST_SUCCESS: {
      let driverDocumentList = cloneDeep(state.driverDocumentList);
      driverDocumentList.data = action.data.data;
      driverDocumentList.meta = {
        page: 1 + state.driverDocumentList.offset / state.driverDocumentList.limit,
        pageSize: state.driverDocumentList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.driverDocumentList.limit
        ),
        total: action.data.recordsTotal
      };
      
      return {
        ...state,
        driverDocumentList,
        isProcessLoadDriverDocument:false,
      };
    }

    case actionTypes.DRIVER_DOCUMENTATION_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadDriverDocument:false,
      };
    }
    
    case actionTypes.RESET_DRIVER_DOCUMENTATION_LIST: {

      return {
        ...state,
        driverDocumentList: {
          data: [],
          offset: 0,
          limit: 10,
          meta: {
            page: 1,
            pageSize: 0,
            pageTotal: 1,
            total: 0,
            pageSizeOptions: [5, 10, 20, 30, 50, 100]
          }
        },
        isProcessLoadDriverDocument:false,
      };
    }
    

    case actionTypes.APPROVE_DRIVER_DOCUMENTATION: {
      return {
        ...state,
        isApproveDriverDocumentSuccess: false
      };
    }

    case actionTypes.APPROVE_DRIVER_DOCUMENTATION_SUCCESS: {
      return {
        ...state,
        isApproveDriverDocumentSuccess: true
      };
    }

    case actionTypes.APPROVE_DRIVER_DOCUMENTATION_FAIL: {
      return {
        ...state,
        isApproveDriverDocumentSuccess: false
      };
    }
    

    case actionTypes.REJECT_DRIVER_DOCUMENTATION: {
      return {
        ...state,
        isRejectDriverDocumentSuccess: false
      };
    }

    case actionTypes.REJECT_DRIVER_DOCUMENTATION_SUCCESS: {
      return {
        ...state,
        isRejectDriverDocumentSuccess: true
      };
    }

    case actionTypes.REJECT_DRIVER_DOCUMENTATION_FAIL: {
      return {
        ...state,
        isRejectDriverDocumentSuccess: false
      };
    }
    
    case actionTypes.APPLY_SCHEDULED_WASTE_SERVICE: {
      return {
        ...state,
        isApplyScheduledWasteServiceSuccess: false
      };
    }

    case actionTypes.APPLY_SCHEDULED_WASTE_SERVICE_SUCCESS: {
      return {
        ...state,
        isApplyScheduledWasteServiceSuccess: true
      };
    }

    case actionTypes.APPLY_SCHEDULED_WASTE_SERVICE_FAIL: {
      return {
        ...state,
        isApplyScheduledWasteServiceSuccess: false
      };
    }
    
    case actionTypes.EDIT_DRIVER_STATUS: {
      return {
        ...state,
        isUpdateDriverStatusSuccess: false
      };
    }

    case actionTypes.EDIT_DRIVER_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateDriverStatusSuccess: true
      };
    }

    case actionTypes.EDIT_DRIVER_STATUS_FAIL: {
      return {
        ...state,
        isUpdateDriverStatusSuccess: false
      };
    }

    case actionTypes.EDIT_DRIVER_SHOW_VERI_CODE_STATUS: {
      return {
        ...state,
        isUpdateDriverLoginShowVeriCodeStatusSuccess: false
      };
    }

    case actionTypes.EDIT_DRIVER_SHOW_VERI_CODE_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateDriverLoginShowVeriCodeStatusSuccess: true
      };
    }

    case actionTypes.EDIT_DRIVER_SHOW_VERI_CODE_STATUS_FAIL: {
      return {
        ...state,
        isUpdateDriverLoginShowVeriCodeStatusSuccess: false
      };
    }

    case actionTypes.EDIT_DRIVER_SUBSCRIPTION_STATUS: {
      return {
        ...state,
        isUpdateDriverSubscriptionStatusSuccess: false
      };
    }

    case actionTypes.EDIT_DRIVER_SUBSCRIPTION_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateDriverSubscriptionStatusSuccess: true
      };
    }

    case actionTypes.EDIT_DRIVER_SUBSCRIPTION_STATUS_FAIL: {
      return {
        ...state,
        isUpdateDriverSubscriptionStatusSuccess: false
      };
    }
    
    case actionTypes.EDIT_DRIVER_VERIFY_STATUS: {
      return {
        ...state,
        isUpdateDriverVerifyStatusSuccess: false
      };
    }

    case actionTypes.EDIT_DRIVER_VERIFY_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateDriverVerifyStatusSuccess: true
      };
    }

    case actionTypes.EDIT_DRIVER_VERIFY_STATUS_FAIL: {
      return {
        ...state,
        isUpdateDriverVerifyStatusSuccess: false
      };
    }

    case actionTypes.REJECT_DRIVER_VERIFY_STATUS: {
      return {
        ...state,
        isRejectDriverVerifyStatusSuccess: false
      };
    }

    case actionTypes.REJECT_DRIVER_VERIFY_STATUS_SUCCESS: {
      return {
        ...state,
        isRejectDriverVerifyStatusSuccess: true
      };
    }

    case actionTypes.REJECT_DRIVER_VERIFY_STATUS_FAIL: {
      return {
        ...state,
        isRejectDriverVerifyStatusSuccess: false
      };
    }
    
    case actionTypes.EDIT_DRIVER_DETAIL: {
      return {
        ...state,
        isUpdateDriverDetailSuccess: false
      };
    }

    case actionTypes.EDIT_DRIVER_DETAIL_SUCCESS: {
      return {
        ...state,
        isUpdateDriverDetailSuccess: true
      };
    }

    case actionTypes.EDIT_DRIVER_DETAIL_FAIL: {
      return {
        ...state,
        isUpdateDriverDetailSuccess: false
      };
    }

    case actionTypes.DRIVER_TRACK_LIST: {
      let driverTrackList = cloneDeep(state.driverTrackList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverTrackList.offset = action.data.offset;
        driverTrackList.limit = action.data.limit;
      }

      driverTrackList.isLoading = true;
      
      if (action.data.offset === 0) 
      driverTrackList.data = [];

      return {
        ...state,
        driverTrackList
      };
    }

    case actionTypes.DRIVER_TRACK_LIST_SUCCESS: {

      let driverTrackList = cloneDeep(state.driverTrackList);
      
      if(action.data.data.length > 0)
      {
          driverTrackList.data = [...driverTrackList.data, ...action.data.data];
          driverTrackList.isLoading = false;
          driverTrackList.hasMore = true;
      }else
        driverTrackList.hasMore = false;

      return {
        ...state,
        driverTrackList
      };
    }

    case actionTypes.DRIVER_TRACK_LIST_FAIL: {
      
      let driverTrackList = cloneDeep(state.driverTrackList);
      driverTrackList.isLoading = false;
      driverTrackList.hasMore = false;

      return {
        ...state,
        driverTrackList
      };
    }

    case actionTypes.DRIVER_TRACK: {
      let driverTrack = cloneDeep(state.driverTrack);
      
      return {
        ...state,
        driverTrack
      };
    }

    case actionTypes.DRIVER_TRACK_SUCCESS: {
      return {
        ...state,
        driverTrack: action.data
      };
    }

    case actionTypes.DRIVER_TRACK_FAIL: {
      return {
        ...state
      };
    }



    case actionTypes.LOAD_DRIVER_SUMMARY: {
      let driverSummary = {};
      
      return {
        ...state,
        driverSummary
      };
    }

    case actionTypes.LOAD_DRIVER_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        driverSummary: action.data
      };
    }

    case actionTypes.LOAD_DRIVER_SUMMARY_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.LOAD_DRIVER_LORRY_DUMPSTER: {
      
      return {
        ...state,
        driverLorryDumpster: {}
      };
    }

    case actionTypes.LOAD_DRIVER_LORRY_DUMPSTER_SUCCESS: {
    
      return {
        ...state,
        driverLorryDumpster:action.data
      };
    }

    case actionTypes.LOAD_DRIVER_LORRY_DUMPSTER_FAIL: {
      return {
        ...state
      };
    }
          
    /* ===== DRIVER TRANSPORT LICENSE ===== */  
    case actionTypes.LOAD_DRIVER_TRANSPORT_LICENSE_LIST: {
      let driverTransportLicenseList = cloneDeep(state.driverTransportLicenseList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverTransportLicenseList.offset = action.data.offset;
        driverTransportLicenseList.limit = action.data.limit;
      }

      return {
        ...state,
        driverTransportLicenseList
      };
    }

    case actionTypes.LOAD_DRIVER_TRANSPORT_LICENSE_LIST_SUCCESS: {
      let driverTransportLicenseList = cloneDeep(state.driverTransportLicenseList);
      driverTransportLicenseList.data = action.data.data;
      driverTransportLicenseList.meta = {
        page: 1 + state.driverTransportLicenseList.offset / state.driverTransportLicenseList.limit,
        pageSize: state.driverTransportLicenseList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.driverTransportLicenseList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        driverTransportLicenseList
      };
    }

    case actionTypes.LOAD_DRIVER_TRANSPORT_LICENSE_LIST_FAIL: {
      return {
        ...state
      };
    }
  
    case actionTypes.DRIVER_TRANSPORT_LICENSE_COUNT: {
      
      return {
        ...state,
        driverTransportLicenseCount: {}
      };
    }

    case actionTypes.DRIVER_TRANSPORT_LICENSE_COUNT_SUCCESS: {
    
      return {
        ...state,
        driverTransportLicenseCount:action.data
      };
    }

    case actionTypes.DRIVER_TRANSPORT_LICENSE_COUNT_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED: {
      
      return {
        ...state,
        driverTransportLicenseVerifySuccess: false
      };
    }

    case actionTypes.DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED_SUCCESS: {
    
      return {
        ...state,
        driverTransportLicenseVerifySuccess: true
      };
    }

    case actionTypes.DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED_FAIL: {
      return {
        ...state,
        driverTransportLicenseVerifySuccess: false
      };
    }


    case actionTypes.REJECT_DRIVER_TRANSPORT_LICENSE: {
      
      return {
        ...state,
        driverTransportLicenseRejectSuccess: false
      };
    }

    case actionTypes.REJECT_DRIVER_TRANSPORT_LICENSE_SUCCESS: {
    
      return {
        ...state,
        driverTransportLicenseRejectSuccess: true
      };
    }

    case actionTypes.REJECT_DRIVER_TRANSPORT_LICENSE_FAIL: {
      return {
        ...state,
        driverTransportLicenseRejectSuccess: false
      };
    }
    
    
    case actionTypes.DRIVER_SCHEDULED_WASTE_SET_RECOVERER: {
      
      return {
        ...state,
        driverScheduledWasteSetRecovererSuccess: false
      };
    }

    case actionTypes.DRIVER_SCHEDULED_WASTE_SET_RECOVERER_SUCCESS: {
    
      return {
        ...state,
        driverScheduledWasteSetRecovererSuccess: true
      };
    }

    case actionTypes.DRIVER_SCHEDULED_WASTE_SET_RECOVERER_FAIL: {
      return {
        ...state,
        driverScheduledWasteSetRecovererSuccess: false
      };
    }
    /* ===== END DRIVER TRANSPORT LICENSE ===== */

    
    default:
      return state;
  }
}
