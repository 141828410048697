import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

export function* loadDriverList() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.verifyStatus) ? action.data.verifyStatus : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`DRIVERLIST${status}${search}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverListFail());
    }
  });
}
  
export function* loadDriverFilter() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_FILTER, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.verifyStatus) ? action.data.verifyStatus : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`DRIVERFILTER${status}${search}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverFilterSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverFilterFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverFilterFail());
    }
  });
}


export function* driverCount() {
  
  yield takeEvery(actionTypes.DRIVER_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.driverCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.driverCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverCountFail());
    }
  });
}

export function* editDriverDetail() {
  yield takeEvery(actionTypes.EDIT_DRIVER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITDRIVERDETAIL");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editDriverDetail(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editDriverDetailSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editDriverDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editDriverDetailFail());
    }
  });
}

export function* removeDriver() {
  yield takeEvery(actionTypes.REMOVE_DRIVER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEDRIVER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeDriver(action.id, action.data);
      if (response.data.success) {
        if(response.data.data)
        {
          yield put(actions.removeDriverSuccess(response.data.data));

        }else{
          yield put(actions.removeDriverSuccess());
          yield put(actions.toastSuccess(response.data.message));
          yield put(push("/organization/driver"));

        }
      } else {
        alert(response.data.message);
        yield put(actions.removeDriverFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeDriverFail());
    }
  });
}

export function* recoveryDriver() {
  yield takeEvery(actionTypes.RECOVERY_DRIVER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECOVERYDRIVER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.recoveryDriver(action.id, action.data);
      if (response.data.success) {
        
        yield put(actions.recoveryDriverSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.recoveryDriverFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recoveryDriverFail());
    }
  });
}

export function* uploadDriverDoc() {
  yield takeEvery(actionTypes.UPLOAD_DRIVER_DOC, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPLOADDRIVERDOC");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.uploadDriverDoc(action.id, action.data);
      if (response.data.success) {
        
        yield put(actions.uploadDriverDocSuccess());
        
      } else {
        alert(response.data.message);
        yield put(actions.uploadDriverDocFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.uploadDriverDocFail());
    }
  });
}
  


export function* loadDriverDocumentationCount() {
  
  yield takeEvery(actionTypes.DRIVER_DOCUMENTATION_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERDOCUMENTATIONCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverDocumentationCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverDocumentationCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverDocumentationCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverDocumentationCountFail());
    }
  });
}


export function* loadDriverDocumentationList() {
  
  yield takeEvery(actionTypes.DRIVER_DOCUMENTATION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let driverID = (action.data.driver_id) ? action.data.driver_id : ""
    let driverLorryID = (action.data.driver_lorry_id) ? action.data.driver_lorry_id : ""
    let search = (action.data.search) ? action.data.search : ""
    let acc_verified = (action.data.acc_verified) ? "acc_verified" : ""
    let pending_verified = (action.data.pending_verified) ? "pending_verified" : ""
    
    let track_no = Helper.generateTrackNo(`DRIVERDOCLIST${driverID}${search}${driverLorryID}${acc_verified}${pending_verified}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverDocumentationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverDocumentationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverDocumentationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverDocumentationListFail());
    }
  });
}


export function* resetDriverDocumentationList() {
  
  yield takeEvery(actionTypes.RESET_DRIVER_DOCUMENTATION_LIST, function*(action) {
    
    yield put(actions.loadDriverDocumentationListSuccess([]));
  });
}


export function* approveDriverDocumentation() {
  
  yield takeEvery(actionTypes.APPROVE_DRIVER_DOCUMENTATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPROVEDRIVERDOCUMENTATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.approveDriverDocumentation(action.data);
      if (response.data.success) {
        yield put(actions.approveDriverDocumentationSuccess());
      } else {
        alert(response.data.message);
        yield put(actions.approveDriverDocumentationFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.approveDriverDocumentationFail());
    }
  });
}



export function* rejectDriverDocumentation() {
  
  yield takeEvery(actionTypes.REJECT_DRIVER_DOCUMENTATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTDRIVERDOCUMENTATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rejectDriverDocumentation(action.data);
      if (response.data.success) {
        yield put(actions.rejectDriverDocumentationSuccess());
      } else {
        alert(response.data.message);
        yield put(actions.rejectDriverDocumentationFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rejectDriverDocumentationFail());
    }
  });
}

export function* applyScheduledWasteService() {
  yield takeEvery(actionTypes.APPLY_SCHEDULED_WASTE_SERVICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPLYSWSERVICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.applyScheduledWasteService(action.id, action.data);
      if (response.data.success) {
        
        yield put(actions.applyScheduledWasteServiceSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.applyScheduledWasteServiceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.applyScheduledWasteServiceFail());
    }
  });
}

export function* editDriverStatus() {
  yield takeEvery(actionTypes.EDIT_DRIVER_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITDRIVERSTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editDriverStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editDriverStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        // if(action.listParams)
        //   yield put(actions.loadDriverList(action.listParams));
        // else
        //   yield put(actions.loadDriverDetail(action.id));
      } else {
        alert(response.data.message);
        yield put(actions.editDriverStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editDriverStatusFail());
    }
  });
}

export function* editDriverLoginShowVeriCodeStatus() {
  yield takeEvery(actionTypes.EDIT_DRIVER_SHOW_VERI_CODE_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITDRIVERSHOWVERICODESTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editDriverLoginShowVeriCodeStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editDriverLoginShowVeriCodeStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        // if(action.listParams)
        //   yield put(actions.loadDriverList(action.listParams));
        // else
        //   yield put(actions.loadDriverDetail(action.id));
      } else {
        alert(response.data.message);
        yield put(actions.editDriverLoginShowVeriCodeStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editDriverLoginShowVeriCodeStatusFail());
    }
  });
}

export function* editDriverSubscriptionStatus() {
  yield takeEvery(actionTypes.EDIT_DRIVER_SUBSCRIPTION_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITDRIVERSUBSCRIPTIONSTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editDriverSubscriptionStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editDriverSubscriptionStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        // if(action.listParams)
        //   yield put(actions.loadDriverList(action.listParams));
        // else
        //   yield put(actions.loadDriverDetail(action.id));
      } else {
        alert(response.data.message);
        yield put(actions.editDriverSubscriptionStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editDriverSubscriptionStatusFail());
    }
  });
}

export function* editDriverVerifyStatus() {
  yield takeEvery(actionTypes.EDIT_DRIVER_VERIFY_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITDRIVERVERIFYSTATUS");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editDriverVerifyStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editDriverVerifyStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        // if(action.listParams)
        //   yield put(actions.loadDriverList(action.listParams));
        // else
        //   yield put(actions.loadDriverDetail(action.id));
      } else {
        alert(response.data.message);
        yield put(actions.editDriverVerifyStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editDriverVerifyStatusFail());
    }
  });
}

export function* rejectDriverVerifyStatus() {
  yield takeEvery(actionTypes.REJECT_DRIVER_VERIFY_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTDRIVERVERIFYSTATUS");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rejectDriverVerifyStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.rejectDriverVerifyStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        // if(action.listParams)
        //   yield put(actions.loadDriverList(action.listParams));
        // else
        //   yield put(actions.loadDriverDetail(action.id));
      } else {
        alert(response.data.message);
        yield put(actions.rejectDriverVerifyStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rejectDriverVerifyStatusFail());
    }
  });
}


export function* loadDriverDetail() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadDriverDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadDriverDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverDetailFail());
    }
  });
}

export function* driverTrackList() {
  
  yield takeEvery(actionTypes.DRIVER_TRACK_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERTRACKLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.driverTrackList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.driverTrackListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverTrackListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverTrackListFail());
    }
  });
}


export function* driverTrack() {
  
  yield takeEvery(actionTypes.DRIVER_TRACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERTRACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.driverTrack(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.driverTrackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverTrackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverTrackFail());
    }
  });
}



export function* loadDriverSummary() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERSUMMARY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverSummary(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverSummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverSummaryFail());
    }
  });
}



export function* loadDriverLorryDumpster() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_LORRY_DUMPSTER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERDUMPLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverLorryDumpster(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverLorryDumpsterSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverLorryDumpsterFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverLorryDumpsterFail());
    }
  });
}



/* ===== DRIVER TRANSPORT LICENSE ===== */

export function* loadDriverTransportLicenseList() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_TRANSPORT_LICENSE_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""

    let track_no = Helper.generateTrackNo(`DRIVERTRANSPORTLICENSELIST${status}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverTransportLicenseList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverTransportLicenseListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverTransportLicenseListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverTransportLicenseListFail());
    }
  });
}
  


export function* driverTransportLicenseCount() {
  
  yield takeEvery(actionTypes.DRIVER_TRANSPORT_LICENSE_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERTRANSPORTLICENSECOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.driverTransportLicenseCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.driverTransportLicenseCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverTransportLicenseCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverTransportLicenseCountFail());
    }
  });
}

export function* transportLicenseUpdateVerify() {
  yield takeEvery(actionTypes.DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("VERIFYDRIVERTRANSPORTLICENSE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.transportLicenseUpdateVerify(action.id, action.data);
      if (response.data.success) {
        yield put(actions.transportLicenseUpdateVerifySuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.transportLicenseUpdateVerifyFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.transportLicenseUpdateVerifyFail());
    }
  });
}

export function* rejectTransportLicense() {
  yield takeEvery(actionTypes.REJECT_DRIVER_TRANSPORT_LICENSE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTDRIVERTRANSPORTLICENSE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rejectTransportLicense(action.id, action.data);
      if (response.data.success) {
        yield put(actions.rejectTransportLicenseSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.rejectTransportLicenseFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rejectTransportLicenseFail());
    }
  });
}

export function* scheduledWasteSetRecoverer() {
  yield takeEvery(actionTypes.DRIVER_SCHEDULED_WASTE_SET_RECOVERER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERSETRECOVERER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.scheduledWasteSetRecoverer(action.id, action.data);
      if (response.data.success) {
        yield put(actions.scheduledWasteSetRecovererSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.scheduledWasteSetRecovererFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.scheduledWasteSetRecovererFail());
    }
  });
}
/* ===== END DRIVER TRANSPORT LICENSE ===== */

export default function* driverSaga() {
  yield all([fork(loadDriverList)]);
  yield all([fork(loadDriverFilter)]);
  yield all([fork(driverCount)]);
  yield all([fork(editDriverDetail)]);
  yield all([fork(removeDriver)]);  
  yield all([fork(recoveryDriver)]);  
  yield all([fork(uploadDriverDoc)]);  
  yield all([fork(loadDriverDocumentationCount)]);  
  yield all([fork(loadDriverDocumentationList)]);  
  yield all([fork(resetDriverDocumentationList)]);  
  yield all([fork(approveDriverDocumentation)]);  
  yield all([fork(rejectDriverDocumentation)]);  
  yield all([fork(applyScheduledWasteService)]);  
  yield all([fork(editDriverStatus)]);
  yield all([fork(editDriverLoginShowVeriCodeStatus)]);
  yield all([fork(editDriverSubscriptionStatus)]);
  yield all([fork(loadDriverDetail)]);
  yield all([fork(driverTrackList)]);
  yield all([fork(driverTrack)]);
  yield all([fork(loadDriverSummary)]);
  yield all([fork(editDriverVerifyStatus)]);
  yield all([fork(rejectDriverVerifyStatus)]);
  yield all([fork(loadDriverLorryDumpster)]);
  yield all([fork(loadDriverTransportLicenseList)]);
  yield all([fork(driverTransportLicenseCount)]);
  yield all([fork(transportLicenseUpdateVerify)]);
  yield all([fork(rejectTransportLicense)]);
  yield all([fork(scheduledWasteSetRecoverer)]);
  
}
