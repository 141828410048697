import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

export function* loadOrderList() {
  
  yield takeEvery(actionTypes.LOAD_ORDER_LIST, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.order_status) ? action.data.order_status : ""
    let search = (action.data.search) ? action.data.search : ""
    
    let pendingAssign = (action.data.pendingAssign) ? "pendingAssign" : ""

    let track_no = Helper.generateTrackNo(`ORDERLIST${status}${pendingAssign}${search}`);

    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadOrderListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadOrderListFail());
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadOrderListFail());
    }
  });
}


export function* loadOrderDetail() {
  
  yield takeEvery(actionTypes.LOAD_ORDER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadOrderDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadOrderDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadOrderDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadOrderDetailFail());
    }
  });
}


export function* loadOrderDetailTripGroup() {
  
  yield takeEvery(actionTypes.LOAD_ORDER_DETAIL_TRIP_GROUP, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERDETAILTRIPGROUP");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadOrderDetailTripGroup(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadOrderDetailTripGroupSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadOrderDetailTripGroupFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadOrderDetailTripGroupFail());
    }
  });
}

export function* loadOrderDetailTripList() {
  
  yield takeEvery(actionTypes.LOAD_ORDER_DETAIL_TRIP_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERDETAILTRIPLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderDetailTripList(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadOrderDetailTripListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadOrderDetailTripListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadOrderDetailTripListFail());
    }
  });
}


export function* orderCount() {
  
  yield takeEvery(actionTypes.ORDER_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.orderCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.orderCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.orderCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderCountFail());
    }
  });
}


export function* createOrder() {
  yield takeEvery(actionTypes.CREATE_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CREATEORDER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.createOrder(action.data);
      if (response.data.success) {
        yield put(actions.createOrderSuccess());
        yield put(actions.toastSuccess(response.data.message));
        yield put(push("/order"));
      } else {
        alert(response.data.message);
        yield put(actions.createOrderFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.createOrderFail());
    }
  });
}

export function* cancelOrder() {
  yield takeEvery(actionTypes.CANCEL_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CANCELORDER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.cancelOrder(action.id, action.data);
      if (response.data.success) {
        
        if(response.data.data)
        {
          yield put(actions.cancelOrderSuccess(response.data.data, action.data));
        }else{
          yield put(actions.cancelOrderSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }

      } else {
        alert(response.data.message);
        yield put(actions.cancelOrderFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.cancelOrderFail());
    }
  });
}

export function* getOrderCancellationDetail() {
  yield takeEvery(actionTypes.GET_ORDER_CANCELLATION_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERCANCELLATIONDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getOrderCancellationDetail(action.id, action.data);
      if (response.data.success && response.data.data) {        
          yield put(actions.getOrderCancellationDetailSuccess(response.data.data));   
      } else {
        alert(response.data.message);
        yield put(actions.getOrderCancellationDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getOrderCancellationDetailFail());
    }
  });
}


export function* orderAssignDriver() {
  yield takeEvery(actionTypes.ORDER_ASSIGN_DRIVER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ASSIGNDRIVER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.orderAssignDriver(action.id, action.data);
      if (response.data.success) {

        if(response.data.data)
        {
          yield put(actions.orderAssignDriverSuccess(response.data.data, action.data));
          
        }else{
          yield put(actions.orderAssignDriverSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }

      } else {
        alert(response.data.message);
        yield put(actions.orderAssignDriverFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderAssignDriverFail());
    }
  });
}

export function* loadOrderTripList() {
  
  yield takeEvery(actionTypes.LOAD_ORDER_TRIP_LIST, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.trip_status) ? action.data.trip_status : ""
    let trip_type = (action.data.trip_type) ? action.data.trip_type : ""
    let exportAs = (action.data.exportAs) ? action.data.exportAs : ""
    let search = (action.data.search) ? action.data.search : ""
    
    let track_no = Helper.generateTrackNo(`ORDERTRIPLIST${status}${trip_type}${exportAs}${search}`);

    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderTripList(action.data);

      if (response.data.success && response.data.data) {
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {

            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.filename;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });

        }else
          yield put(actions.loadOrderTripListSuccess(response.data));

      } else {
        alert(response.data.message);
        yield put(actions.loadOrderTripListFail());
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadOrderTripListFail());
    }
  });
}

export function* orderTripCount() {
  
  yield takeEvery(actionTypes.ORDER_TRIP_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERTRIPCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.orderTripCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.orderTripCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.orderTripCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderTripCountFail());
    }
  });
}

export function* loadDriverTripList() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_TRIP_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERTRIPLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverTripList(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverTripListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverTripListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverTripListFail());
    }
  });
}

export function* updateTripPlacement() {
  yield takeEvery(actionTypes.UPDATE_TRIP_PLACEMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDPLACEMENT");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateTripPlacement(action.id, action.data);
      if (response.data.success) {
        yield put(actions.updateTripPlacementSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.updateTripPlacementFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateTripPlacementFail());
    }
  });
}

export function* updateTripFilling() {
  yield takeEvery(actionTypes.UPDATE_TRIP_FILLING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDFILLING");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateTripFilling(action.id, action.data);
      if (response.data.success) {
        yield put(actions.updateTripFillingSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.updateTripFillingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateTripFillingFail());
    }
  });
}

export function* rollbackTripFilling() {
  yield takeEvery(actionTypes.ROLLBACK_TRIP_FILLING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ROLLBACKFILLING");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rollbackTripFilling(action.id, action.data);
      if (response.data.success) {
        yield put(actions.rollbackTripFillingSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.rollbackTripFillingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rollbackTripFillingFail());
    }
  });
}

export function* updateTripCollection() {
  yield takeEvery(actionTypes.UPDATE_TRIP_COLLECTION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDCOLLECTION");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateTripCollection(action.id, action.data);
      if (response.data.success) {
        
        if(response.data.data)
        {
          yield put(actions.updateTripCollectionSuccess(action.id, action.data, response.data.data));
        }else{
          yield put(actions.updateTripCollectionSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }

      } else {
        alert(response.data.message);
        yield put(actions.updateTripCollectionFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateTripCollectionFail());
    }
  });
}

export function* updateTripDisposal() {
  yield takeEvery(actionTypes.UPDATE_TRIP_DISPOSAL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDDISPOSAL");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateTripDisposal(action.id, action.data);
      if (response.data.success) {
        yield put(actions.updateTripDisposalSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.updateTripDisposalFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateTripDisposalFail());
    }
  });
}

export function* reuploadDisposalSlip() {
  yield takeEvery(actionTypes.REUPLOAD_DISPOSAL_SLIP, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REUPLOADDISPOSALSLIP");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.reuploadDisposalSlip(action.id, action.data);
      if (response.data.success) {
        yield put(actions.reuploadDisposalSlipSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.reuploadDisposalSlipFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.reuploadDisposalSlipFail());
    }
  });
}

export function* reuploadTripProofImg() {
  yield takeEvery(actionTypes.REUPLOAD_TRIP_PROOF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REUPLOADTRIPPROOF");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.reuploadTripProofImg(action.id, action.data);
      if (response.data.success) {
        yield put(actions.reuploadTripProofImgSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.reuploadTripProofImgFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.reuploadTripProofImgFail());
    }
  });
}

export function* reuploadTripWeighingSlip() {
  yield takeEvery(actionTypes.REUPLOAD_TRIP_WEIGHING_SLIP, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REUPLOADTRIWEIGHINGSLIP");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.reuploadTripWeighingSlip(action.id, action.data);
      if (response.data.success) {
        yield put(actions.reuploadTripWeighingSlipSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.reuploadTripWeighingSlipFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.reuploadTripWeighingSlipFail());
    }
  });
}

export function* skipSignature() {
  yield takeEvery(actionTypes.SKIP_SIGNATURE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SKIPSIGNATURE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.skipSignature(action.id, action.data);
      if (response.data.success) {
        yield put(actions.skipSignatureSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.skipSignatureFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.skipSignatureFail());
    }
  });
}


export function* updateDumpsterEmpty() {
  yield takeEvery(actionTypes.UPDATE_DUMPSTER_EMPTY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDDUMPSTEREMPTY");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateDumpsterEmpty(action.id, action.data);
      if (response.data.success) {
        yield put(actions.updateDumpsterEmptySuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.updateDumpsterEmptyFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateDumpsterEmptyFail());
    }
  });
}



export function* updateTripLoadingCollection() {
  yield takeEvery(actionTypes.UPDATE_TRIP_LOADING_COLLECTION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDLOADINGCOLLECTION");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateTripLoadingCollection(action.id, action.data);
      if (response.data.success) {
        yield put(actions.updateTripLoadingCollectionSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.updateTripLoadingCollectionFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateTripLoadingCollectionFail());
    }
  });
}


export function* trackDriver() {
  
  yield takeEvery(actionTypes.TRACK_DRIVER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("TRCKDRIVER");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.trackDriver(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.trackDriverSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.trackDriverFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.trackDriverFail());
    }
  });
}

export function* tripSetArrived() {
  
  yield takeEvery(actionTypes.TRIP_SET_ARRIVED, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETARRIVED");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.tripSetArrived(action.id, action.data);
  
      if (response.data.success) {
        
        yield put(actions.tripSetArrivedSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.tripSetArrivedFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.tripSetArrivedFail());
    }
  });
}

export function* tripUpdateGoingNow() {
  
  yield takeEvery(actionTypes.TRIP_UPDATE_GOING_NOW, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDGOINGNOW");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.tripUpdateGoingNow(action.id,action.data);
  
      if (response.data.success) {
        
        yield put(actions.tripUpdateGoingNowSuccess());

        if(!response.data.data)
          yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.tripUpdateGoingNowFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.tripUpdateGoingNowFail());
    }
  });
}

export function* tripAllowGoingNowAdvance() {
  
  yield takeEvery(actionTypes.TRIP_UPDATE_GOING_NOW_ADVANCE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDGOINGNOWADVANCE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.tripAllowGoingNowAdvance(action.id, action.data);
  
      if (response.data.success) {
        
        yield put(actions.tripAllowGoingNowAdvanceSuccess());

        if(!response.data.data)
          yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.tripAllowGoingNowAdvanceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.tripAllowGoingNowAdvanceFail());
    }
  });
}

export function* tripRollbackGoingNow() {
  
  yield takeEvery(actionTypes.TRIP_ROLLBACK_GOING_NOW, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ROLLBACKGOINGNOW");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.tripRollbackGoingNow(action.id, action.data);
  
      if (response.data.success) {
        
        yield put(actions.tripRollbackGoingNowSuccess());

        if(!response.data.data)
          yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.tripRollbackGoingNowFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.tripRollbackGoingNowFail());
    }
  });
}

export function* tripSetStarted() {
  
  yield takeEvery(actionTypes.TRIP_SET_STARTED, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETSTARTED");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.tripSetStarted(action.orderID, action.tripID, action.data);
  
      if (response.data.success) {
        
        yield put(actions.tripSetStartedSuccess());
          yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.tripSetStartedFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.tripSetStartedFail());
    }
  });
}

export function* editTripWeighingFigure() {
  
  yield takeEvery(actionTypes.EDIT_TRIP_WEIGHING_FIGURE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITWEIGHINGFIGURE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.editTripWeighingFigure(action.id, action.data);
  
      if (response.data.success) {

        if(response.data.data)
        {
          yield put(actions.editTripWeighingFigureSuccess(response.data.data));

        }else{
          yield put(actions.editTripWeighingFigureSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }

      } else {
        alert(response.data.message);
        yield put(actions.editTripWeighingFigureFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editTripWeighingFigureFail());
    }
  });
}


export function* resetCollectionTrip() {
  
  yield takeEvery(actionTypes.RESET_COLLECTION_TRIP, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RESETCOLLECTIONTRIP");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.resetCollectionTrip(action.id, action.data);
  
      if (response.data.success) {

        if(response.data.data)
        {
          yield put(actions.resetCollectionTripSuccess(response.data.data));
        }else{
            yield put(actions.resetCollectionTripSuccess());
            yield put(actions.toastSuccess(response.data.message));
        }
      } else {
        alert(response.data.message);
        yield put(actions.resetCollectionTripFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.resetCollectionTripFail());
    }
  });
}



/* ===== ORDER INVOICE  ===== */
export function* billingList() {
  
  yield takeEvery(actionTypes.BILLING_LIST, function*(action) {

    if(!action.data) action.data = {}

    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`BILLINGLIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.billingList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.billingListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.billingListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.billingListFail());
    }
  });
}


export function* orderInvoice() {
  
  yield takeEvery(actionTypes.ORDER_INVOICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERINVOICE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.orderInvoice(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.orderInvoiceSuccess(response.data.data, (response.data.amount_updated) ? true : false));
      } else {
        alert(response.data.message);
        yield put(actions.orderInvoiceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderInvoiceFail());
    }
  });
}

export function* orderInvoiceStack() {
  
  yield takeEvery(actionTypes.ORDER_INVOICE_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERINVOICESTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.orderInvoiceStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.orderInvoiceStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.orderInvoiceStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderInvoiceStackFail());
    }
  });
}


export function* agreementDebitCreditNoteLoad() {
  
  yield takeEvery(actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DEBITCREDITNOTELIST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.agreementDebitCreditNoteLoad(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.agreementDebitCreditNoteLoadSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.agreementDebitCreditNoteLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.agreementDebitCreditNoteLoadFail());
    }
  });
}

export function* agreementDebitCreditNoteStack() {
  
  yield takeEvery(actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DEBITCREDITNOTESTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.agreementDebitCreditNoteStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.agreementDebitCreditNoteStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.agreementDebitCreditNoteStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.agreementDebitCreditNoteStackFail());
    }
  });
}
/* ===== END ORDER INVOICE ===== */


/* ===== SPECIAL INVOICE  ===== */
export function* specialInvoiceList() {
  
  yield takeEvery(actionTypes.SPECIAL_INVOICE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`SPECIALINVOICELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.specialInvoiceList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.specialInvoiceListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.specialInvoiceListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.specialInvoiceListFail());
    }
  });
}


export function* getSpecialInvoice() {
  
  yield takeEvery(actionTypes.GET_SPECIAL_INVOICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETSPECIALINVOICE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getSpecialInvoice(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getSpecialInvoiceSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getSpecialInvoiceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getSpecialInvoiceFail());
    }
  });
}

export function* getSpecialInvoiceStack() {
  
  yield takeEvery(actionTypes.GET_SPECIAL_INVOICE_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETSPECIALINVOICESTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getSpecialInvoiceStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getSpecialInvoiceStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getSpecialInvoiceStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getSpecialInvoiceStackFail());
    }
  });
}
/* ===== END SPECIAL INVOICE  ===== */



/* ===== PURCHASE BILL  ===== */
export function* purchaseBillList() {
  
  yield takeEvery(actionTypes.PURCHASE_BILL_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`PURCHASEBILLLIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseBillList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.purchaseBillListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.purchaseBillListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseBillListFail());
    }
  });
}

export function* purchaseBillDumpRecycling() {
  
  yield takeEvery(actionTypes.PURCHASE_BILL_DUMP_RECYCLING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLDR");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseBillDumpRecycling(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.purchaseBillDumpRecyclingSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.purchaseBillDumpRecyclingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseBillDumpRecyclingFail());
    }
  });
}

export function* purchaseBillDumpRecyclingStack() {
  
  yield takeEvery(actionTypes.PURCHASE_BILL_DUMP_RECYCLING_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLDRSTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseBillDumpRecyclingStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.purchaseBillDumpRecyclingStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.purchaseBillDumpRecyclingStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseBillDumpRecyclingStackFail());
    }
  });
}

export function* purchaseBillDumpExpress() {
  
  yield takeEvery(actionTypes.PURCHASE_BILL_DUMP_EXPRESS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLDE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseBillDumpExpress(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.purchaseBillDumpExpressSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.purchaseBillDumpExpressFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseBillDumpExpressFail());
    }
  });
}

export function* purchaseBillDumpExpressStack() {
  
  yield takeEvery(actionTypes.PURCHASE_BILL_DUMP_EXPRESS_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLDESTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseBillDumpExpressStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.purchaseBillDumpExpressStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.purchaseBillDumpExpressStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseBillDumpExpressStackFail());
    }
  });
}

export function* purchaseBillStatement() {
  
  yield takeEvery(actionTypes.PURCHASE_BILL_STATEMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLSTATEMENT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseBillStatement(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(action.data.exportFile)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {

            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });

        }else
          yield put(actions.purchaseBillStatementSuccess(response.data.data));

      } else {
        alert(response.data.message);
        yield put(actions.purchaseBillStatementFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseBillStatementFail());
    }
  });
}

/* ===== END PURCHASE BILL ===== */

/* ===== PURCHASE DEBIT CREDIT NOTE  ===== */
export function* purchaseDebitCreditNoteList() {
  
  yield takeEvery(actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`PURCHASENOTELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseDebitCreditNoteList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.purchaseDebitCreditNoteListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.purchaseDebitCreditNoteListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseDebitCreditNoteListFail());
    }
  });
}

export function* purchaseDebitCreditNote() {
  
  yield takeEvery(actionTypes.PURCHASE_DEBIT_CREDIT_NOTE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASENOTE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseDebitCreditNote(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.purchaseDebitCreditNoteSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.purchaseDebitCreditNoteFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseDebitCreditNoteFail());
    }
  });
}

export function* purchaseDebitCreditNoteStack() {
  
  yield takeEvery(actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASENOTESTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.purchaseDebitCreditNoteStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.purchaseDebitCreditNoteStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.purchaseDebitCreditNoteStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.purchaseDebitCreditNoteStackFail());
    }
  });
}
/* ===== END PURCHASE DEBIT CREDIT NOTE ===== */

export function* storeReceiptList() {
  
  yield takeEvery(actionTypes.STORE_RECEIPT_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`STORERECEIPTLIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.storeReceiptList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.storeReceiptListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.storeReceiptListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.storeReceiptListFail());
    }
  });
}

export function* getStoreReceiptDumpExpressStack() {
  
  yield takeEvery(actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("STORERECEIPTDESTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getStoreReceiptDumpExpressStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getStoreReceiptDumpExpressStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getStoreReceiptDumpExpressStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getStoreReceiptDumpExpressStackFail());
    }
  });
}

export function* getStoreReceiptDumpRecyclingStack() {
  
  yield takeEvery(actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("STORERECEIPTDRSTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getStoreReceiptDumpRecyclingStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getStoreReceiptDumpRecyclingStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getStoreReceiptDumpRecyclingStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getStoreReceiptDumpRecyclingStackFail());
    }
  });
}


export function* wasteReceiptList() {
  
  yield takeEvery(actionTypes.WASTE_RECEIPT_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`WASTERECEIPTLIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.wasteReceiptList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.wasteReceiptListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.wasteReceiptListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.wasteReceiptListFail());
    }
  });
}

export function* getWasteReceiptStack() {
  
  yield takeEvery(actionTypes.GET_WASTE_RECEIPT_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WASTERECEIPTSTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getWasteReceiptStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getWasteReceiptStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getWasteReceiptStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getWasteReceiptStackFail());
    }
  });
}


/* ===== RECYCLABLE SALES INVOICE  ===== */
export function* recyclableSalesInvoiceList() {
  
  yield takeEvery(actionTypes.RECYCLABLE_SALES_INVOICE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`RECYCLABLESALESINVOICELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.recyclableSalesInvoiceList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.recyclableSalesInvoiceListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.recyclableSalesInvoiceListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recyclableSalesInvoiceListFail());
    }
  });
}

export function* recyclableSalesInvoice() {
  
  yield takeEvery(actionTypes.RECYCLABLE_SALES_INVOICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLABLESALESINVOICE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.recyclableSalesInvoice(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.recyclableSalesInvoiceSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.recyclableSalesInvoiceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recyclableSalesInvoiceFail());
    }
  });
}

export function* recyclableSalesInvoiceStack() {
  
  yield takeEvery(actionTypes.RECYCLABLE_SALES_INVOICE_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLABLESALESINVOICESTACK");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.recyclableSalesInvoiceStack(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.recyclableSalesInvoiceStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.recyclableSalesInvoiceStackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recyclableSalesInvoiceStackFail());
    }
  });
}
/* ===== END RECYCLABLE SALES INVOICE ===== */

/* ===== BOOKING FEE  ===== */
export function* bookingFeeList() {
  
  yield takeEvery(actionTypes.BOOKING_FEE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`BOOKINGFEELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.bookingFeeList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.bookingFeeListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.bookingFeeListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.bookingFeeListFail());
    }
  });
}
/* ===== END BOOKING FEE ===== */


/* ===== PROCESS FEE  ===== */
export function* processFeeList() {
  
  yield takeEvery(actionTypes.PROCESS_FEE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`PROCESSFEELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.processFeeList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.processFeeListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.processFeeListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.processFeeListFail());
    }
  });
}
/* ===== END PROCESS FEE ===== */


/* ===== LANDFILL FEE  ===== */
export function* landfillFeeList() {
  
  yield takeEvery(actionTypes.LANDFILL_FEE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`LANDFILLFEELIST${search}`);;
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.landfillFeeList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.landfillFeeListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.landfillFeeListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.landfillFeeListFail());
    }
  });
}
/* ===== END LANDFILL FEE ===== */


/* ===== USER LOADING FEE LIST  ===== */
export function* userLoadingFeeList() {
  
  yield takeEvery(actionTypes.USER_LOADING_FEE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`USERLOADINGFEELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.userLoadingFeeList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.userLoadingFeeListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.userLoadingFeeListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.userLoadingFeeListFail());
    }
  });
}
/* ===== END USER LOADING FEE LIST ===== */


/* ===== DRIVER LOADING PAYMENT LIST  ===== */
export function* driverLoadingPaymentList() {
  
  yield takeEvery(actionTypes.DRIVER_LOADING_PAYMENT_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`DRIVERLOADINGPAYMENTLIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.driverLoadingPaymentList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.driverLoadingPaymentListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverLoadingPaymentListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverLoadingPaymentListFail());
    }
  });
}
/* ===== END DRIVER LOADING PAYMENT LIST ===== */


/* ===== SORTING FEE  ===== */
export function* sortingFeeList() {
  
  yield takeEvery(actionTypes.SORTING_FEE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`SORTINGFEELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.sortingFeeList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.sortingFeeListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.sortingFeeListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.sortingFeeListFail());
    }
  });
}
/* ===== END SORTING FEE ===== */


/* ===== OVERSIZE FEE  ===== */
export function* oversizeFeeList() {
  
  yield takeEvery(actionTypes.OVERSIZE_FEE_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`OVERSIZEFEELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.oversizeFeeList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.oversizeFeeListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.oversizeFeeListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.oversizeFeeListFail());
    }
  });
}
/* ===== END OVERSIZE FEE ===== */

export function* recyclablePurchaseList() {
  
  yield takeEvery(actionTypes.RECYCLABLE_PURCHASE_SUMMARY_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`RECYCLABLEPURCHASELIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.recyclablePurchaseList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.recyclablePurchaseListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.recyclablePurchaseListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recyclablePurchaseListFail());
    }
  });
}



export function* orderTrackReport() {
  
  yield takeEvery(actionTypes.ORDER_TRACK_REPORT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERTRACKREPORT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.orderTrackReport(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.orderTrackReportSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.orderTrackReportFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderTrackReportFail());
    }
  });
}

export function* orderTripStoreReceipt() {
  
  yield takeEvery(actionTypes.ORDER_TRIP_STORE_RECEIPT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("TRIPSTORERECEIPT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.orderTripStoreReceipt(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.orderTripStoreReceiptSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.orderTripStoreReceiptFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderTripStoreReceiptFail());
    }
  });
}

export function* orderTripWasteReceipt() {
  
  yield takeEvery(actionTypes.ORDER_TRIP_WASTE_RECEIPT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("TRIPWASTERECEIPT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.orderTripWasteReceipt(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.orderTripWasteReceiptSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.orderTripWasteReceiptFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderTripWasteReceiptFail());
    }
  });
}


export function* orderWasteSummary() {
  
  yield takeEvery(actionTypes.ORDER_WASTE_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERWASTESUMMARY");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.orderWasteSummary(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.orderWasteSummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.orderWasteSummaryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderWasteSummaryFail());
    }
  });
}



export function* loadTransactionList() {
  
  yield takeEvery(actionTypes.LOAD_TRANSACTION_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`TRANSACTIONLIST${search}`);
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadTransactionList(action.data);
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.loadTransactionListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadTransactionListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadTransactionListFail());
    }
  });
}



export function* loadDuitNowTransactionList() {
  
  yield takeEvery(actionTypes.LOAD_DUITNOW_TRANSACTION_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`DUITNOWTRANSACTIONLIST${search}`);
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDuitNowTransactionList(action.data);
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.loadDuitNowTransactionListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDuitNowTransactionListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDuitNowTransactionListFail());
    }
  });
}


export function* loadOrderSummary() {
  
  yield takeEvery(actionTypes.LOAD_ORDER_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERSUMMARY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderSummary(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadOrderSummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadOrderSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadOrderSummaryFail());
    }
  });
}


export function* loadOrderSummaryChart() {
  
  yield takeEvery(actionTypes.LOAD_ORDER_SUMMARY_CHART, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERSUMMARYCHART");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderSummaryChart(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadOrderSummaryChartSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadOrderSummaryChartFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadOrderSummaryChartFail());
    }
  });
}


export function* loadOrderSaleSummary() {
  
  yield takeEvery(actionTypes.LOAD_ORDER_SALE_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERSALESUMMARY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderSaleSummary(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadOrderSaleSummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadOrderSaleSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadOrderSaleSummaryFail());
    }
  });
}

export function* loadDriverIncentive() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_INCENTIVE, function*(action) {

    if(!action.data) action.data = {}

    let group = (action.data.group) ? action.data.group : ""
    
    let track_no = Helper.generateTrackNo(`DRIVERINCENTIVE${group}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverIncentive(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverIncentiveSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverIncentiveFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverIncentiveFail());
    }
  });
}

export function* loadUserOrderHistory() {
  
  yield takeEvery(actionTypes.LOAD_USER_ORDER_HISTORY, function*(action) {

    if(!action.data) action.data = {}
    
    let group = (action.data.group) ? action.data.group : ""
    
    let track_no = Helper.generateTrackNo(`ORDERHISTORY${group}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadUserOrderHistory(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadUserOrderHistorySuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadUserOrderHistoryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadUserOrderHistoryFail());
    }
  });
}

/* =====REPORT ===== */

export function* monthlyWasteSummary() {
  
  yield takeEvery(actionTypes.MONTHLY_WASTE_SUMMARY, function*(action) {

    if(!action.params) action.params = {}

    let exportAs = (action.params.exportAs) ? action.params.exportAs : ""

    let track_no = Helper.generateTrackNo(`MONTHLYWASTESUMMARY${exportAs}`);

    action.params.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.monthlyWasteSummary(action.user, action.branch, action.params);
  
      if (response.data.success && response.data.data) {
        
        Helper.loadSecureFile(response.data.data, file => {
          let filename = `${(action.params && action.params.service_type === "recycling") ? "Recyclable": "Waste"} Collection Summary Report ${action.params.period}`;

          if(action.params.client)
            filename = `${(action.params && action.params.service_type === "recycling") ? "Recyclable": "Waste"} Collection Summary Report (${action.params.period}) - ${action.params.client}`;

          let filetype = "pdf"

          if(action.params && action.params.exportAs && action.params.exportAs === "excel")
            filetype = "xlsx"

          var downloadLink = document.createElement("a");
          downloadLink.href = file;
          downloadLink.download = `${filename}.${filetype}`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      } else {
        alert(response.data.message);
        yield put(actions.monthlyWasteSummaryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyWasteSummaryFail());
    }
  });
}


export function* monthlyWasteSummaryLoad() {
  
  yield takeEvery(actionTypes.MONTHLY_WASTE_SUMMARY_LOAD, function*(action) {

    if(!action.params) action.params = {}

    let track_no = Helper.generateTrackNo("MONTHLYWASTESUMMARYLIST");

    action.params.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.monthlyWasteSummaryLoad(action.user, action.branch, action.params);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.monthlyWasteSummaryLoadSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.monthlyWasteSummaryLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyWasteSummaryLoadFail());
    }
  });
}



export function* monthlyLandfillSummary() {
  
  yield takeEvery(actionTypes.MONTHLY_LANDFILL_SUMMARY, function*(action) {

    if(!action.params) action.params = {}

    let exportAs = (action.params.exportAs) ? action.params.exportAs : ""

    let track_no = Helper.generateTrackNo(`MONTHLYLANDFILLSUMMARY${exportAs}`);

    action.params.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.monthlyLandfillSummary(action.id, action.params);
  
      if (response.data.success && response.data.data) {
        
        Helper.loadSecureFile(response.data.data, file => {
          let filename = "Landfill Summary Report (" + action.params.period + ")";

          if(action.params.client)
            filename = `Landfill Summary Report (${action.params.period}) - ${action.params.client}`;

          let filetype = "pdf"

          if(action.params && action.params.exportAs && action.params.exportAs === "excel")
            filetype = "xlsx"

          var downloadLink = document.createElement("a");
          downloadLink.href = file;
          downloadLink.download = `${filename}.${filetype}`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      } else {
        alert(response.data.message);
        yield put(actions.monthlyLandfillSummaryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyLandfillSummaryFail());
    }
  });
}


export function* monthlyLandfillSummaryLoad() {
  
  yield takeEvery(actionTypes.MONTHLY_LANDFILL_SUMMARY_LOAD, function*(action) {

    if(!action.params) action.params = {}

    let track_no = Helper.generateTrackNo("MONTHLYLANDFILLSUMMARYLIST");
    action.params.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.monthlyLandfillSummaryLoad(action.id, action.params);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.monthlyLandfillSummaryLoadSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.monthlyLandfillSummaryLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyLandfillSummaryLoadFail());
    }
  });
}



export function* monthlyStockSummary() {
  
  yield takeEvery(actionTypes.MONTHLY_STOCK_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MONTHLYSTOCKSUMMARY");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.monthlyStockSummary(action.data);
  
      if (response.data.success && response.data.data) {
        
        Helper.loadSecureFile(response.data.data, file => {

          let filename = `(${action.data.service_type}) Monthly Stock Summary Report - ${action.data.filter_date}`;
          let filetype = "xlsx"

          var downloadLink = document.createElement("a");
          downloadLink.href = file;
          downloadLink.download = `${filename}.${filetype}`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      } else {
        alert(response.data.message);
        yield put(actions.monthlyStockSummaryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyStockSummaryFail());
    }
  });
}


export function* monthlyStockSummaryLoad() {
  
  yield takeEvery(actionTypes.MONTHLY_STOCK_SUMMARY_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MONTHLYSTOCKSUMMARYLIST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.monthlyStockSummaryLoad(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.monthlyStockSummaryLoadSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.monthlyStockSummaryLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyStockSummaryLoadFail());
    }
  });
}



export function* monthlyWasteStockSummary() {
  
  yield takeEvery(actionTypes.MONTHLY_WASTE_STOCK_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MONTHLYWASTESTOCKSUMMARY");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.monthlyWasteStockSummary(action.data);
  
      if (response.data.success && response.data.data) {
        
        Helper.loadSecureFile(response.data.data, file => {

          let filename = `(${action.data.service_type}) Monthly Stock Summary Report - ${action.data.filter_date}`;
          let filetype = "xlsx"

          var downloadLink = document.createElement("a");
          downloadLink.href = file;
          downloadLink.download = `${filename}.${filetype}`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      } else {
        alert(response.data.message);
        yield put(actions.monthlyWasteStockSummaryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyWasteStockSummaryFail());
    }
  });
}


export function* monthlyWasteStockSummaryLoad() {
  
  yield takeEvery(actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MONTHLYWASTE_STOCKSUMMARYLIST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.monthlyWasteStockSummaryLoad(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.monthlyWasteStockSummaryLoadSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.monthlyWasteStockSummaryLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyWasteStockSummaryLoadFail());
    }
  });
}


export function* monthlyDriverTripSummary() {
  
  yield takeEvery(actionTypes.MONTHLY_DRIVER_TRIP_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let driver_id = (action.data.driver_id) ? action.data.driver_id : ""
    let status = (action.data.trip_status) ? action.data.trip_status : ""
    let period = (action.data.period) ? action.data.period : ""
    let exportAs = (action.data.exportAs) ? action.data.exportAs : ""
    
    let track_no = Helper.generateTrackNo(`DRIVERTRIPSUMMARY${driver_id}${status}${period}${exportAs}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.monthlyDriverTripSummary(action.data);

      if (response.data.success && response.data.data) {

        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {

            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.monthlyDriverTripSummarySuccess(response.data));

      } else {
        alert(response.data.message);
        yield put(actions.monthlyDriverTripSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.monthlyDriverTripSummaryFail());
    }
  });
}


export function* resetDriverTripSummary() {
  
  yield takeEvery(actionTypes.RESET_DRIVER_TRIP_SUMMARY, function*(action) {

    yield put(actions.enableLoading());

    yield put(actions.resetDriverTripSummarySuccess());      
    yield put(actions.disableLoading());

  });
}


export function* orderDistanceSummary() {
  
  yield takeEvery(actionTypes.ORDER_DISTANCE_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let user_id = (action.data.user_id) ? action.data.user_id : ""
    let branch_id = (action.data.branch_id) ? action.data.branch_id : ""
    let period = (action.data.period) ? action.data.period : ""
    let exportAs = (action.data.exportAs) ? action.data.exportAs : ""
    
    let track_no = Helper.generateTrackNo(`${user_id}${branch_id}ORDERDISTANCESUMMARY${period}${exportAs}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.orderDistanceSummary(action.data);

      if (response.data.success && response.data.data) {

        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {

            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.orderDistanceSummarySuccess(response.data));

      } else {
        alert(response.data.message);
        yield put(actions.orderDistanceSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.orderDistanceSummaryFail());
    }
  });
}

export function* referralCommissionSummary() {

  yield takeEvery(actionTypes.LOAD_REFERRAL_COMMISSION_SUMMARY, function*(action) {

    if(!action.data) action.data = {}
    
    let exportAs = (action.data.exportAs) ? action.data.exportAs : ""
    let period = (action.data.period) ? action.data.period : ""
    let user = (action.data.user_id) ? action.data.user : ""

    let track_no = Helper.generateTrackNo(`REFERRALCOMMISSIONSUMMARY${period}${user}${exportAs}`);
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.referralCommissionSummary(action.data);
     
      if (response.data.success) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {

            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }
  
        yield put(actions.referralCommissionSummarySuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.referralCommissionSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.referralCommissionSummaryFail());
    }
  });
}

/* ===== END REPORT ===== */


export function* loadAdjAgreementList() {
  
  yield takeEvery(actionTypes.LOAD_ADJUSTMENT_AGREEMENT_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADJAGREEMENTLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadAdjAgreementList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadAdjAgreementListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadAdjAgreementListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadAdjAgreementListFail());
    }
  });
}

export function* loadAdjAgreementDetail() {
  
  yield takeEvery(actionTypes.LOAD_ADJUSTMENT_AGREEMENT_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADJAGREEMENTDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadAdjAgreementDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadAdjAgreementDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadAdjAgreementDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadAdjAgreementDetailFail());
    }
  });
}


export function* agreementProceeRefund() {
  
  yield takeEvery(actionTypes.AGREEMENT_PROCESS_REFUND, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("AGREEMENTPROCESSREFUND");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.agreementProceeRefund(action.data);
  
      if (response.data.success) {
        
        yield put(actions.agreementProceeRefundSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.agreementProceeRefundFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.agreementProceeRefundFail());
    }
  });
}


export function* loadAdjAffectedOrderList() {
  
  yield takeEvery(actionTypes.LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let is_refund = (action.data.is_refund) ? "refund" : ""
    let is_countervail = (action.data.is_countervail) ? "countervail" : ""
    let is_payment = (action.data.is_payment) ? "payment" : ""
    let is_subjected_to_change = (action.data.is_subjected_to_change) ? "stoc" : ""
    let exportFile = (action.data.exportFile) ? "exportFile" : ""
    
    let track_no = Helper.generateTrackNo(`ADJAGREEMENTORDERLIST${is_refund}${is_countervail}${is_payment}${is_subjected_to_change}${exportFile}`);

    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadAdjAffectedOrderList(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.loadAdjAffectedOrderListSuccess(response.data.data));
        
      } else {
        alert(response.data.message);
        yield put(actions.loadAdjAffectedOrderListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadAdjAffectedOrderListFail());
    }
  });
}


export function* cancelOrderRefundList() {
  
  yield takeEvery(actionTypes.CANCEL_ORDER_REFUND_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`ORDERREFUNDLIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      
      const response = yield API.cancelOrderRefundList(action.data);
  
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }
        yield put(actions.cancelOrderRefundListSuccess(response.data));
        
      } else {
        alert(response.data.message);
        yield put(actions.cancelOrderRefundListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.cancelOrderRefundListFail());
    }
  });
}

export function* cancelOrderRefundDetail() {
  
  yield takeEvery(actionTypes.CANCEL_ORDER_REFUND_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERREFUNDDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.cancelOrderRefundDetail(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.cancelOrderRefundDetailSuccess(response.data.data));
        
      } else {
        alert(response.data.message);
        yield put(actions.cancelOrderRefundDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.cancelOrderRefundDetailFail());
    }
  });
}


export function* cancelOrderProcessRefund() {
  
  yield takeEvery(actionTypes.CANCEL_ORDER_PROCESS_REFUND, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PROCESSCANCELREFUND");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.cancelOrderProcessRefund(action.id, action.data);
  
      if (response.data.success) {
        
        yield put(actions.cancelOrderProcessRefundSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.cancelOrderProcessRefundFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.cancelOrderProcessRefundFail());
    }
  });
}


export function* reassignTrip() {
  
  yield takeEvery(actionTypes.REASSIGN_TRIP, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REASSIGNTRIP");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.reassignTrip(action.id, action.data);
  
      if (response.data.success) {
        if(response.data.data)
        {
          yield put(actions.reassignTripSuccess(response.data.data));

        }else{
          yield put(actions.reassignTripSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }
        
      } else {
        alert(response.data.message);
        yield put(actions.reassignTripFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.reassignTripFail());
    }
  });
}


export function* makeItTodayTrip() {
  
  yield takeEvery(actionTypes.MAKE_IT_TODAY_TRIP, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MAKEITTODAYTRIP");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.makeItTodayTrip(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.makeItTodayTripSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.makeItTodayTripFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.makeItTodayTripFail());
    }
  });
}


/* ===== COLLECTION REQUEST ===== */   

export function* collectionRequestCount() {
  
  yield takeEvery(actionTypes.COLLECTION_REQUEST_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("COLLECTIONREQUESTCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.collectionRequestCount(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.collectionRequestCountSuccess(response.data.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.collectionRequestCountFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.collectionRequestCountFail());
    }
  });
}

export function* collectionRequestLoad() {
  
  yield takeEvery(actionTypes.COLLECTION_REQUEST_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.status) ? action.data.status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`COLLECTIONREQUESTLIST${status}${search}`);
    
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.collectionRequestLoad(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.collectionRequestLoadSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.collectionRequestLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.collectionRequestLoadFail());
    }
  });
}

export function* collectionRequestApprove() {
  
  yield takeEvery(actionTypes.COLLECTION_REQUEST_APPROVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRCOLLECTIONREQUEST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.collectionRequestApprove(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.collectionRequestApproveSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.collectionRequestApproveFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.collectionRequestApproveFail());
    }
  });
}

export function* collectionRequestReject() {
  
  yield takeEvery(actionTypes.COLLECTION_REQUEST_REJECT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTCOLLECTIONREQUEST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.collectionRequestReject(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.collectionRequestRejectSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.collectionRequestRejectFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.collectionRequestRejectFail());
    }
  });
}
/* ===== END COLLECTION REQUEST ===== */   


/* ===== FILLING_EXTENSION REQUEST ===== */   

export function* fillingExtensionRequestCount() {
  
  yield takeEvery(actionTypes.FILLING_EXTENSION_REQUEST_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("FILLINGEXTREQUESTCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.fillingExtensionRequestCount(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.fillingExtensionRequestCountSuccess(response.data.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.fillingExtensionRequestCountFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.fillingExtensionRequestCountFail());
    }
  });
}

export function* fillingExtensionRequestLoad() {
  
  yield takeEvery(actionTypes.FILLING_EXTENSION_REQUEST_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.status) ? action.data.status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`FILLINGEXTREQUESTLIST${status}${search}`);
    
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.fillingExtensionRequestLoad(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.fillingExtensionRequestLoadSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.fillingExtensionRequestLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.fillingExtensionRequestLoadFail());
    }
  });
}

export function* fillingExtensionRequestDetail() {
  
  yield takeEvery(actionTypes.FILLING_EXTENSION_REQUEST_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("FILLINGEXTREQUESTDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.fillingExtensionRequestDetail(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.fillingExtensionRequestDetailSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.fillingExtensionRequestDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.fillingExtensionRequestDetailFail());
    }
  });
}

export function* fillingExtensionRequestApprove() {
  
  yield takeEvery(actionTypes.FILLING_EXTENSION_REQUEST_APPROVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRFILLINGEXTREQUEST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.fillingExtensionRequestApprove(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.fillingExtensionRequestApproveSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.fillingExtensionRequestApproveFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.fillingExtensionRequestApproveFail());
    }
  });
}

export function* fillingExtensionRequestReject() {
  
  yield takeEvery(actionTypes.FILLING_EXTENSION_REQUEST_REJECT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTFILLINGEXTREQUEST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.fillingExtensionRequestReject(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.fillingExtensionRequestRejectSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.fillingExtensionRequestRejectFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.fillingExtensionRequestRejectFail());
    }
  });
}
/* ===== END FILLING_EXTENSION REQUEST ===== */   


/* ===== MADE_IT_TODAY REQUEST ===== */   

export function* madeItTodayRequestCount() {
  
  yield takeEvery(actionTypes.MADE_IT_TODAY_REQUEST_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MADEITTODAYREQUESTCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.madeItTodayRequestCount(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.madeItTodayRequestCountSuccess(response.data.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.madeItTodayRequestCountFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.madeItTodayRequestCountFail());
    }
  });
}

export function* madeItTodayRequestLoad() {
  
  yield takeEvery(actionTypes.MADE_IT_TODAY_REQUEST_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.status) ? action.data.status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`MADEITTODAYREQUESTLIST${status}${search}`);
    
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.madeItTodayRequestLoad(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.madeItTodayRequestLoadSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.madeItTodayRequestLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.madeItTodayRequestLoadFail());
    }
  });
}

export function* madeItTodayRequestDetail() {
  
  yield takeEvery(actionTypes.MADE_IT_TODAY_REQUEST_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MADEITTODAYREQUESTDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.madeItTodayRequestDetail(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.madeItTodayRequestDetailSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.madeItTodayRequestDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.madeItTodayRequestDetailFail());
    }
  });
}

export function* madeItTodayRequestApprove() {
  
  yield takeEvery(actionTypes.MADE_IT_TODAY_REQUEST_APPROVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRMADEITTODAYREQUEST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.madeItTodayRequestApprove(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.madeItTodayRequestApproveSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.madeItTodayRequestApproveFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.madeItTodayRequestApproveFail());
    }
  });
}

export function* madeItTodayRequestReject() {
  
  yield takeEvery(actionTypes.MADE_IT_TODAY_REQUEST_REJECT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTMADEITTODAYREQUEST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.madeItTodayRequestReject(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.madeItTodayRequestRejectSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.madeItTodayRequestRejectFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.madeItTodayRequestRejectFail());
    }
  });
}
/* ===== END MADE_IT_TODAY REQUEST ===== */ 


/* ===== ADD_ON_TRIP REQUEST ===== */   

export function* addonTripRequestCount() {
  
  yield takeEvery(actionTypes.ADD_ON_TRIP_REQUEST_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDONTRIPREQUESTCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.addonTripRequestCount(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.addonTripRequestCountSuccess(response.data.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.addonTripRequestCountFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addonTripRequestCountFail());
    }
  });
}

export function* addonTripRequestLoad() {
  
  yield takeEvery(actionTypes.ADD_ON_TRIP_REQUEST_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.status) ? action.data.status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`ADDONTRIPREQUESTLIST${status}${search}`);

    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.addonTripRequestLoad(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.addonTripRequestLoadSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.addonTripRequestLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addonTripRequestLoadFail());
    }
  });
}

export function* addonTripRequestDetail() {
  
  yield takeEvery(actionTypes.ADD_ON_TRIP_REQUEST_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDONTRIPREQUESTDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.addonTripRequestDetail(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.addonTripRequestDetailSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.addonTripRequestDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addonTripRequestDetailFail());
    }
  });
}

export function* addonTripRequestApprove() {
  
  yield takeEvery(actionTypes.ADD_ON_TRIP_REQUEST_APPROVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRADDONTRIPREQUEST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.addonTripRequestApprove(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.addonTripRequestApproveSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.addonTripRequestApproveFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addonTripRequestApproveFail());
    }
  });
}

export function* addonTripRequestReject() {
  
  yield takeEvery(actionTypes.ADD_ON_TRIP_REQUEST_REJECT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTADDONTRIPREQUEST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.addonTripRequestReject(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.addonTripRequestRejectSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.addonTripRequestRejectFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addonTripRequestRejectFail());
    }
  });
}
/* ===== END ADD_ON_TRIP REQUEST ===== */   


export function* findDriverSimulate() {
  
  yield takeEvery(actionTypes.FIND_DRIVER_SIMULATE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("FINDDRIVERSIMULATE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.findDriverSimulate(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.findDriverSimulateSuccess(response.data.data)); 
   
      } else {
        alert(response.data.message);
        yield put(actions.findDriverSimulateFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.findDriverSimulateFail());
    }
  });
}

/* ===== GRADING  ===== */
export function* gradingListLoad() {
  
  yield takeEvery(actionTypes.LOAD_GRADING_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`GRADINGLIST${status}${search}`);

    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.gradingListLoad(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.gradingListLoadSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.gradingListLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.gradingListLoadFail());
    }
  });
}


export function* gradingCount() {
  
  yield takeEvery(actionTypes.LOAD_GRADING_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.gradingCount(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.gradingCountSuccess(response.data.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.gradingCountFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.gradingCountFail());
    }
  });
}


export function* pendingGradingListLoad() {
  
  yield takeEvery(actionTypes.LOAD_PENDING_GRADING_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`PENDINGGRADINGLIST${status}${search}`);

    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.pendingGradingListLoad(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.pendingGradingListLoadSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.pendingGradingListLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.pendingGradingListLoadFail());
    }
  });
}


export function* pendingGradingCount() {
  
  yield takeEvery(actionTypes.LOAD_PENDING_GRADING_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PENDINGGRADINGCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.pendingGradingCount(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.pendingGradingCountSuccess(response.data.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.pendingGradingCountFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.pendingGradingCountFail());
    }
  });
}


export function* pendingWasteGradingListLoad() {
  
  yield takeEvery(actionTypes.LOAD_PENDING_WASTE_GRADING_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`PENDINGWASTEGRADINGLIST${status}${search}`);

    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.pendingWasteGradingListLoad(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.pendingWasteGradingListLoadSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.pendingWasteGradingListLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.pendingWasteGradingListLoadFail());
    }
  });
}


export function* pendingWasteGradingCount() {
  
  yield takeEvery(actionTypes.LOAD_PENDING_WASTE_GRADING_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PENDINGWASTEGRADINGCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.pendingWasteGradingCount(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.pendingWasteGradingCountSuccess(response.data.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.pendingWasteGradingCountFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.pendingWasteGradingCountFail());
    }
  });
}

/* ===== END GRADING  ===== */


/* ===== MPRM Process  ===== */
export function* MPRMProcessList() {
  
  yield takeEvery(actionTypes.MPRM_PROCESS_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`MPRMPROCESSLIST${search}`);
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.MPRMProcessList(action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.MPRMProcessListSuccess(response.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.MPRMProcessListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.MPRMProcessListFail());
    }
  });
}

export function* MPRMProcessDetail() {
  
  yield takeEvery(actionTypes.MPRM_PROCESS_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MPRMPROCESSDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.MPRMProcessDetail(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        yield put(actions.MPRMProcessDetailSuccess(response.data.data));   
        
      } else {
        alert(response.data.message);
        yield put(actions.MPRMProcessDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.MPRMProcessDetailFail());
    }
  });
}

export function* MPRMProcessRetry() {
  
  yield takeEvery(actionTypes.MPRM_PROCESS_RETRY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MPRMPROCESSRETRY");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.MPRMProcessRetry(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.MPRMProcessRetrySuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.MPRMProcessRetryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.MPRMProcessRetryFail());
    }
  });
}

export function* MPRMProcessReset() {
  
  yield takeEvery(actionTypes.MPRM_PROCESS_RESET, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MPRMPROCESSRESET");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.MPRMProcessReset(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.MPRMProcessResetSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.MPRMProcessResetFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.MPRMProcessResetFail());
    }
  });
}
/* ===== END MPRM Process  ===== */


/* ===== END EXPRESS  ===== */


export function* expressOrderCount() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_ORDER_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSORDERCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.expressOrderCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.expressOrderCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.expressOrderCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.expressOrderCountFail());
    }
  });
}

export function* loadExpressOrderList() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_ORDER_LIST, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.order_status) ? action.data.order_status : ""
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`EXPRESSORDERLIST${status}${search}`);
    
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressOrderList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadExpressOrderListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressOrderListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressOrderListFail());
    }
  });
}


export function* loadExpressOrderDetail() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_ORDER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSORDERDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadExpressOrderDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadExpressOrderDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressOrderDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressOrderDetailFail());
    }
  });
}

export function* cancelExpressOrder() {
  
  yield takeEvery(actionTypes.CANCEL_EXPRESS_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CANCELEXPRESSORDER");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.cancelExpressOrder(action.id, action.data);
  
      if (response.data.success) {
        yield put(actions.cancelExpressOrderSuccess());
        yield put(actions.toastSuccess(response.data.message));        
        
      } else {
        alert(response.data.message);
        yield put(actions.cancelExpressOrderFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.cancelExpressOrderFail());
    }
  });
}


export function* expressStoreReceipt() {
  
  yield takeEvery(actionTypes.EXPRESS_STORE_RECEIPT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSSTORERECEIPT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.expressStoreReceipt(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.expressStoreReceiptSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.expressStoreReceiptFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.expressStoreReceiptFail());
    }
  });
}

export function* loadExpressOrderSummary() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSSUMMARY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressOrderSummary(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadExpressOrderSummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressOrderSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressOrderSummaryFail());
    }
  });
}


export function* loadExpressOrderSummaryChart() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_SUMMARY_CHART, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSSUMMARYCHART");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressOrderSummaryChart(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadExpressOrderSummaryChartSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressOrderSummaryChartFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressOrderSummaryChartFail());
    }
  });
}


export function* loadExpressOrderSaleSummary() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_SALE_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSSALESUMMARY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressOrderSaleSummary(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadExpressOrderSaleSummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressOrderSaleSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressOrderSaleSummaryFail());
    }
  });
}


export function* graderDailySettlement() {
  
  yield takeEvery(actionTypes.LOAD_GRADER_DAILY_SETTLEMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADERDAILYSETTLEMENT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.graderDailySettlement(action.data);
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.graderDailySettlementSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.graderDailySettlementFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.graderDailySettlementFail());
    }
  });
}

/* ===== END EXPRESS  ===== */


export default function* orderSaga() {
  yield all([fork(loadOrderList)]);
  yield all([fork(createOrder)]);
  yield all([fork(cancelOrder)]);
  yield all([fork(getOrderCancellationDetail)]);
  yield all([fork(loadOrderDetail)]);
  yield all([fork(loadOrderDetailTripGroup)]);
  yield all([fork(loadOrderDetailTripList)]);
  yield all([fork(loadOrderTripList)]);
  yield all([fork(orderTripCount)]);  
  yield all([fork(loadDriverTripList)]);
  yield all([fork(updateTripPlacement)]);
  yield all([fork(updateTripFilling)]);
  yield all([fork(rollbackTripFilling)]);
  yield all([fork(updateTripCollection)]);
  yield all([fork(updateTripDisposal)]);
  yield all([fork(reuploadDisposalSlip)]);
  yield all([fork(reuploadTripProofImg)]);  
  yield all([fork(reuploadTripWeighingSlip)]);
  yield all([fork(skipSignature)]);
  yield all([fork(updateDumpsterEmpty)]);
  yield all([fork(updateTripLoadingCollection)]);
  yield all([fork(trackDriver)]);
  yield all([fork(tripSetArrived)]);
  yield all([fork(tripUpdateGoingNow)]);
  yield all([fork(tripAllowGoingNowAdvance)]);
  yield all([fork(tripRollbackGoingNow)]);
  yield all([fork(tripSetStarted)]);
  yield all([fork(editTripWeighingFigure)]);
  yield all([fork(resetCollectionTrip)]);  
  yield all([fork(billingList)]);
  yield all([fork(orderInvoice)]);
  yield all([fork(orderInvoiceStack)]);
  yield all([fork(agreementDebitCreditNoteLoad)]);
  yield all([fork(agreementDebitCreditNoteStack)]); 
  yield all([fork(specialInvoiceList)]);
  yield all([fork(getSpecialInvoice)]);
  yield all([fork(getSpecialInvoiceStack)]); 
  yield all([fork(purchaseBillList)]);
  yield all([fork(purchaseBillDumpExpress)]);  
  yield all([fork(purchaseBillDumpExpressStack)]); 
  yield all([fork(purchaseBillDumpRecycling)]);
  yield all([fork(purchaseBillDumpRecyclingStack)]); 
  yield all([fork(purchaseBillStatement)]);   
  yield all([fork(purchaseDebitCreditNoteList)]);   
  yield all([fork(purchaseDebitCreditNote)]);   
  yield all([fork(purchaseDebitCreditNoteStack)]);   
  yield all([fork(storeReceiptList)]);   
  yield all([fork(getStoreReceiptDumpExpressStack)]);  
  yield all([fork(getStoreReceiptDumpRecyclingStack)]);   
  yield all([fork(wasteReceiptList)]);   
  yield all([fork(getWasteReceiptStack)]);   
  yield all([fork(recyclableSalesInvoiceList)]);
  yield all([fork(recyclableSalesInvoice)]);    
  yield all([fork(recyclableSalesInvoiceStack)]);       
  yield all([fork(bookingFeeList)]);
  yield all([fork(processFeeList)]);  
  yield all([fork(landfillFeeList)]);  
  yield all([fork(userLoadingFeeList)]);  
  yield all([fork(driverLoadingPaymentList)]);  
  yield all([fork(sortingFeeList)]);  
  yield all([fork(oversizeFeeList)]);    
  yield all([fork(recyclablePurchaseList)]);
  yield all([fork(orderTrackReport)]);
  yield all([fork(orderTripStoreReceipt)]);
  yield all([fork(orderTripWasteReceipt)]);
  yield all([fork(orderWasteSummary)]);
  yield all([fork(loadTransactionList)]);
  yield all([fork(loadDuitNowTransactionList)]);
  yield all([fork(loadOrderSummary)]);
  yield all([fork(loadOrderSummaryChart)]);
  yield all([fork(loadOrderSaleSummary)]);  
  yield all([fork(loadDriverIncentive)]);
  yield all([fork(loadUserOrderHistory)]);  
  yield all([fork(monthlyWasteSummary)]);
  yield all([fork(monthlyWasteSummaryLoad)]);
  yield all([fork(monthlyStockSummary)]);
  yield all([fork(monthlyStockSummaryLoad)]);
  yield all([fork(monthlyWasteStockSummary)]);
  yield all([fork(monthlyWasteStockSummaryLoad)]);
  yield all([fork(monthlyDriverTripSummary)]);
  yield all([fork(resetDriverTripSummary)]);
  yield all([fork(orderDistanceSummary)]);
  yield all([fork(referralCommissionSummary)]);
  yield all([fork(monthlyLandfillSummary)]);
  yield all([fork(monthlyLandfillSummaryLoad)]);  
  yield all([fork(orderCount)]);
  yield all([fork(orderAssignDriver)]);
  yield all([fork(loadAdjAgreementList)]);
  yield all([fork(loadAdjAgreementDetail)]);
  yield all([fork(agreementProceeRefund)]);
  yield all([fork(loadAdjAffectedOrderList)]);
  yield all([fork(cancelOrderRefundList)]);
  yield all([fork(cancelOrderRefundDetail)]);
  yield all([fork(cancelOrderProcessRefund)]);
  yield all([fork(reassignTrip)]);
  yield all([fork(makeItTodayTrip)]);
  yield all([fork(collectionRequestCount)]);
  yield all([fork(collectionRequestLoad)]);
  yield all([fork(collectionRequestApprove)]);
  yield all([fork(collectionRequestReject)]);
  yield all([fork(fillingExtensionRequestCount)]);
  yield all([fork(fillingExtensionRequestLoad)]);
  yield all([fork(fillingExtensionRequestDetail)]);
  yield all([fork(fillingExtensionRequestApprove)]);
  yield all([fork(fillingExtensionRequestReject)]);
  yield all([fork(madeItTodayRequestCount)]);
  yield all([fork(madeItTodayRequestLoad)]);
  yield all([fork(madeItTodayRequestDetail)]);
  yield all([fork(madeItTodayRequestApprove)]);
  yield all([fork(madeItTodayRequestReject)]);
  yield all([fork(addonTripRequestCount)]);
  yield all([fork(addonTripRequestLoad)]);
  yield all([fork(addonTripRequestDetail)]);
  yield all([fork(addonTripRequestApprove)]);
  yield all([fork(addonTripRequestReject)]);
  yield all([fork(findDriverSimulate)]);
  yield all([fork(gradingListLoad)]);
  yield all([fork(gradingCount)]);
  yield all([fork(pendingGradingListLoad)]);
  yield all([fork(pendingGradingCount)]);
  yield all([fork(pendingWasteGradingListLoad)]);
  yield all([fork(pendingWasteGradingCount)]);
  yield all([fork(MPRMProcessList)]);
  yield all([fork(MPRMProcessDetail)]);
  yield all([fork(MPRMProcessRetry)]);
  yield all([fork(MPRMProcessReset)]);
  
  yield all([fork(expressOrderCount)]);
  yield all([fork(loadExpressOrderList)]);
  yield all([fork(loadExpressOrderDetail)]);
  yield all([fork(cancelExpressOrder)]);
  yield all([fork(expressStoreReceipt)]);
  yield all([fork(loadExpressOrderSummary)]);
  yield all([fork(loadExpressOrderSummaryChart)]);
  yield all([fork(loadExpressOrderSaleSummary)]);
  yield all([fork(graderDailySettlement)]);
}
