import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  orderList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    isLoading: false,
  },
  orderTripList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    isLoading: false,
  },
  orderTripCount: {},
  driverTripList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  orderDetail:{},
  orderDetailTripGroup: [],
  orderDetailTripList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    allTrips: []
  },
  trackDriver:{},
  istrackDriverSuccess: false,
  isUpdatePlacementSuccess: false,
  isUpdateFillingSuccess: false,
  isRollbackFillingSuccess: false,
  isUpdateCollectionSuccess: false,
  DumpsterEmptyConfirmation: null,
  collectionConfirmation: null,
  isUpdateDisposalSuccess: false,
  isReuploadDisposalSlipSuccess: false,
  isReuploadTripProofSuccess: false,
  isReuploadTripWeighingSlipSuccess: false,
  isUpdateLoadingCollectionSuccess: false,
  isCancelOrderSuccess: false,
  orderCancellationReview: null,
  assignConfirmation: null,
  billingList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  orderInvoice:"",
  isInvoiceAmountUpdated: false,
  orderInvoiceStack: [],
  agreement_debit_credit_note: "",
  agreementDebitCreditNoteStack: [],
  specialInvoiceList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  specialInvoice:"",
  specialInvoiceStack:[],
  purchaseBillList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  purchaseBillDumpRecycling:"",
  purchaseBillDumpRecyclingStack: [],
  purchaseBillDumpExpress:"",
  purchaseBillDumpExpressStack: [],
  purchaseBillStatement: [],
  purchaseDebitCreditNoteList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  purchaseDebitCreditNote:"",
  purchaseDebitCreditNoteStack: [],
  storeReceiptList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  storeReceiptDumpExpressStack: [],
  storeReceiptDumpRecyclingStack: [],
  wasteReceiptList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  recyclableSalesInvoiceList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  recyclableSalesInvoice:"",
  recyclableSalesInvoiceStack:[],
  orderTrackReport:"",
  orderTripStoreReceipt: "",
  orderTripWasteReceipt: "",
  orderWasteSummary:"",
  monthlyWasteSummaryReport: "",
  monthlyWasteSummary: [],
  monthlyLandfillSummaryReport: "",
  monthlyLandfillSummary: [],
  monthlyStockSummaryReport: "",
  monthlyStockSummary: [],
  monthlyWasteStockSummaryReport: "",
  monthlyWasteStockSummary: [],
  transactionList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  duitNowTransactionList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  orderSummary:{},
  orderSummaryChart: {},
  orderSaleSummary:{},
  orderIncentive: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  orderHistory: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  orderCount:{},
  isOrderAssignedSuccess: false,
  isOrderAssignedFailed: false,
  isDisposalSetArrivedSuccess: false,
  disposalDetails: {},
  isTripStartedSuccess: false,
  isWeighingFigureEditSuccess: false,
  isWeighingFigureEditFail: false,
  isCollectionTripResetSuccess: false,
  isTripUpdateGoingNowSuccess: false,
  isTripUpdateGoingNowAdvanceSuccess: false,
  isTripRollbackGoingNowSuccess: false,
  adjAgreementList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  adjAgreementDetail: {},
  isAgreementRefundSuccess: false,
  adjAffectedOrder: [],
  adjAffectedOrder_backup: [],
  
  cancelOrderRefundList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  cancelOrderRefundDetail: {},
  isProcessCancelOrderRefund: false,
  cancelOrderRefundedSuccess: false,
  reassignTripSuccess: false,
  reassignTripConfirm:null,
  tripMakeItTodaySuccess: false,
  collectionRequestCount: {},
  collectionRequestList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isCollectionRequestApproveSuccess: false,
  isCollectionRequestRejectSuccess: false,
  fillingExtensionRequestCount: {},
  fillingExtensionRequestList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  fillingExtensionRequestDetail: {},
  isFillingExtensionRequestApproveSuccess: false,
  isFillingExtensionRequestRejectSuccess: false,
  madeItTodayRequestCount: {},
  madeItTodayRequestList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  madeItTodayRequestDetail: {},
  isMadeItTodayRequestApproveSuccess: false,
  isMadeItTodayRequestRejectSuccess: false,
  addonTripRequestCount: {},
  addonTripRequestList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  addonTripRequestDetail: {},
  isAddonTripRequestApproveSuccess: false,
  isAddonTripRequestRejectSuccess: false,
  findDriverSimulateList: [],
  gradingList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  gradingCount: {},
  pendingGradingList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  pendingGradingCount: {},
  pendingWasteGradingList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  pendingWasteGradingCount: {},
  bookingFeeList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  processFeeList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  landfillFeeList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  userLoadingFeeList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  driverLoadingPaymentList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  sortingFeeList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  oversizeFeeList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  recyclablePurchaseList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  isSkipSignatureSuccess: false,
  isUpdateDumpsterEmptySuccess: false,
  cancellationDetail: {},
  MPRMProcessList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  MPRMProcessDetail: {},
  isMPRMProcessRetrySuccess: false,
  isMPRMProcessRetryFail: false,
  isMPRMProcessResetSuccess: false,
  isMPRMProcessResetFail: false,
  
  expressOrderCount:{},
  expressOrderList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false,
    isLoading: false,
  },
  expressOrderDetail: {},
  isCancelExpressOrderSuccess: false,
  isCancelExpressOrderFail: false,
  expressStoreReceipt: "",
  expressSummary:{},
  expressSummaryChart:{},
  expressSaleSummary:{},  
  graderSettlementRecord: [],
  graderSettlementDetail: {},
  graderSettlementExportSuccess: false,
  monthlyDriverTripSummary: {},  
  monthlyDriverTripSummary_backup: {},
  orderDistanceSummary: [],  
  orderDistanceSummary_backup: [],  
  referralCommissionSummary: {    
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  referralCommissionSummary_backup: [],
  isProcessLoadReferralCommissionSummary: false
};

export default function orderReducer(state = initState, action) {

  switch (action.type) {
    case actionTypes.LOAD_ORDER_LIST: {
      let orderList = cloneDeep(state.orderList);

      orderList.isLoading = true;

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        orderList.offset = action.data.offset;
        orderList.limit = action.data.limit;
      }

      return {
        ...state,
        orderList
      };
    }

    case actionTypes.LOAD_ORDER_LIST_SUCCESS: {
      let orderList = cloneDeep(state.orderList);
      orderList.data = action.data.data;
      orderList.meta = {
        page: 1 + state.orderList.offset / state.orderList.limit,
        pageSize: state.orderList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.orderList.limit
        ),
        total: action.data.recordsTotal
      };

      orderList.isLoading = false;

      return {
        ...state,
        orderList
      };
    }

    case actionTypes.LOAD_ORDER_LIST_FAIL: {
      
      let orderList = cloneDeep(state.orderList);
      orderList.isLoading = false;

      return {
        ...state,
        orderList
      };
    }

    case actionTypes.LOAD_ORDER_DETAIL: {
      let orderDetail = {};
      
      return {
        ...state,
        orderDetail
      };
    }

    case actionTypes.LOAD_ORDER_DETAIL_SUCCESS: {
      return {
        ...state,
        orderDetail: action.data
      };
    }

    case actionTypes.LOAD_ORDER_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.LOAD_ORDER_DETAIL_TRIP_GROUP: {
      let orderDetailTripGroup = [];
      
      return {
        ...state,
        orderDetailTripGroup
      };
    }

    case actionTypes.LOAD_ORDER_DETAIL_TRIP_GROUP_SUCCESS: {
      return {
        ...state,
        orderDetailTripGroup: action.data
      };
    }

    case actionTypes.LOAD_ORDER_DETAIL_TRIP_GROUP_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.LOAD_ORDER_DETAIL_TRIP_LIST: {
      let orderDetailTripList = cloneDeep(state.orderDetailTripList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        orderDetailTripList.offset = action.data.offset;
        orderDetailTripList.limit = action.data.limit;
      }

      orderDetailTripList.isLoading = true;
      orderDetailTripList.allTrips = [];
      

      return {
        ...state,
        orderDetailTripList
      };
    }

    case actionTypes.LOAD_ORDER_DETAIL_TRIP_LIST_SUCCESS: {
      let orderDetailTripList = cloneDeep(state.orderDetailTripList);

      const currentList = (orderDetailTripList.data && state.orderDetailTripList.offset > 0) ? orderDetailTripList.data : [];

      orderDetailTripList.data = [...currentList, ...action.data.data];
      orderDetailTripList.meta = {
        page: 1 + state.orderDetailTripList.offset / state.orderDetailTripList.limit,
        pageSize: state.orderDetailTripList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.orderDetailTripList.limit
        ),
        total: action.data.recordsTotal
      };
      
      orderDetailTripList.isLoading = false;
      orderDetailTripList.reachEnd = (action.data.data.length === 0 || action.data.data.length < state.orderDetailTripList.limit);
      
      orderDetailTripList.allTrips = action.data.allTrips;

      return {
        ...state,
        orderDetailTripList
      };
    }

    case actionTypes.LOAD_ORDER_DETAIL_TRIP_LIST_FAIL: {
      let orderDetailTripList = cloneDeep(state.orderDetailTripList);
      orderDetailTripList.isLoading = false;

      return {
        ...state,
        orderDetailTripList
      };
    }

    
    
    case actionTypes.ORDER_COUNT: {
      
      return {
        ...state,
        orderCount: {}
      };
    }

    case actionTypes.ORDER_COUNT_SUCCESS: {
    
      return {
        ...state,
        orderCount:action.data
      };
    }

    case actionTypes.ORDER_COUNT_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.LOAD_ORDER_TRIP_LIST: {
      let orderTripList = cloneDeep(state.orderTripList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        orderTripList.offset = action.data.offset;
        orderTripList.limit = action.data.limit;
      }

      orderTripList.isLoading = true;

      return {
        ...state,
        orderTripList
      };
    }

    case actionTypes.LOAD_ORDER_TRIP_LIST_SUCCESS: {
      let orderTripList = cloneDeep(state.orderTripList);
      orderTripList.data = action.data.data;
      orderTripList.meta = {
        page: 1 + state.orderTripList.offset / state.orderTripList.limit,
        pageSize: state.orderTripList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.orderTripList.limit
        ),
        total: action.data.recordsTotal
      };
      orderTripList.isLoading = false;
      
      return {
        ...state,
        orderTripList
      };
    }

    case actionTypes.LOAD_ORDER_TRIP_LIST_FAIL: {
      let orderTripList = cloneDeep(state.orderTripList);
      orderTripList.isLoading = false;

      return {
        ...state,
        orderTripList
      };
    }


    case actionTypes.ORDER_TRIP_COUNT: {
      
      return {
        ...state,
        orderTripCount: {}
      };
    }

    case actionTypes.ORDER_TRIP_COUNT_SUCCESS: {
      
      return {
        ...state,
        orderTripCount: action.data
      };
    }

    case actionTypes.ORDER_TRIP_COUNT_FAIL: {
      return {
        ...state
      };
    }


    
    
    case actionTypes.LOAD_DRIVER_TRIP_LIST: {
      let driverTripList = cloneDeep(state.driverTripList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverTripList.offset = action.data.offset;
        driverTripList.limit = action.data.limit;
      }

      return {
        ...state,
        driverTripList
      };
    }

    case actionTypes.LOAD_DRIVER_TRIP_LIST_SUCCESS: {
      let driverTripList = cloneDeep(state.driverTripList);
      driverTripList.data = action.data.data;
      driverTripList.meta = {
        page: 1 + state.driverTripList.offset / state.driverTripList.limit,
        pageSize: state.driverTripList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.driverTripList.limit
        ),
        total: action.data.recordsTotal
      };
      
      return {
        ...state,
        driverTripList
      };
    }

    case actionTypes.LOAD_DRIVER_TRIP_LIST_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.TRACK_DRIVER: {
      let trackDriver = cloneDeep(state.trackDriver);
      
      return {
        ...state,
        trackDriver,
        isTrackDriverSuccess: false
      };
    }

    case actionTypes.TRACK_DRIVER_SUCCESS: {
      
      return {
        ...state,
        trackDriver: action.data,
        isTrackDriverSuccess: true
      };
    }

    case actionTypes.TRACK_DRIVER_FAIL: {
      return {
        ...state,
        isTrackDriverSuccess: false
      };
    }

    case actionTypes.TRIP_SET_ARRIVED: {
      
      return {
        ...state,
        isDisposalSetArrivedSuccess: false
      };
    }

    case actionTypes.TRIP_SET_ARRIVED_SUCCESS: {
      
      return {
        ...state,
        isDisposalSetArrivedSuccess: true
      };
    }

    case actionTypes.TRIP_SET_ARRIVED_FAIL: {
      return {
        ...state,
        isDisposalSetArrivedSuccess: false
      };
    }
    

    case actionTypes.TRIP_UPDATE_GOING_NOW: {
      
      return {
        ...state,
        disposalDetails: {},
        isTripUpdateGoingNowSuccess: false
      };
    }

    case actionTypes.TRIP_UPDATE_GOING_NOW_SUCCESS: {
      
      return {
        ...state,
        disposalDetails: action.data,
        isTripUpdateGoingNowSuccess: true
      };
    }

    case actionTypes.TRIP_UPDATE_GOING_NOW_FAIL: {
      return {
        ...state,
        isTripUpdateGoingNowSuccess: false
      };
    }
    

    case actionTypes.TRIP_UPDATE_GOING_NOW_ADVANCE: {
      
      return {
        ...state,
        isTripUpdateGoingNowAdvanceSuccess: false
      };
    }

    case actionTypes.TRIP_UPDATE_GOING_NOW_ADVANCE_SUCCESS: {
      
      return {
        ...state,
        isTripUpdateGoingNowAdvanceSuccess: true
      };
    }

    case actionTypes.TRIP_UPDATE_GOING_NOW_ADVANCE_FAIL: {
      return {
        ...state,
        isTripUpdateGoingNowAdvanceSuccess: false
      };
    }
    
    case actionTypes.TRIP_ROLLBACK_GOING_NOW: {
      
      return {
        ...state,
        isTripRollbackGoingNowSuccess: false
      };
    }

    case actionTypes.TRIP_ROLLBACK_GOING_NOW_SUCCESS: {
      
      return {
        ...state,
        isTripRollbackGoingNowSuccess: true
      };
    }

    case actionTypes.TRIP_ROLLBACK_GOING_NOW_FAIL: {
      return {
        ...state,
        isTripRollbackGoingNowSuccess: false
      };
    }
    
    
    case actionTypes.TRIP_SET_STARTED: {
      
      return {
        ...state,
        isTripStartedSuccess: false
      };
    }

    case actionTypes.TRIP_SET_STARTED_SUCCESS: {
      
      return {
        ...state,
        isTripStartedSuccess: true
      };
    }

    case actionTypes.TRIP_SET_STARTED_FAIL: {
      return {
        ...state,
        isTripStartedSuccess: false
      };
    }
    
    
    
    case actionTypes.EDIT_TRIP_WEIGHING_FIGURE: {
      
      return {
        ...state,
        isWeighingFigureEditSuccess: false,
        isWeighingFigureEditFail: false
      };
    }

    case actionTypes.EDIT_TRIP_WEIGHING_FIGURE_SUCCESS: {
      
      return {
        ...state,
        isWeighingFigureEditSuccess: (action.data) ? false : true,
        isWeighingFigureEditFail: (action.data) ? true : false,
        weighingFigureEditConfirmation: (action.data && action.data.update_confirm) ? action.data : null,
      };
    }

    case actionTypes.EDIT_TRIP_WEIGHING_FIGURE_FAIL: {
      return {
        ...state,
        isWeighingFigureEditFail: true
      };
    }
    
    
    case actionTypes.RESET_COLLECTION_TRIP: {
      
      return {
        ...state,
        isCollectionTripResetSuccess: false,
        resetConfirmation: null,
      };
    }

    case actionTypes.RESET_COLLECTION_TRIP_SUCCESS: {
      
      return {
        ...state,
        isCollectionTripResetSuccess: (action.data) ? false : true,
        resetConfirmation: (action.data && action.data.reset_confirmed) ? action.data : null,
      };
    }

    case actionTypes.RESET_COLLECTION_TRIP_FAIL: {
      return {
        ...state,
        isCollectionTripResetSuccess: false
      };
    }

    
    case actionTypes.UPDATE_TRIP_PLACEMENT: {
      
      return {
        ...state,
        isUpdatePlacementSuccess: false
      };
    }

    case actionTypes.UPDATE_TRIP_PLACEMENT_SUCCESS: {
      
      return {
        ...state,
        isUpdatePlacementSuccess: true
      };
    }

    case actionTypes.UPDATE_TRIP_PLACEMENT_FAIL: {
      return {
        ...state,
        isUpdatePlacementSuccess: false
      };
    }
    

    case actionTypes.UPDATE_TRIP_FILLING: {
      
      return {
        ...state,
        isUpdateFillingSuccess: false
      };
    }

    case actionTypes.UPDATE_TRIP_FILLING_SUCCESS: {
      
      return {
        ...state,
        isUpdateFillingSuccess: true
      };
    }

    case actionTypes.UPDATE_TRIP_FILLING_FAIL: {
      return {
        ...state,
        isUpdateFillingSuccess: false
      };
    }

    case actionTypes.ROLLBACK_TRIP_FILLING: {
      
      return {
        ...state,
        isRollbackFillingSuccess: false
      };
    }

    case actionTypes.ROLLBACK_TRIP_FILLING_SUCCESS: {
      
      return {
        ...state,
        isRollbackFillingSuccess: true
      };
    }

    case actionTypes.ROLLBACK_TRIP_FILLING_FAIL: {
      return {
        ...state,
        isRollbackFillingSuccess: false
      };
    }
    
    case actionTypes.UPDATE_TRIP_COLLECTION: {
      
      return {
        ...state,
        isUpdateCollectionSuccess: false,
        DumpsterEmptyConfirmation: null,
        collectionConfirmation: null
      };
    }

    case actionTypes.UPDATE_TRIP_COLLECTION_SUCCESS: {
      
      return {
        ...state,
        isUpdateCollectionSuccess: (action.data && (action.data.dumpster_empty_confirmation || action.data.placeDumpster)) ? false : true,
        DumpsterEmptyConfirmation: (action.data && action.data.dumpster_empty_confirmation) ? {id: action.id, dumpsterEmptyConfirmation: true, title: action.data.title, msg: action.data.msg, params: action.params} : null,
        collectionConfirmation: (action.data && action.data.placeDumpster) ? {id: action.id, placeDumpster: true, title: action.data.title, msg: action.data.msg, params: action.params} : null,
      };
    }

    case actionTypes.UPDATE_TRIP_COLLECTION_FAIL: {
      return {
        ...state,
        isUpdateCollectionSuccess: false
      };
    }

    case actionTypes.UPDATE_TRIP_DISPOSAL: {
      
      return {
        ...state,
        isUpdateDisposalSuccess: false
      };
    }

    case actionTypes.UPDATE_TRIP_DISPOSAL_SUCCESS: {
      
      return {
        ...state,
        isUpdateDisposalSuccess: true
      };
    }

    case actionTypes.UPDATE_TRIP_DISPOSAL_FAIL: {
      return {
        ...state,
        isUpdateDisposalSuccess: false
      };
    }

    case actionTypes.REUPLOAD_DISPOSAL_SLIP: {
      
      return {
        ...state,
        isReuploadDisposalSlipSuccess: false
      };
    }

    case actionTypes.REUPLOAD_DISPOSAL_SLIP_SUCCESS: {
      
      return {
        ...state,
        isReuploadDisposalSlipSuccess: true
      };
    }

    case actionTypes.REUPLOAD_DISPOSAL_SLIP_FAIL: {
      return {
        ...state,
        isReuploadDisposalSlipSuccess: false
      };
    }
    

    case actionTypes.REUPLOAD_TRIP_PROOF: {
      
      return {
        ...state,
        isReuploadTripProofSuccess: false
      };
    }

    case actionTypes.REUPLOAD_TRIP_PROOF_SUCCESS: {
      
      return {
        ...state,
        isReuploadTripProofSuccess: true
      };
    }

    case actionTypes.REUPLOAD_TRIP_PROOF_FAIL: {
      return {
        ...state,
        isReuploadTripProofSuccess: false
      };
    }
    

    case actionTypes.REUPLOAD_TRIP_WEIGHING_SLIP: {
      
      return {
        ...state,
        isReuploadTripWeighingSlipSuccess: false
      };
    }

    case actionTypes.REUPLOAD_TRIP_WEIGHING_SLIP_SUCCESS: {
      
      return {
        ...state,
        isReuploadTripWeighingSlipSuccess: true
      };
    }

    case actionTypes.REUPLOAD_TRIP_WEIGHING_SLIP_FAIL: {
      return {
        ...state,
        isReuploadTripWeighingSlipSuccess: false
      };
    }
    
    
    case actionTypes.SKIP_SIGNATURE: {
      
      return {
        ...state,
        isSkipSignatureSuccess: false
      };
    }

    case actionTypes.SKIP_SIGNATURE_SUCCESS: {
      
      return {
        ...state,
        isSkipSignatureSuccess: true
      };
    }

    case actionTypes.SKIP_SIGNATURE_FAIL: {
      return {
        ...state,
        isSkipSignatureSuccess: false
      };
    }
    

    case actionTypes.UPDATE_DUMPSTER_EMPTY: {
      
      return {
        ...state,
        isUpdateDumpsterEmptySuccess: false
      };
    }

    case actionTypes.UPDATE_DUMPSTER_EMPTY_SUCCESS: {
      
      return {
        ...state,
        isUpdateDumpsterEmptySuccess: true
      };
    }

    case actionTypes.UPDATE_DUMPSTER_EMPTY_FAIL: {
      return {
        ...state,
        isUpdateDumpsterEmptySuccess: false
      };
    }

    
    case actionTypes.UPDATE_TRIP_LOADING_COLLECTION: {
      
      return {
        ...state,
        isUpdateLoadingCollectionSuccess: false
      };
    }

    case actionTypes.UPDATE_TRIP_LOADING_COLLECTION_SUCCESS: {
      
      return {
        ...state,
        isUpdateLoadingCollectionSuccess: true
      };
    }

    case actionTypes.UPDATE_TRIP_LOADING_COLLECTION_FAIL: {
      return {
        ...state,
        isUpdateLoadingCollectionSuccess: false
      };
    }
    

    case actionTypes.CANCEL_ORDER: {
      
      return {
        ...state,
        isCancelOrderSuccess: false,
        orderCancellationReview: null
      };
    }

    case actionTypes.CANCEL_ORDER_SUCCESS: {
      
      return {
        ...state,
        isCancelOrderSuccess: (action.data) ? false : true,
        orderCancellationReview: (action.data) ? action.data : null
      };
    }

    case actionTypes.CANCEL_ORDER_FAIL: {
      return {
        ...state,
        isCancelOrderSuccess: false
      };
    }
    

    case actionTypes.GET_ORDER_CANCELLATION_DETAIL: {
      
      return {
        ...state,
        cancellationDetail: {}
      };
    }

    case actionTypes.GET_ORDER_CANCELLATION_DETAIL_SUCCESS: {
      
      return {
        ...state,
        cancellationDetail: action.data
      };
    }

    case actionTypes.GET_ORDER_CANCELLATION_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    
    

    case actionTypes.ORDER_ASSIGN_DRIVER: {
      
      return {
        ...state,
        isOrderAssignedSuccess: false,
        assignConfirmation: null,
        isOrderAssignedFailed: false,
      };
    }

    case actionTypes.ORDER_ASSIGN_DRIVER_SUCCESS: {
      
      return {
        ...state,
        isOrderAssignedSuccess: (action.data) ? false : true,
        assignConfirmation: (action.data && action.data.accept_distance) ? {accept_distance: true, msg: action.data.msg, params: action.params} : null,
      };
    }

    case actionTypes.ORDER_ASSIGN_DRIVER_FAIL: {
      return {
        ...state,
        isOrderAssignedFailed: true,
      };
    }

    
    /* ===== ORDER INVOICE  ===== */  
    case actionTypes.BILLING_LIST: {
      let billingList = cloneDeep(state.billingList);
      billingList.exportSuccess = false

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        billingList.offset = action.data.offset;
        billingList.limit = action.data.limit;
      }

      return {
        ...state,
        billingList
      };
    }

    case actionTypes.BILLING_LIST_SUCCESS: {
      let billingList = cloneDeep(state.billingList);

      if(!action.data.data.export)
      {
        billingList.exportSuccess = false
        billingList.data = action.data.data;
        billingList.meta = {
          page: 1 + state.billingList.offset / state.billingList.limit,
          pageSize: state.billingList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.billingList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        billingList.exportSuccess = true
      }

      return {
        ...state,
        billingList
      };
    }

    case actionTypes.BILLING_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.ORDER_INVOICE: {
      let orderInvoice = "";
      
      return {
        ...state,
        orderInvoice,
        isInvoiceAmountUpdated: false
      };
    }

    case actionTypes.ORDER_INVOICE_SUCCESS: {
      return {
        ...state,
        orderInvoice: action.data,
        isInvoiceAmountUpdated: action.amount_updated,
      };
    }

    case actionTypes.ORDER_INVOICE_FAIL: {
      return {
        ...state
      };
    }

    
    
    case actionTypes.ORDER_INVOICE_STACK: {
      
      return {
        ...state,
        orderInvoiceStack:[]
      };
    }

    case actionTypes.ORDER_INVOICE_STACK_SUCCESS: {
      return {
        ...state,
        orderInvoiceStack: action.data,
      };
    }

    case actionTypes.ORDER_INVOICE_STACK_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_LOAD: {
      
      return {
        ...state,
        agreement_debit_credit_note: ""
      };
    }

    case actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_LOAD_SUCCESS: {
      
      return {
        ...state,
        agreement_debit_credit_note: action.data
      };
    }

    case actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_LOAD_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_STACK: {
      
      return {
        ...state,
        agreementDebitCreditNoteStack:[]
      };
    }

    case actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_STACK_SUCCESS: {
      return {
        ...state,
        agreementDebitCreditNoteStack: action.data,
      };
    }

    case actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_STACK_FAIL: {
      return {
        ...state
      };
    }


    /* ===== END ORDER INVOICE ===== */

    /* ===== SPECIAL INVOICE  ===== */
    case actionTypes.SPECIAL_INVOICE_LIST: {
      let specialInvoiceList = cloneDeep(state.specialInvoiceList);
      specialInvoiceList.exportSuccess = false

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        specialInvoiceList.offset = action.data.offset;
        specialInvoiceList.limit = action.data.limit;
      }

      return {
        ...state,
        specialInvoiceList
      };
    }

    case actionTypes.SPECIAL_INVOICE_LIST_SUCCESS: {
      let specialInvoiceList = cloneDeep(state.specialInvoiceList);

      if(!action.data.data.export)
      {
        specialInvoiceList.exportSuccess = false
        specialInvoiceList.data = action.data.data;
        specialInvoiceList.meta = {
          page: 1 + state.specialInvoiceList.offset / state.specialInvoiceList.limit,
          pageSize: state.specialInvoiceList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.specialInvoiceList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        specialInvoiceList.exportSuccess = true
      }

      return {
        ...state,
        specialInvoiceList
      };
    }

    case actionTypes.SPECIAL_INVOICE_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.GET_SPECIAL_INVOICE: {
      let specialInvoice = "";
      
      return {
        ...state,
        specialInvoice
      };
    }

    case actionTypes.GET_SPECIAL_INVOICE_SUCCESS: {
      
      return {
        ...state,
        specialInvoice: action.data
      };
    }

    case actionTypes.GET_SPECIAL_INVOICE_FAIL: {
      return {
        ...state
      };
    }

        
    case actionTypes.GET_SPECIAL_INVOICE_STACK: {
      
      return {
        ...state,
        specialInvoiceStack:[]
      };
    }

    case actionTypes.GET_SPECIAL_INVOICE_STACK_SUCCESS: {
      return {
        ...state,
        specialInvoiceStack: action.data,
      };
    }

    case actionTypes.GET_SPECIAL_INVOICE_STACK_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END SPECIAL INVOICE  ===== */


    /* ===== PURCHASE BILL  ===== */
    
    case actionTypes.PURCHASE_BILL_LIST: {
      let purchaseBillList = cloneDeep(state.purchaseBillList);
      purchaseBillList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        purchaseBillList.offset = action.data.offset;
        purchaseBillList.limit = action.data.limit;
      }

      return {
        ...state,
        purchaseBillList
      };
    }

    case actionTypes.PURCHASE_BILL_LIST_SUCCESS: {
      let purchaseBillList = cloneDeep(state.purchaseBillList);
      
      if(!action.data.data.export)
      {
        purchaseBillList.exportSuccess = false
        purchaseBillList.data = action.data.data;
        purchaseBillList.meta = {
          page: 1 + state.purchaseBillList.offset / state.purchaseBillList.limit,
          pageSize: state.purchaseBillList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.purchaseBillList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        purchaseBillList.exportSuccess = true
      }

      return {
        ...state,
        purchaseBillList
      };
    }

    case actionTypes.PURCHASE_BILL_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.PURCHASE_BILL_DUMP_RECYCLING: {
      
      return {
        ...state,
        purchaseBillDumpRecycling: ""
      };
    }

    case actionTypes.PURCHASE_BILL_DUMP_RECYCLING_SUCCESS: {
      
      return {
        ...state,
        purchaseBillDumpRecycling: action.data
      };
    }

    case actionTypes.PURCHASE_BILL_DUMP_RECYCLING_FAIL: {
      return {
        ...state
      };
    }
    
    
        
    case actionTypes.PURCHASE_BILL_DUMP_RECYCLING_STACK: {
      
      return {
        ...state,
        purchaseBillDumpRecyclingStack:[]
      };
    }

    case actionTypes.PURCHASE_BILL_DUMP_RECYCLING_STACK_SUCCESS: {
      return {
        ...state,
        purchaseBillDumpRecyclingStack: action.data,
      };
    }

    case actionTypes.PURCHASE_BILL_DUMP_RECYCLING_STACK_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.PURCHASE_BILL_DUMP_EXPRESS: {
      
      return {
        ...state,
        purchaseBillDumpExpress: ""
      };
    }

    case actionTypes.PURCHASE_BILL_DUMP_EXPRESS_SUCCESS: {
      
      return {
        ...state,
        purchaseBillDumpExpress: action.data
      };
    }

    case actionTypes.PURCHASE_BILL_DUMP_EXPRESS_FAIL: {
      return {
        ...state
      };
    }
    
    
        
    case actionTypes.PURCHASE_BILL_DUMP_EXPRESS_STACK: {
      
      return {
        ...state,
        purchaseBillDumpExpressStack:[]
      };
    }

    case actionTypes.PURCHASE_BILL_DUMP_EXPRESS_STACK_SUCCESS: {
      return {
        ...state,
        purchaseBillDumpExpressStack: action.data,
      };
    }

    case actionTypes.PURCHASE_BILL_DUMP_EXPRESS_STACK_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.PURCHASE_BILL_STATEMENT: {
      
      return {
        ...state,
        purchaseBillStatement: (action.data.exportFile) ? cloneDeep(state.purchaseBillStatement): []
      };
    }

    case actionTypes.PURCHASE_BILL_STATEMENT_SUCCESS: {
      
      return {
        ...state,
        purchaseBillStatement: action.data
      };
    }

    case actionTypes.PURCHASE_BILL_STATEMENT_FAIL: {
      return {
        ...state
      };
    }
    
    /* ===== END PURCHASE BILL ===== */

    /* ===== PURCHASE DEBIT CREDIT NOTE  ===== */    
    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_LIST: {
      let purchaseDebitCreditNoteList = cloneDeep(state.purchaseDebitCreditNoteList);
      purchaseDebitCreditNoteList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        purchaseDebitCreditNoteList.offset = action.data.offset;
        purchaseDebitCreditNoteList.limit = action.data.limit;
      }

      return {
        ...state,
        purchaseDebitCreditNoteList
      };
    }

    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_LIST_SUCCESS: {
      let purchaseDebitCreditNoteList = cloneDeep(state.purchaseDebitCreditNoteList);
      
      if(!action.data.data.export)
      {
        purchaseDebitCreditNoteList.exportSuccess = false
        purchaseDebitCreditNoteList.data = action.data.data;
        purchaseDebitCreditNoteList.meta = {
          page: 1 + state.purchaseDebitCreditNoteList.offset / state.purchaseDebitCreditNoteList.limit,
          pageSize: state.purchaseDebitCreditNoteList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.purchaseDebitCreditNoteList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        purchaseDebitCreditNoteList.exportSuccess = true
      }

      return {
        ...state,
        purchaseDebitCreditNoteList
      };
    }

    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE: {
      
      return {
        ...state,
        purchaseDebitCreditNote: ""
      };
    }

    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_SUCCESS: {
      
      return {
        ...state,
        purchaseDebitCreditNote: action.data
      };
    }

    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_FAIL: {
      return {
        ...state
      };
    }
    
    
        
    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_STACK: {
      
      return {
        ...state,
        purchaseDebitCreditNoteStack:[]
      };
    }

    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_STACK_SUCCESS: {
      return {
        ...state,
        purchaseDebitCreditNoteStack: action.data,
      };
    }

    case actionTypes.PURCHASE_DEBIT_CREDIT_NOTE_STACK_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END PURCHASE DEBIT CREDIT NOTE ===== */


    
    case actionTypes.STORE_RECEIPT_LIST: {
      let storeReceiptList = cloneDeep(state.storeReceiptList);
      storeReceiptList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        storeReceiptList.offset = action.data.offset;
        storeReceiptList.limit = action.data.limit;
      }

      return {
        ...state,
        storeReceiptList
      };
    }

    case actionTypes.STORE_RECEIPT_LIST_SUCCESS: {
      let storeReceiptList = cloneDeep(state.storeReceiptList);
      
      if(!action.data.data.export)
      {
        storeReceiptList.exportSuccess = false
        storeReceiptList.data = action.data.data;
        storeReceiptList.meta = {
          page: 1 + state.storeReceiptList.offset / state.storeReceiptList.limit,
          pageSize: state.storeReceiptList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.storeReceiptList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        storeReceiptList.exportSuccess = true
      }

      return {
        ...state,
        storeReceiptList
      };
    }

    case actionTypes.STORE_RECEIPT_LIST_FAIL: {
      return {
        ...state
      };
    }
    
           
    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_STACK: {
      
      return {
        ...state,
        storeReceiptDumpExpressStack:[]
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_STACK_SUCCESS: {
      return {
        ...state,
        storeReceiptDumpExpressStack: action.data,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_STACK_FAIL: {
      return {
        ...state
      };
    }
    
           
    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_STACK: {
      
      return {
        ...state,
        storeReceiptDumpRecyclingStack:[]
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_STACK_SUCCESS: {
      return {
        ...state,
        storeReceiptDumpRecyclingStack: action.data,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_STACK_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.WASTE_RECEIPT_LIST: {
      let wasteReceiptList = cloneDeep(state.wasteReceiptList);
      wasteReceiptList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        wasteReceiptList.offset = action.data.offset;
        wasteReceiptList.limit = action.data.limit;
      }

      return {
        ...state,
        wasteReceiptList
      };
    }

    case actionTypes.WASTE_RECEIPT_LIST_SUCCESS: {
      let wasteReceiptList = cloneDeep(state.wasteReceiptList);
      
      if(!action.data.data.export)
      {
        wasteReceiptList.exportSuccess = false
        wasteReceiptList.data = action.data.data;
        wasteReceiptList.meta = {
          page: 1 + state.wasteReceiptList.offset / state.wasteReceiptList.limit,
          pageSize: state.wasteReceiptList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.wasteReceiptList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        wasteReceiptList.exportSuccess = true
      }

      return {
        ...state,
        wasteReceiptList
      };
    }

    case actionTypes.WASTE_RECEIPT_LIST_FAIL: {
      return {
        ...state
      };
    }
    
        
    case actionTypes.GET_WASTE_RECEIPT_STACK: {
      
      return {
        ...state,
        wasteReceiptStack:[]
      };
    }

    case actionTypes.GET_WASTE_RECEIPT_STACK_SUCCESS: {
      return {
        ...state,
        wasteReceiptStack: action.data,
      };
    }

    case actionTypes.GET_WASTE_RECEIPT_STACK_FAIL: {
      return {
        ...state
      };
    }

    /* ===== RECYCLABLE SALES INVOICE  ===== */    
    case actionTypes.RECYCLABLE_SALES_INVOICE_LIST: {
      let recyclableSalesInvoiceList = cloneDeep(state.recyclableSalesInvoiceList);
      recyclableSalesInvoiceList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        recyclableSalesInvoiceList.offset = action.data.offset;
        recyclableSalesInvoiceList.limit = action.data.limit;
      }

      return {
        ...state,
        recyclableSalesInvoiceList
      };
    }

    case actionTypes.RECYCLABLE_SALES_INVOICE_LIST_SUCCESS: {
      let recyclableSalesInvoiceList = cloneDeep(state.recyclableSalesInvoiceList);
      
      if(!action.data.data.export)
      {
        recyclableSalesInvoiceList.exportSuccess = false
        recyclableSalesInvoiceList.data = action.data.data;
        recyclableSalesInvoiceList.meta = {
          page: 1 + state.recyclableSalesInvoiceList.offset / state.recyclableSalesInvoiceList.limit,
          pageSize: state.recyclableSalesInvoiceList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.recyclableSalesInvoiceList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        recyclableSalesInvoiceList.exportSuccess = true
      }

      return {
        ...state,
        recyclableSalesInvoiceList
      };
    }

    case actionTypes.RECYCLABLE_SALES_INVOICE_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.RECYCLABLE_SALES_INVOICE: {
      let recyclableSalesInvoice = "";
      
      return {
        ...state,
        recyclableSalesInvoice
      };
    }

    case actionTypes.RECYCLABLE_SALES_INVOICE_SUCCESS: {
      
      return {
        ...state,
        recyclableSalesInvoice: action.data
      };
    }

    case actionTypes.RECYCLABLE_SALES_INVOICE_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.RECYCLABLE_SALES_INVOICE_STACK: {
      
      return {
        ...state,
        recyclableSalesInvoiceStack:[]
      };
    }

    case actionTypes.RECYCLABLE_SALES_INVOICE_STACK_SUCCESS: {
      
      return {
        ...state,
        recyclableSalesInvoiceStack: action.data
      };
    }

    case actionTypes.RECYCLABLE_SALES_INVOICE_STACK_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END RECYCLABLE SALES INVOICE ===== */

    /* ===== BOOKING FEE  ===== */
    
    case actionTypes.BOOKING_FEE_LIST: {
      let bookingFeeList = cloneDeep(state.bookingFeeList);
      bookingFeeList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        bookingFeeList.offset = action.data.offset;
        bookingFeeList.limit = action.data.limit;
      }

      return {
        ...state,
        bookingFeeList
      };
    }

    case actionTypes.BOOKING_FEE_LIST_SUCCESS: {
      let bookingFeeList = cloneDeep(state.bookingFeeList);
      
      if(!action.data.data.export)
      {
        bookingFeeList.exportSuccess = false
        bookingFeeList.data = action.data.data;
        bookingFeeList.meta = {
          page: 1 + state.bookingFeeList.offset / state.bookingFeeList.limit,
          pageSize: state.bookingFeeList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.bookingFeeList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        bookingFeeList.exportSuccess = true
      }

      return {
        ...state,
        bookingFeeList
      };
    }

    case actionTypes.BOOKING_FEE_LIST_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END BOOKING FEE ===== */


    /* ===== PROCESS FEE  ===== */
    
    case actionTypes.PROCESS_FEE_LIST: {
      let processFeeList = cloneDeep(state.processFeeList);
      processFeeList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        processFeeList.offset = action.data.offset;
        processFeeList.limit = action.data.limit;
      }

      return {
        ...state,
        processFeeList
      };
    }

    case actionTypes.PROCESS_FEE_LIST_SUCCESS: {
      let processFeeList = cloneDeep(state.processFeeList);
      
      if(!action.data.data.export)
      {
        processFeeList.exportSuccess = false
        processFeeList.data = action.data.data;
        processFeeList.meta = {
          page: 1 + state.processFeeList.offset / state.processFeeList.limit,
          pageSize: state.processFeeList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.processFeeList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        processFeeList.exportSuccess = true
      }

      return {
        ...state,
        processFeeList
      };
    }

    case actionTypes.PROCESS_FEE_LIST_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END PROCESS FEE ===== */


    /* ===== LANDFILL FEE  ===== */    
    case actionTypes.LANDFILL_FEE_LIST: {
      let landfillFeeList = cloneDeep(state.landfillFeeList);
      landfillFeeList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        landfillFeeList.offset = action.data.offset;
        landfillFeeList.limit = action.data.limit;
      }

      return {
        ...state,
        landfillFeeList
      };
    }

    case actionTypes.LANDFILL_FEE_LIST_SUCCESS: {
      let landfillFeeList = cloneDeep(state.landfillFeeList);
      
      if(!action.data.data.export)
      {
        landfillFeeList.exportSuccess = false
        landfillFeeList.data = action.data.data;
        landfillFeeList.meta = {
          page: 1 + state.landfillFeeList.offset / state.landfillFeeList.limit,
          pageSize: state.landfillFeeList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.landfillFeeList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        landfillFeeList.exportSuccess = true
      }

      return {
        ...state,
        landfillFeeList
      };
    }

    case actionTypes.LANDFILL_FEE_LIST_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END LANDFILL FEE ===== */


    /* ===== USER LOADING FEE LIST  ===== */
    case actionTypes.USER_LOADING_FEE_LIST: {
      let userLoadingFeeList = cloneDeep(state.userLoadingFeeList);
      userLoadingFeeList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        userLoadingFeeList.offset = action.data.offset;
        userLoadingFeeList.limit = action.data.limit;
      }

      return {
        ...state,
        userLoadingFeeList
      };
    }

    case actionTypes.USER_LOADING_FEE_LIST_SUCCESS: {
      let userLoadingFeeList = cloneDeep(state.userLoadingFeeList);
      
      if(!action.data.data.export)
      {
        userLoadingFeeList.exportSuccess = false
        userLoadingFeeList.data = action.data.data;
        userLoadingFeeList.meta = {
          page: 1 + state.userLoadingFeeList.offset / state.userLoadingFeeList.limit,
          pageSize: state.userLoadingFeeList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.userLoadingFeeList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        userLoadingFeeList.exportSuccess = true
      }

      return {
        ...state,
        userLoadingFeeList
      };
    }

    case actionTypes.USER_LOADING_FEE_LIST_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END USER LOADING FEE LIST ===== */


    /* ===== DRIVER LOADING PAYMENT LIST  ===== */    
    case actionTypes.DRIVER_LOADING_PAYMENT_LIST: {
      let driverLoadingPaymentList = cloneDeep(state.driverLoadingPaymentList);
      driverLoadingPaymentList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverLoadingPaymentList.offset = action.data.offset;
        driverLoadingPaymentList.limit = action.data.limit;
      }

      return {
        ...state,
        driverLoadingPaymentList
      };
    }

    case actionTypes.DRIVER_LOADING_PAYMENT_LIST_SUCCESS: {
      let driverLoadingPaymentList = cloneDeep(state.driverLoadingPaymentList);
      
      if(!action.data.data.export)
      {
        driverLoadingPaymentList.exportSuccess = false
        driverLoadingPaymentList.data = action.data.data;
        driverLoadingPaymentList.meta = {
          page: 1 + state.driverLoadingPaymentList.offset / state.driverLoadingPaymentList.limit,
          pageSize: state.driverLoadingPaymentList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.driverLoadingPaymentList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        driverLoadingPaymentList.exportSuccess = true
      }

      return {
        ...state,
        driverLoadingPaymentList
      };
    }

    case actionTypes.DRIVER_LOADING_PAYMENT_LIST_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END DRIVER LOADING PAYMENT LIST ===== */


    /* ===== SORTING FEE LIST  ===== */    
    case actionTypes.SORTING_FEE_LIST: {
      let sortingFeeList = cloneDeep(state.sortingFeeList);
      sortingFeeList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        sortingFeeList.offset = action.data.offset;
        sortingFeeList.limit = action.data.limit;
      }

      return {
        ...state,
        sortingFeeList
      };
    }

    case actionTypes.SORTING_FEE_LIST_SUCCESS: {
      let sortingFeeList = cloneDeep(state.sortingFeeList);
      
      if(!action.data.data.export)
      {
        sortingFeeList.exportSuccess = false
        sortingFeeList.data = action.data.data;
        sortingFeeList.meta = {
          page: 1 + state.sortingFeeList.offset / state.sortingFeeList.limit,
          pageSize: state.sortingFeeList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.sortingFeeList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        sortingFeeList.exportSuccess = true
      }

      return {
        ...state,
        sortingFeeList
      };
    }

    case actionTypes.SORTING_FEE_LIST_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END SORTING FEE LIST ===== */


    /* ===== OVERSIZE FEE  ===== */    
    case actionTypes.OVERSIZE_FEE_LIST: {
      let oversizeFeeList = cloneDeep(state.oversizeFeeList);
      oversizeFeeList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        oversizeFeeList.offset = action.data.offset;
        oversizeFeeList.limit = action.data.limit;
      }

      return {
        ...state,
        oversizeFeeList
      };
    }

    case actionTypes.OVERSIZE_FEE_LIST_SUCCESS: {
      let oversizeFeeList = cloneDeep(state.oversizeFeeList);
      
      if(!action.data.data.export)
      {
        oversizeFeeList.exportSuccess = false
        oversizeFeeList.data = action.data.data;
        oversizeFeeList.meta = {
          page: 1 + state.oversizeFeeList.offset / state.oversizeFeeList.limit,
          pageSize: state.oversizeFeeList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.oversizeFeeList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        oversizeFeeList.exportSuccess = true
      }

      return {
        ...state,
        oversizeFeeList
      };
    }

    case actionTypes.OVERSIZE_FEE_LIST_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END OVERSIZE FEE ===== */
    case actionTypes.RECYCLABLE_PURCHASE_SUMMARY_LIST: {
      let recyclablePurchaseList = cloneDeep(state.recyclablePurchaseList);
      recyclablePurchaseList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        recyclablePurchaseList.offset = action.data.offset;
        recyclablePurchaseList.limit = action.data.limit;
      }

      return {
        ...state,
        recyclablePurchaseList
      };
    }

    case actionTypes.RECYCLABLE_PURCHASE_SUMMARY_LIST_SUCCESS: {
      let recyclablePurchaseList = cloneDeep(state.recyclablePurchaseList);
      
      if(!action.data.data.export)
      {
        recyclablePurchaseList.exportSuccess = false
        recyclablePurchaseList.data = action.data.data;
        recyclablePurchaseList.meta = {
          page: 1 + state.recyclablePurchaseList.offset / state.recyclablePurchaseList.limit,
          pageSize: state.recyclablePurchaseList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.recyclablePurchaseList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        recyclablePurchaseList.exportSuccess = true
      }
      
      return {
        ...state,
        recyclablePurchaseList
      };
    }

    case actionTypes.RECYCLABLE_PURCHASE_SUMMARY_LIST_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.ORDER_TRACK_REPORT: {
      let orderTrackReport = cloneDeep(state.orderTrackReport);
      
      return {
        ...state,
        orderTrackReport
      };
    }

    case actionTypes.ORDER_TRACK_REPORT_SUCCESS: {
      
      return {
        ...state,
        orderTrackReport: action.data
      };
    }

    case actionTypes.ORDER_TRACK_REPORT_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.ORDER_TRIP_STORE_RECEIPT: {
      
      return {
        ...state,
        orderTripStoreReceipt: ""
      };
    }

    case actionTypes.ORDER_TRIP_STORE_RECEIPT_SUCCESS: {
      
      return {
        ...state,
        orderTripStoreReceipt: action.data
      };
    }

    case actionTypes.ORDER_TRIP_STORE_RECEIPT_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.ORDER_TRIP_WASTE_RECEIPT: {
      
      return {
        ...state,
        orderTripWasteReceipt: ""
      };
    }

    case actionTypes.ORDER_TRIP_WASTE_RECEIPT_SUCCESS: {
      
      return {
        ...state,
        orderTripWasteReceipt: action.data
      };
    }

    case actionTypes.ORDER_TRIP_WASTE_RECEIPT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.ORDER_WASTE_SUMMARY: {
      let orderWasteSummary = cloneDeep(state.orderWasteSummary);
      
      return {
        ...state,
        orderWasteSummary
      };
    }

    case actionTypes.ORDER_WASTE_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        orderWasteSummary: action.data
      };
    }

    case actionTypes.ORDER_WASTE_SUMMARY_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.LOAD_TRANSACTION_LIST: {
      let transactionList = cloneDeep(state.transactionList);
      transactionList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        transactionList.offset = action.data.offset;
        transactionList.limit = action.data.limit;
      }

      return {
        ...state,
        transactionList
      };
    }

    case actionTypes.LOAD_TRANSACTION_LIST_SUCCESS: {
      let transactionList = cloneDeep(state.transactionList);
      
      if(!action.data.data.export)
      {
        transactionList.exportSuccess = false
        transactionList.data = action.data.data;
        transactionList.meta = {
          page: 1 + state.transactionList.offset / state.transactionList.limit,
          pageSize: state.transactionList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.transactionList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        transactionList.exportSuccess = true
      }
      
      return {
        ...state,
        transactionList
      };
     
    }

    case actionTypes.LOAD_TRANSACTION_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.LOAD_DUITNOW_TRANSACTION_LIST: {
      let duitNowTransactionList = cloneDeep(state.duitNowTransactionList);
      duitNowTransactionList.exportSuccess = false
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        duitNowTransactionList.offset = action.data.offset;
        duitNowTransactionList.limit = action.data.limit;
      }

      return {
        ...state,
        duitNowTransactionList
      };
    }

    case actionTypes.LOAD_DUITNOW_TRANSACTION_LIST_SUCCESS: {
      let duitNowTransactionList = cloneDeep(state.duitNowTransactionList);
      
      if(!action.data.data.export)
      {
        duitNowTransactionList.exportSuccess = false
        duitNowTransactionList.data = action.data.data;
        duitNowTransactionList.meta = {
          page: 1 + state.duitNowTransactionList.offset / state.duitNowTransactionList.limit,
          pageSize: state.duitNowTransactionList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.duitNowTransactionList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        duitNowTransactionList.exportSuccess = true
      }
      
      return {
        ...state,
        duitNowTransactionList
      };
     
    }

    case actionTypes.LOAD_DUITNOW_TRANSACTION_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_ORDER_SUMMARY: {
      let orderSummary = {};
      
      return {
        ...state,
        orderSummary
      };
    }

    case actionTypes.LOAD_ORDER_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        orderSummary: action.data
      };
    }

    case actionTypes.LOAD_ORDER_SUMMARY_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_ORDER_SUMMARY_CHART: {
      
      return {
        ...state,
        orderSummaryChart: {}
      };
    }

    case actionTypes.LOAD_ORDER_SUMMARY_CHART_SUCCESS: {
      
      return {
        ...state,
        orderSummaryChart: action.data
      };
    }

    case actionTypes.LOAD_ORDER_SUMMARY_CHART_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_ORDER_SALE_SUMMARY: {
      
      return {
        ...state,
        orderSaleSummary: {}
      };
    }

    case actionTypes.LOAD_ORDER_SALE_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        orderSaleSummary: action.data
      };
    }

    case actionTypes.LOAD_ORDER_SALE_SUMMARY_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.LOAD_DRIVER_INCENTIVE: {
      let orderIncentive = cloneDeep(state.orderIncentive);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        orderIncentive.offset = action.data.offset;
        orderIncentive.limit = action.data.limit;
      }

      return {
        ...state,
        orderIncentive
      };
    }

    case actionTypes.LOAD_DRIVER_INCENTIVE_SUCCESS: {
      let orderIncentive = cloneDeep(state.orderIncentive);
      orderIncentive.data = action.data.data;
      orderIncentive.meta = {
        page: 1 + state.orderIncentive.offset / state.orderIncentive.limit,
        pageSize: state.orderIncentive.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.orderIncentive.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        orderIncentive
      };
    }

    case actionTypes.LOAD_DRIVER_INCENTIVE_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.LOAD_USER_ORDER_HISTORY: {
      let orderHistory = cloneDeep(state.orderHistory);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        orderHistory.offset = action.data.offset;
        orderHistory.limit = action.data.limit;
      }

      return {
        ...state,
        orderHistory
      };
    }

    case actionTypes.LOAD_USER_ORDER_HISTORY_SUCCESS: {
      let orderHistory = cloneDeep(state.orderHistory);
      orderHistory.data = action.data.data;
      orderHistory.meta = {
        page: 1 + state.orderHistory.offset / state.orderHistory.limit,
        pageSize: state.orderHistory.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.orderHistory.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        orderHistory
      };
    }

    case actionTypes.LOAD_USER_ORDER_HISTORY_FAIL: {
      return {
        ...state
      };
    }

    
    /* =====REPORT ===== */
    case actionTypes.MONTHLY_WASTE_SUMMARY: {
      let monthlyWasteSummaryReport = cloneDeep(state.monthlyWasteSummaryReport);
      
      return {
        ...state,
        monthlyWasteSummaryReport
      };
    }

    case actionTypes.MONTHLY_WASTE_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        monthlyWasteSummaryReport: action.data
      };
    }

    case actionTypes.MONTHLY_WASTE_SUMMARY_FAIL: {
      return {
        ...state
      };
    }

    

    case actionTypes.MONTHLY_WASTE_SUMMARY_LOAD: {
      let monthlyWasteSummary = cloneDeep(state.monthlyWasteSummary);
      
      return {
        ...state,
        monthlyWasteSummary
      };
    }

    case actionTypes.MONTHLY_WASTE_SUMMARY_LOAD_SUCCESS: {
      
      return {
        ...state,
        monthlyWasteSummary: action.data
      };
    }

    case actionTypes.MONTHLY_WASTE_SUMMARY_LOAD_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.MONTHLY_LANDFILL_SUMMARY: {
      let monthlyLandfillSummaryReport = cloneDeep(state.monthlyLandfillSummaryReport);
      
      return {
        ...state,
        monthlyLandfillSummaryReport
      };
    }

    case actionTypes.MONTHLY_LANDFILL_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        monthlyLandfillSummaryReport: action.data
      };
    }

    case actionTypes.MONTHLY_LANDFILL_SUMMARY_FAIL: {
      return {
        ...state
      };
    }

    

    case actionTypes.MONTHLY_LANDFILL_SUMMARY_LOAD: {
      let monthlyLandfillSummary = cloneDeep(state.monthlyLandfillSummary);
      
      return {
        ...state,
        monthlyLandfillSummary
      };
    }

    case actionTypes.MONTHLY_LANDFILL_SUMMARY_LOAD_SUCCESS: {
      
      return {
        ...state,
        monthlyLandfillSummary: action.data
      };
    }

    case actionTypes.MONTHLY_LANDFILL_SUMMARY_LOAD_FAIL: {
      return {
        ...state
      };
    }


    
    case actionTypes.MONTHLY_STOCK_SUMMARY: {
      let monthlyStockSummaryReport = cloneDeep(state.monthlyStockSummaryReport);
      
      return {
        ...state,
        monthlyStockSummaryReport
      };
    }

    case actionTypes.MONTHLY_STOCK_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        monthlyStockSummaryReport: action.data
      };
    }

    case actionTypes.MONTHLY_STOCK_SUMMARY_FAIL: {
      return {
        ...state
      };
    }

    

    case actionTypes.MONTHLY_STOCK_SUMMARY_LOAD: {
      let monthlyStockSummary = cloneDeep(state.monthlyStockSummary);
      
      return {
        ...state,
        monthlyStockSummary
      };
    }

    case actionTypes.MONTHLY_STOCK_SUMMARY_LOAD_SUCCESS: {
      
      return {
        ...state,
        monthlyStockSummary: action.data
      };
    }

    case actionTypes.MONTHLY_STOCK_SUMMARY_LOAD_FAIL: {
      return {
        ...state
      };
    }



    
    case actionTypes.MONTHLY_WASTE_STOCK_SUMMARY: {
      let monthlyWasteStockSummaryReport = cloneDeep(state.monthlyWasteStockSummaryReport);
      
      return {
        ...state,
        monthlyWasteStockSummaryReport
      };
    }

    case actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        monthlyWasteStockSummaryReport: action.data
      };
    }

    case actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_FAIL: {
      return {
        ...state
      };
    }

    

    case actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_LOAD: {
      let monthlyWasteStockSummary = cloneDeep(state.monthlyWasteStockSummary);
      
      return {
        ...state,
        monthlyWasteStockSummary
      };
    }

    case actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_LOAD_SUCCESS: {
      
      return {
        ...state,
        monthlyWasteStockSummary: action.data
      };
    }

    case actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_LOAD_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.MONTHLY_DRIVER_TRIP_SUMMARY: {
      
      return {
        ...state,
        monthlyDriverTripSummary: {}
      };
    }

    case actionTypes.MONTHLY_DRIVER_TRIP_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        monthlyDriverTripSummary: (action.data.data && action.data.data.export) ? cloneDeep(state.monthlyDriverTripSummary_backup) : action.data.data,
        monthlyDriverTripSummary_backup: (action.data.data && action.data.data.export) ? cloneDeep(state.monthlyDriverTripSummary_backup) : action.data.data,
      };
    }

    case actionTypes.MONTHLY_DRIVER_TRIP_SUMMARY_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.RESET_DRIVER_TRIP_SUMMARY_SUCCESS:
      return {
        ...state,
        monthlyDriverTripSummary: {},
        monthlyDriverTripSummary_backup: {},
      };


  
  
    case actionTypes.ORDER_DISTANCE_SUMMARY: {
    
      return {
        ...state,
        orderDistanceSummary: []
      };
    }

    case actionTypes.ORDER_DISTANCE_SUMMARY_SUCCESS: {
    
      return {
        ...state,
        orderDistanceSummary: (action.data.data && action.data.data.export) ? cloneDeep(state.orderDistanceSummary_backup) : action.data.data,
        orderDistanceSummary_backup: (action.data.data && action.data.data.export) ? cloneDeep(state.orderDistanceSummary_backup) : action.data.data,
      };
    }

    case actionTypes.ORDER_DISTANCE_SUMMARY_FAIL: {
      return {
        ...state
      };
    }
      
    
    

    case actionTypes.LOAD_REFERRAL_COMMISSION_SUMMARY: {
      let referralCommissionSummary = cloneDeep(state.referralCommissionSummary);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        referralCommissionSummary.offset = action.data.offset;
        referralCommissionSummary.limit = action.data.limit;
      }

      return {
        ...state,
        referralCommissionSummary,
        isProcessLoadReferralCommissionSummary: true,
      };
    }

    case actionTypes.LOAD_REFERRAL_COMMISSION_SUMMARY_SUCCESS: {
      let referralCommissionSummary = cloneDeep(state.referralCommissionSummary);
      referralCommissionSummary.data = (action.data.data && action.data.data.export) ? cloneDeep(state.referralCommissionSummary_backup) : action.data.data;
      referralCommissionSummary.meta = {
        page: 1 + state.referralCommissionSummary.offset / state.referralCommissionSummary.limit,
        pageSize: state.referralCommissionSummary.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.referralCommissionSummary.limit
        ),
        total: action.data.recordsTotal
      };

      referralCommissionSummary.total_weight = action.data.total_weight
      referralCommissionSummary.total_amount = action.data.total_amount
      referralCommissionSummary.total_level = action.data.total_level
      
      return {
        ...state,
        isProcessLoadReferralCommissionSummary: false,
        referralCommissionSummary,
        referralCommissionSummary_backup: (action.data.data && action.data.data.export) ? cloneDeep(state.referralCommissionSummary_backup) : action.data.data,
      };
    }

    case actionTypes.LOAD_REFERRAL_COMMISSION_SUMMARY_FAIL: {
      return {
        ...state,
        isProcessLoadReferralCommissionSummary: false,
      };
    }
    /* ===== END REPORT ===== */

    case actionTypes.LOAD_ADJUSTMENT_AGREEMENT_LIST: {
      let adjAgreementList = cloneDeep(state.adjAgreementList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        adjAgreementList.offset = action.data.offset;
        adjAgreementList.limit = action.data.limit;
      }

      return {
        ...state,
        adjAgreementList
      };
    }

    case actionTypes.LOAD_ADJUSTMENT_AGREEMENT_LIST_SUCCESS: {
      let adjAgreementList = cloneDeep(state.adjAgreementList);
      adjAgreementList.data = action.data.data;
      adjAgreementList.meta = {
        page: 1 + state.adjAgreementList.offset / state.adjAgreementList.limit,
        pageSize: state.adjAgreementList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.adjAgreementList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        adjAgreementList
      };
    }

    case actionTypes.LOAD_ADJUSTMENT_AGREEMENT_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.LOAD_ADJUSTMENT_AGREEMENT_DETAIL: {
      
      return {
        ...state,
        adjAgreementDetail: {}
      };
    }

    case actionTypes.LOAD_ADJUSTMENT_AGREEMENT_DETAIL_SUCCESS: {
      
      return {
        ...state,
        adjAgreementDetail: action.data
      };
    }

    case actionTypes.LOAD_ADJUSTMENT_AGREEMENT_DETAIL_FAIL: {

      return {
        ...state
      };
    }


    case actionTypes.AGREEMENT_PROCESS_REFUND: {
      
      return {
        ...state,
        isAgreementRefundSuccess: false
      };
    }

    case actionTypes.AGREEMENT_PROCESS_REFUND_SUCCESS: {
      
      return {
        ...state,
        isAgreementRefundSuccess: true
      };
    }

    case actionTypes.AGREEMENT_PROCESS_REFUND_FAIL: {

      return {
        ...state,
        isAgreementRefundSuccess: false
      };
    }


    

    case actionTypes.LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST: {
     
      return {
        ...state,
        adjAffectedOrder:[]
      };
    }

    case actionTypes.LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST_SUCCESS: {
     
      return {
        ...state,
        adjAffectedOrder: (action.data.data && action.data.data.export) ? cloneDeep(state.adjAffectedOrder_backup) : action.data,
        adjAffectedOrder_backup: action.data
      };
    }

    case actionTypes.LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST_FAIL: {
      return {
        ...state
      };
    }
    



    case actionTypes.CANCEL_ORDER_REFUND_LIST: {
      let cancelOrderRefundList = cloneDeep(state.cancelOrderRefundList);
      cancelOrderRefundList.exportSuccess = false

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        cancelOrderRefundList.offset = action.data.offset;
        cancelOrderRefundList.limit = action.data.limit;
      }

      return {
        ...state,
        cancelOrderRefundList
      };
    }

    case actionTypes.CANCEL_ORDER_REFUND_LIST_SUCCESS: {
     
      let cancelOrderRefundList = cloneDeep(state.cancelOrderRefundList);

      if(!action.data.data.export)
      {
      
        cancelOrderRefundList.exportSuccess = false
        cancelOrderRefundList.data = action.data.data;
        cancelOrderRefundList.meta = {
          page: 1 + state.cancelOrderRefundList.offset / state.cancelOrderRefundList.limit,
          pageSize: state.cancelOrderRefundList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.cancelOrderRefundList.limit
          ),
          total: action.data.recordsTotal
        };

      }else{
        cancelOrderRefundList.exportSuccess = true
      }

      return {
        ...state,
        cancelOrderRefundList
      };
    }

    case actionTypes.CANCEL_ORDER_REFUND_LIST_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.CANCEL_ORDER_REFUND_DETAIL: {
     
      return {
        ...state,
        cancelOrderRefundDetail:{}
      };
    }

    case actionTypes.CANCEL_ORDER_REFUND_DETAIL_SUCCESS: {
     
      return {
        ...state,
        cancelOrderRefundDetail: action.data
      };
    }

    case actionTypes.CANCEL_ORDER_REFUND_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.CANCEL_ORDER_PROCESS_REFUND: {
     
      return {
        ...state,
        isProcessCancelOrderRefund: true,
        cancelOrderRefundedSuccess: false
      };
    }

    case actionTypes.CANCEL_ORDER_PROCESS_REFUND_SUCCESS: {
     
      return {
        ...state,
        isProcessCancelOrderRefund: false,
        cancelOrderRefundedSuccess: true
      };
    }

    case actionTypes.CANCEL_ORDER_PROCESS_REFUND_FAIL: {
      return {
        ...state,
        isProcessCancelOrderRefund: false,
        cancelOrderRefundedSuccess: false
      };
    }
    


    /* ===== REASSIGN TRIP ===== */
    case actionTypes.REASSIGN_TRIP: {
     
      return {
        ...state,
        reassignTripSuccess: false,
        reassignTripConfirm: null
      };
    }

    case actionTypes.REASSIGN_TRIP_SUCCESS: {
     
      return {
        ...state,
        reassignTripSuccess: (action.data) ? false : true,
        reassignTripConfirm: (action.data) ? action.data : null
      };
    }

    case actionTypes.REASSIGN_TRIP_FAIL: {
      return {
        ...state,
        reassignTripSuccess: false
      };
    }
    /* ===== END REASSIGN TRIP ===== */
    
    /* ===== MAKE IT TODAY TRIP ===== */
    case actionTypes.MAKE_IT_TODAY_TRIP: {
     
      return {
        ...state,
        tripMakeItTodaySuccess: false
      };
    }

    case actionTypes.MAKE_IT_TODAY_TRIP_SUCCESS: {
     
      return {
        ...state,
        tripMakeItTodaySuccess: true
      };
    }

    case actionTypes.MAKE_IT_TODAY_TRIP_FAIL: {
      return {
        ...state,
        tripMakeItTodaySuccess: false
      };
    }
    /* ===== END MAKE IT TODAY TRIP ===== */
    

    /* ===== COLLECTION REQUEST ===== */   
    case actionTypes.COLLECTION_REQUEST_COUNT: {
     
      return {
        ...state,
        collectionRequestCount: {}
      };
    }

    case actionTypes.COLLECTION_REQUEST_COUNT_SUCCESS: {
     
      return {
        ...state,
        collectionRequestCount: action.data
      };
    }

    case actionTypes.COLLECTION_REQUEST_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.COLLECTION_REQUEST_LOAD: {
      let collectionRequestList = cloneDeep(state.collectionRequestList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        collectionRequestList.offset = action.data.offset;
        collectionRequestList.limit = action.data.limit;
      }

      return {
        ...state,
        collectionRequestList
      };
    }

    case actionTypes.COLLECTION_REQUEST_LOAD_SUCCESS: {
     
      let collectionRequestList = cloneDeep(state.collectionRequestList);
      collectionRequestList.data = action.data.data;
      collectionRequestList.meta = {
        page: 1 + state.collectionRequestList.offset / state.collectionRequestList.limit,
        pageSize: state.collectionRequestList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.collectionRequestList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        collectionRequestList
      };
    }

    case actionTypes.COLLECTION_REQUEST_LOAD_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.COLLECTION_REQUEST_APPROVE: {
     
      return {
        ...state,
        isCollectionRequestApproveSuccess: false
      };
    }

    case actionTypes.COLLECTION_REQUEST_APPROVE_SUCCESS: {
     
      return {
        ...state,
        isCollectionRequestApproveSuccess: true
      };
    }

    case actionTypes.COLLECTION_REQUEST_APPROVE_FAIL: {
      return {
        ...state,
        isCollectionRequestApproveSuccess: false
      };
    }
    

    case actionTypes.COLLECTION_REQUEST_REJECT: {
     
      return {
        ...state,
        isCollectionRequestRejectSuccess: false
      };
    }

    case actionTypes.COLLECTION_REQUEST_REJECT_SUCCESS: {
     
      return {
        ...state,
        isCollectionRequestRejectSuccess: true
      };
    }

    case actionTypes.COLLECTION_REQUEST_REJECT_FAIL: {
      return {
        ...state,
        isCollectionRequestRejectSuccess: false
      };
    }
    /* ===== END COLLECTION REQUEST ===== */
    
    /* ===== FILLING_EXTENSION REQUEST ===== */   
    case actionTypes.FILLING_EXTENSION_REQUEST_COUNT: {
     
      return {
        ...state,
        fillingExtensionRequestCount: {}
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_COUNT_SUCCESS: {
     
      return {
        ...state,
        fillingExtensionRequestCount: action.data
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_LOAD: {
      let fillingExtensionRequestList = cloneDeep(state.fillingExtensionRequestList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        fillingExtensionRequestList.offset = action.data.offset;
        fillingExtensionRequestList.limit = action.data.limit;
      }

      return {
        ...state,
        fillingExtensionRequestList
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_LOAD_SUCCESS: {
     
      let fillingExtensionRequestList = cloneDeep(state.fillingExtensionRequestList);
      fillingExtensionRequestList.data = action.data.data;
      fillingExtensionRequestList.meta = {
        page: 1 + state.fillingExtensionRequestList.offset / state.fillingExtensionRequestList.limit,
        pageSize: state.fillingExtensionRequestList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.fillingExtensionRequestList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        fillingExtensionRequestList
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_LOAD_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_DETAIL: {
     
      return {
        ...state,
        fillingExtensionRequestDetail: {}
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_DETAIL_SUCCESS: {
     
      return {
        ...state,
        fillingExtensionRequestDetail: action.data
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.FILLING_EXTENSION_REQUEST_APPROVE: {
     
      return {
        ...state,
        isFillingExtensionRequestApproveSuccess: false
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_APPROVE_SUCCESS: {
     
      return {
        ...state,
        isFillingExtensionRequestApproveSuccess: true
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_APPROVE_FAIL: {
      return {
        ...state,
        isFillingExtensionRequestApproveSuccess: false
      };
    }
    

    case actionTypes.FILLING_EXTENSION_REQUEST_REJECT: {
     
      return {
        ...state,
        isFillingExtensionRequestRejectSuccess: false
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_REJECT_SUCCESS: {
     
      return {
        ...state,
        isFillingExtensionRequestRejectSuccess: true
      };
    }

    case actionTypes.FILLING_EXTENSION_REQUEST_REJECT_FAIL: {
      return {
        ...state,
        isFillingExtensionRequestRejectSuccess: false
      };
    }
    /* ===== END FILLING_EXTENSION REQUEST ===== */
    
    
    /* ===== MADE_IT_TODAY REQUEST ===== */   
    case actionTypes.MADE_IT_TODAY_REQUEST_COUNT: {
     
      return {
        ...state,
        madeItTodayRequestCount: {}
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_COUNT_SUCCESS: {
     
      return {
        ...state,
        madeItTodayRequestCount: action.data
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_LOAD: {
      let madeItTodayRequestList = cloneDeep(state.madeItTodayRequestList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        madeItTodayRequestList.offset = action.data.offset;
        madeItTodayRequestList.limit = action.data.limit;
      }

      return {
        ...state,
        madeItTodayRequestList
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_LOAD_SUCCESS: {
     
      let madeItTodayRequestList = cloneDeep(state.madeItTodayRequestList);
      madeItTodayRequestList.data = action.data.data;
      madeItTodayRequestList.meta = {
        page: 1 + state.madeItTodayRequestList.offset / state.madeItTodayRequestList.limit,
        pageSize: state.madeItTodayRequestList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.madeItTodayRequestList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        madeItTodayRequestList
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_LOAD_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_DETAIL: {
     
      return {
        ...state,
        madeItTodayRequestDetail: {}
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_DETAIL_SUCCESS: {
     
      return {
        ...state,
        madeItTodayRequestDetail: action.data
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.MADE_IT_TODAY_REQUEST_APPROVE: {
     
      return {
        ...state,
        isMadeItTodayRequestApproveSuccess: false
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_APPROVE_SUCCESS: {
     
      return {
        ...state,
        isMadeItTodayRequestApproveSuccess: true
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_APPROVE_FAIL: {
      return {
        ...state,
        isMadeItTodayRequestApproveSuccess: false
      };
    }
    

    case actionTypes.MADE_IT_TODAY_REQUEST_REJECT: {
     
      return {
        ...state,
        isMadeItTodayRequestRejectSuccess: false
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_REJECT_SUCCESS: {
     
      return {
        ...state,
        isMadeItTodayRequestRejectSuccess: true
      };
    }

    case actionTypes.MADE_IT_TODAY_REQUEST_REJECT_FAIL: {
      return {
        ...state,
        isMadeItTodayRequestRejectSuccess: false
      };
    }
    /* ===== END MADE_IT_TODAY REQUEST ===== */
    
    /* ===== ADD_ON_TRIP REQUEST ===== */   
    case actionTypes.ADD_ON_TRIP_REQUEST_COUNT: {
     
      return {
        ...state,
        addonTripRequestCount: {}
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_COUNT_SUCCESS: {
     
      return {
        ...state,
        addonTripRequestCount: action.data
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_LOAD: {
      let addonTripRequestList = cloneDeep(state.addonTripRequestList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        addonTripRequestList.offset = action.data.offset;
        addonTripRequestList.limit = action.data.limit;
      }

      return {
        ...state,
        addonTripRequestList
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_LOAD_SUCCESS: {
     
      let addonTripRequestList = cloneDeep(state.addonTripRequestList);
      addonTripRequestList.data = action.data.data;
      addonTripRequestList.meta = {
        page: 1 + state.addonTripRequestList.offset / state.addonTripRequestList.limit,
        pageSize: state.addonTripRequestList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.addonTripRequestList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        addonTripRequestList
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_LOAD_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_DETAIL: {
     
      return {
        ...state,
        addonTripRequestDetail: {}
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_DETAIL_SUCCESS: {
     
      return {
        ...state,
        addonTripRequestDetail: action.data
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.ADD_ON_TRIP_REQUEST_APPROVE: {
     
      return {
        ...state,
        isAddonTripRequestApproveSuccess: false
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_APPROVE_SUCCESS: {
     
      return {
        ...state,
        isAddonTripRequestApproveSuccess: true
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_APPROVE_FAIL: {
      return {
        ...state,
        isAddonTripRequestApproveSuccess: false
      };
    }
    

    case actionTypes.ADD_ON_TRIP_REQUEST_REJECT: {
     
      return {
        ...state,
        isAddonTripRequestRejectSuccess: false
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_REJECT_SUCCESS: {
     
      return {
        ...state,
        isAddonTripRequestRejectSuccess: true
      };
    }

    case actionTypes.ADD_ON_TRIP_REQUEST_REJECT_FAIL: {
      return {
        ...state,
        isAddonTripRequestRejectSuccess: false
      };
    }
    /* ===== END ADD_ON_TRIP REQUEST ===== */

    case actionTypes.FIND_DRIVER_SIMULATE: {
     
      return {
        ...state,
        findDriverSimulateList: {order: {}, driver:[]}
      };
    }

    case actionTypes.FIND_DRIVER_SIMULATE_SUCCESS: {
      
      return {
        ...state,
        findDriverSimulateList: {order: (action.data.order) ? action.data.order : {}, driver: (action.data.driver) ? action.data.driver : []}
      };
    }

    case actionTypes.FIND_DRIVER_SIMULATE_FAIL: {
      return {
        ...state
      };
    }
    
    /* ===== GRADING  ===== */
    case actionTypes.LOAD_GRADING_LIST: {
      let gradingList = cloneDeep(state.gradingList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        gradingList.offset = action.data.offset;
        gradingList.limit = action.data.limit;
      }

      return {
        ...state,
        gradingList
      };
    }

    case actionTypes.LOAD_GRADING_LIST_SUCCESS: {
     
      let gradingList = cloneDeep(state.gradingList);
      gradingList.data = action.data.data;
      gradingList.meta = {
        page: 1 + state.gradingList.offset / state.gradingList.limit,
        pageSize: state.gradingList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.gradingList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        gradingList
      };
    }

    case actionTypes.LOAD_GRADING_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_GRADING_COUNT: {
     
      return {
        ...state,
        gradingCount: {}
      };
    }

    case actionTypes.LOAD_GRADING_COUNT_SUCCESS: {
      
      return {
        ...state,
        gradingCount: action.data
      };
    }

    case actionTypes.LOAD_GRADING_COUNT_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.LOAD_PENDING_GRADING_LIST: {
      let pendingGradingList = cloneDeep(state.pendingGradingList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        pendingGradingList.offset = action.data.offset;
        pendingGradingList.limit = action.data.limit;
      }

      return {
        ...state,
        pendingGradingList
      };
    }

    case actionTypes.LOAD_PENDING_GRADING_LIST_SUCCESS: {
     
      let pendingGradingList = cloneDeep(state.pendingGradingList);
      pendingGradingList.data = action.data.data;
      pendingGradingList.meta = {
        page: 1 + state.pendingGradingList.offset / state.pendingGradingList.limit,
        pageSize: state.pendingGradingList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.pendingGradingList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        pendingGradingList
      };
    }

    case actionTypes.LOAD_PENDING_GRADING_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_PENDING_GRADING_COUNT: {
     
      return {
        ...state,
        pendingGradingCount: {}
      };
    }

    case actionTypes.LOAD_PENDING_GRADING_COUNT_SUCCESS: {
      
      return {
        ...state,
        pendingGradingCount: action.data
      };
    }

    case actionTypes.LOAD_PENDING_GRADING_COUNT_FAIL: {
      return {
        ...state
      };
    }

    
    

    case actionTypes.LOAD_PENDING_WASTE_GRADING_LIST: {
      let pendingWasteGradingList = cloneDeep(state.pendingWasteGradingList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        pendingWasteGradingList.offset = action.data.offset;
        pendingWasteGradingList.limit = action.data.limit;
      }

      return {
        ...state,
        pendingWasteGradingList
      };
    }

    case actionTypes.LOAD_PENDING_WASTE_GRADING_LIST_SUCCESS: {
     
      let pendingWasteGradingList = cloneDeep(state.pendingWasteGradingList);
      pendingWasteGradingList.data = action.data.data;
      pendingWasteGradingList.meta = {
        page: 1 + state.pendingWasteGradingList.offset / state.pendingWasteGradingList.limit,
        pageSize: state.pendingWasteGradingList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.pendingWasteGradingList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        pendingWasteGradingList
      };
    }

    case actionTypes.LOAD_PENDING_WASTE_GRADING_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_PENDING_WASTE_GRADING_COUNT: {
     
      return {
        ...state,
        pendingWasteGradingCount: {}
      };
    }

    case actionTypes.LOAD_PENDING_WASTE_GRADING_COUNT_SUCCESS: {
      
      return {
        ...state,
        pendingWasteGradingCount: action.data
      };
    }

    case actionTypes.LOAD_PENDING_WASTE_GRADING_COUNT_FAIL: {
      return {
        ...state
      };
    }
    /* ===== END GRADING  ===== */
    

    /* ===== MPRM Process  ===== */
    case actionTypes.MPRM_PROCESS_LIST: {
      let MPRMProcessList = cloneDeep(state.MPRMProcessList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        MPRMProcessList.offset = action.data.offset;
        MPRMProcessList.limit = action.data.limit;
      }

      return {
        ...state,
        MPRMProcessList
      };
    }

    case actionTypes.MPRM_PROCESS_LIST_SUCCESS: {
     
      let MPRMProcessList = cloneDeep(state.MPRMProcessList);
      MPRMProcessList.data = action.data.data;
      MPRMProcessList.meta = {
        page: 1 + state.MPRMProcessList.offset / state.MPRMProcessList.limit,
        pageSize: state.MPRMProcessList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.MPRMProcessList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        MPRMProcessList
      };
    }

    case actionTypes.MPRM_PROCESS_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.MPRM_PROCESS_DETAIL: {
     
      return {
        ...state,
        MPRMProcessDetail: {}
      };
    }

    case actionTypes.MPRM_PROCESS_DETAIL_SUCCESS: {
      
      return {
        ...state,
        MPRMProcessDetail: action.data
      };
    }

    case actionTypes.MPRM_PROCESS_DETAIL_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.MPRM_PROCESS_RETRY: {
     
      return {
        ...state,
        isMPRMProcessRetrySuccess: false,
        isMPRMProcessRetryFail: false,
      };
    }

    case actionTypes.MPRM_PROCESS_RETRY_SUCCESS: {
      
      return {
        ...state,
        isMPRMProcessRetrySuccess: true
      };
    }

    case actionTypes.MPRM_PROCESS_RETRY_FAIL: {
      return {
        ...state,
        isMPRMProcessRetryFail: true
      };
    }


    case actionTypes.MPRM_PROCESS_RESET: {
     
      return {
        ...state,
        isMPRMProcessResetSuccess: false,
        isMPRMProcessResetFail: false,
      };
    }

    case actionTypes.MPRM_PROCESS_RESET_SUCCESS: {
      
      return {
        ...state,
        isMPRMProcessResetSuccess: true
      };
    }

    case actionTypes.MPRM_PROCESS_RESET_FAIL: {
      return {
        ...state,
        isMPRMProcessResetFail: true
      };
    }
    
    /* ===== END MPRM Process  ===== */



    /* ===== END EXPRESS  ===== */  
    case actionTypes.LOAD_EXPRESS_ORDER_COUNT: {
      
      return {
        ...state,
        expressOrderCount: {}
      };
    }

    case actionTypes.LOAD_EXPRESS_ORDER_COUNT_SUCCESS: {
    
      return {
        ...state,
        expressOrderCount:action.data
      };
    }

    case actionTypes.LOAD_EXPRESS_ORDER_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_EXPRESS_ORDER_LIST: {
      let expressOrderList = cloneDeep(state.expressOrderList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        expressOrderList.offset = action.data.offset;
        expressOrderList.limit = action.data.limit;
      }
      expressOrderList.isLoading = true;

      return {
        ...state,
        expressOrderList
      };
    }

    case actionTypes.LOAD_EXPRESS_ORDER_LIST_SUCCESS: {
      let expressOrderList = cloneDeep(state.expressOrderList);
      expressOrderList.data = action.data.data;
      expressOrderList.meta = {
        page: 1 + state.expressOrderList.offset / state.expressOrderList.limit,
        pageSize: state.expressOrderList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.expressOrderList.limit
        ),
        total: action.data.recordsTotal
      };
      expressOrderList.isLoading = false;

      return {
        ...state,
        expressOrderList
      };
    }

    case actionTypes.LOAD_EXPRESS_ORDER_LIST_FAIL: {
      let expressOrderList = cloneDeep(state.expressOrderList);
      expressOrderList.isLoading = false;

      return {
        ...state,
        expressOrderList
      };
    }

    case actionTypes.LOAD_EXPRESS_ORDER_DETAIL: {
      let expressOrderDetail = {};
      
      return {
        ...state,
        expressOrderDetail
      };
    }

    case actionTypes.LOAD_EXPRESS_ORDER_DETAIL_SUCCESS: {
      return {
        ...state,
        expressOrderDetail: action.data
      };
    }

    case actionTypes.LOAD_EXPRESS_ORDER_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.CANCEL_EXPRESS_ORDER: {
      
      return {
        ...state,
        isCancelExpressOrderSuccess: false,
        isCancelExpressOrderFail: false,
      };
    }

    case actionTypes.CANCEL_EXPRESS_ORDER_SUCCESS: {
      return {
        ...state,
        isCancelExpressOrderSuccess: true
      };
    }

    case actionTypes.CANCEL_EXPRESS_ORDER_FAIL: {
      return {
        ...state,
        isCancelExpressOrderFail: true,
      };
    }


    case actionTypes.EXPRESS_STORE_RECEIPT: {
      
      return {
        ...state,
        expressStoreReceipt: ""
      };
    }

    case actionTypes.EXPRESS_STORE_RECEIPT_SUCCESS: {
      
      return {
        ...state,
        expressStoreReceipt: action.data
      };
    }

    case actionTypes.EXPRESS_STORE_RECEIPT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_EXPRESS_SUMMARY: {
      let expressSummary = {};
      
      return {
        ...state,
        expressSummary
      };
    }

    case actionTypes.LOAD_EXPRESS_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        expressSummary: action.data
      };
    }

    case actionTypes.LOAD_EXPRESS_SUMMARY_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_EXPRESS_SUMMARY_CHART: {
      
      return {
        ...state,
        expressSummaryChart: {}
      };
    }

    case actionTypes.LOAD_EXPRESS_SUMMARY_CHART_SUCCESS: {
      
      return {
        ...state,
        expressSummaryChart: action.data
      };
    }

    case actionTypes.LOAD_EXPRESS_SUMMARY_CHART_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_EXPRESS_SALE_SUMMARY: {
      
      return {
        ...state,
        expressSaleSummary: {}
      };
    }

    case actionTypes.LOAD_EXPRESS_SALE_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        expressSaleSummary: action.data
      };
    }

    case actionTypes.LOAD_EXPRESS_SALE_SUMMARY_FAIL: {
      return {
        ...state
      };
    }



    case actionTypes.LOAD_GRADER_DAILY_SETTLEMENT: {
     
      return {
        ...state,
        graderSettlementRecord: cloneDeep(state.graderSettlementRecord),
        graderSettlementDetail: cloneDeep(state.graderSettlementDetail),
        graderSettlementExportSuccess: false

      };
    }

    case actionTypes.LOAD_GRADER_DAILY_SETTLEMENT_SUCCESS: {
      let graderSettlementRecord = cloneDeep(state.graderSettlementRecord);
      let graderSettlementDetail = cloneDeep(state.graderSettlementDetail);
      let graderSettlementExportSuccess = false

      if(action.data.export)
      {
        graderSettlementExportSuccess = true
        
      }else{
        graderSettlementRecord = action.data.record;
        graderSettlementDetail.date = action.data.date;
        graderSettlementDetail.total_amount = action.data.total_amount;
        graderSettlementDetail.total_weight = action.data.total_weight;
        graderSettlementDetail.total_weight_kg = action.data.total_weight_kg;
        graderSettlementDetail.total_weight_unit = action.data.total_weight_unit;
      }

      return {
        ...state,
        graderSettlementRecord,
        graderSettlementDetail,
        graderSettlementExportSuccess
      };
    }

    case actionTypes.LOAD_GRADER_DAILY_SETTLEMENT_FAIL: {
      return {
        ...state
      };
    }
    
    /* ===== END EXPRESS  ===== */

    default:
      return state;
  }
}
